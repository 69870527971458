import React from 'react'

function CommonBanner({imageUrl, title, subtext}) {
  return (
      <>
          <section
              className="breadcrumb__area include-bg about_banner common-banner"
              style={{
                height : '500px',
                  background: `linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%), url(${imageUrl}) lightgray 50% / cover no-repeat`
              }}
          >
              <div className="overlay">
                  <div className="container">
                      <div className='banner-details' style={{ marginBottom : "150px"}}>
                          <h6 className="title mb-0">{title}</h6>
                          <div className='subtitle-container'>
                              <h2 className='subtitle mb-0'>{subtext}</h2>
                          </div>
                      </div>

                  </div>
              </div>
          </section>
      </>
  )
}

export default CommonBanner