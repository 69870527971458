import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const LastedBlog = () => {
  // const industries = [
  //   {
  //     image: "/assets/img/blog/blog.jpeg",
  //     title: "Database Technology At An Agency (Case Study)",
  //     tag: "BUSINESS",
  //     id: 1
  //   },
  //   {
  //     image: "/assets/img/blog/blog2.jpeg",
  //     title: "How To Design A Simple UI When You Have A Complex Solution",
  //     tag: "LEARNING",
  //     id: 2
  //   },
  //   {
  //     image: "/assets/img/blog/blog3.jpeg",
  //     title: "How To Design A Simple UI When You Have A Complex Solution",
  //     tag: "BUSINESS",
  //     id: 3
  //   },
  //   {
  //     image: "/assets/img/blog/blog2.jpeg",
  //     title: "How To Design A Simple UI When You Have A Complex Solution",
  //     tag: "LEARNING",
  //     id: 4
  //   },
  //   {
  //     image: "/assets/img/blog/blog3.jpeg",
  //     title: "How To Design A Simple UI When You Have A Complex Solution",
  //     tag: "BUSINESS",
  //     id: 5
  //   }
  // ];

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const defaultLogo = "assets/img/blog/blog-1.jpg"
  const sliderRef = useRef(null);
  const sliderSectionRef = useRef(null);
  const [isAutoplaying, setIsAutoplaying] = useState(false);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const res = await fetch('https://test2.ardenatech.com/blog/wp-json/wp/v2/posts');
        if (!res.ok) {
          throw new Error('Failed to fetch posts');
        }
        const data = await res.json();
        setPosts(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchPosts();
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // if (entry.isIntersecting) {
          //   setIsAutoplaying(true);
          // } else {
          //   setIsAutoplaying(false);
          // }
          sliderRef.current.slickPlay();
        });
      },
      { threshold: 0.5 }
    );

    if (sliderSectionRef.current) {
      observer.observe(sliderSectionRef.current);
    }

    return () => {
      if (sliderSectionRef.current) {
        observer.unobserve(sliderSectionRef.current);
      }
    };

    
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  

  const settings = {
    infinite: false,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 3.2,
    // autoplay: isAutoplaying,
    autoplaySpeed: 7000,
    dots: false,
    arrows: false,
    afterChange: (current) => {
      setSlideIndex(current);
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 760,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 520,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  

  return (
    <>
      <div className="tp-lasted-blog pt-105 pb-105" id="blogSection" ref={sliderSectionRef}>
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-end mb-70">
              <div className="tp-section-wrapper text-start">
                <span className="tp-section-subtitle mb-30">
                  Our Blogs 
                </span>
                <h2 className="tp-section-title mt-25">
                  Stay updated with our latest news
                </h2>
              </div>
              <Link className="tp-btn-sm text-primary  d-none d-lg-block mb-15" to="/">
                <span>
                  <svg
                    width="36"
                    height="8"
                    viewBox="0 0 36 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                      fill="#622466"
                    />
                  </svg>
                  <svg
                    width="36"
                    height="8"
                    viewBox="0 0 36 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                      fill="#622466"
                    />
                  </svg>
                </span>
                Read More
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Slider {...settings} ref={sliderRef} autoplay={isAutoplaying}>
                {posts.map((item, index) => {
                  const featuredMedia = item._embedded ? item._embedded['wp:featuredmedia'] : false;
                  const imageUrl = featuredMedia && featuredMedia[0] && featuredMedia[0].source_url
                    ? featuredMedia[0].source_url
                    : defaultLogo;
                  return (
                  <div className="col-lg-4 col-md-6 px-2" key={index}>
                    <div className="tp-blog">
                      <div className="tp-blog__thumb">
                          <img src={imageUrl} alt="" />
                        <p className="blog-tag">Business</p>
                      </div>
                      <div className="tp-blog__meta pt-25 pb-15">
                        <span>
                          <Link to="/">Business</Link>/
                        </span>
                        <span>
                          <Link to="/">23.06.2022</Link>
                        </span>
                      </div>
                      <Link to="/blog-details">
                        <h3 dangerouslySetInnerHTML={{ __html: item.title.rendered }} />
                      </Link>
                      <Link className="tp-btn-sm-black" to="/blog-details">
                        <span>
                          <svg
                            width="36"
                            height="8"
                            viewBox="0 0 36 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                              fill="currentColor"
                            />
                          </svg>
                          <svg
                            width="36"
                            height="8"
                            viewBox="0 0 36 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        Read More
                      </Link>
                    </div>
                  </div>
                )})}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LastedBlog;
