import React from "react";
import { Link } from "react-router-dom";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-from-section pt-140 container">

        <div className="view-location-container">
          <div className="view-location-btn">
            <p className="mb-0 title">View <br/>Location</p>
            <div className="offcanva-btn mt-60">
              <Link
                to="/contact-us"
                className="tp-slider-btn d-inline-block"
                tabIndex="-1"
              >
                <span>
                  <svg
                    width="53"
                    height="8"
                    viewBox="0 0 53 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                      fill="#622466"
                    ></path>
                  </svg>
                  <svg
                    width="53"
                    height="8"
                    viewBox="0 0 53 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                      fill="#622466"
                    ></path>
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        </div>

        <div className="contact-map">
          <div className="map-title-container">
            <p className="mb-0 title">Our Location</p>
            <p className="mb-0 subtitle">Want to reach us in Office? Find us here</p>
          </div>

          <div className="tp-ct-map">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14018.655999235425!2d77.2693132!3d28.5498174!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3fd9936e00f%3A0x7d6ee5a2be5f1919!2sAlok%20Masterbatches%20Pvt.Ltd%20-%20Masterbatch%20Manufacturer%20in%20Delhi%2C%20India!5e0!3m2!1sen!2sin!4v1724763115375!5m2!1sen!2sin"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
