import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import IntroBanner from '../../common/IntroBanner'
import ProductContact from './ProductContact'
import ProductsSidebar from '../Products/ProductsSideBar'
import OtherProducts from '../Products/OurProducts'
import IndustriesFooter from '../Industries/IndustriesFooter'

function Mineral() {
  return (
      <>
      <Menu />

      <IntroBanner imageUrl={'/assets/img/products/mineral.jpg'} title={"Our Product"} subtext={"Mineral Masterbatches Engineered for Performance and Efficiency"} textAvatar="MB" />
      <div className="container">
        <div className='row gx-80 masterclass-container '>
          <div className='col-md-8'>
            <div >
              <div className='title-container'>
                <h3 className='title'>Largest Manufacturers of Mineral Masterbatches</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK has developed high performance mineral reinforced Masterbatches specially for PP Tapes applications. Enhancer Masterbatch will help customers to increase the Elongation and Tape Strength, Filler loading can also be increased by 20-30% as well. ALOK offers a wide range of Filler Masterbatches for various applications. Used widely to reduce production costs. In addition, it minimizes shrinkage and improves the surface characteristics of final products.</p>
              </div>

              <div className='title-container pt-80'>
                <h3 className='title'>Mineral Masterbatches</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK are designed to improve tape strength, reduce production costs, and enhance the surface characteristics of final products. Our mineral-reinforced solutions are tailored for optimal performance.</p>
              </div>

              <div className=" ">
                <div className="row">
                  <div className="col overflow-auto">
                    <table className="table table-bordered masterclass-table">
                      <thead>
                        <tr className="table-header" style={{ backgroundColor: 'black', color: 'white' }}>
                          <th scope="col">Products</th>
                          <th scope="col">Description</th>
                          <th scope="col">Application</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Calcium Carbonate Masterbatches</td>
                          <td>ALOK offers a wide range of Filler Masterbatches that reduce production costs and improve product features such as shrinkage control and surface characteristics.</td>
                          <td>Used in PP Tape Extrusion/Rafia, Injection Molding, Blow Molding, PE Film, PS Thermoforming, Clear Film, PP compounding, and Lamination.</td>
                        </tr>
                        <tr>
                          <td>Enhancer Masterbatches</td>
                          <td>Specifically developed for PP Tapes applications, these masterbatches increase elongation and tape strength, enhancing filler loading by up to 20-30%.</td>
                          <td>Ideal for applications in Woven Sacks, FIBC, and PP tape, improving dust resistance, cost efficiency, and mechanical properties.</td>
                        </tr>
                        <tr>
                          <td>Specialty talc</td>
                          <td>ALOK's range of talc masterbatches is based on the highest purity quality of talc in the world and is free from asbestos and crystalline silica.</td>
                          <td>Compounding, PP Sheet Extrusion, Alveolar sheet, PET Extrusion, Transparent Film, PS Sheet Extrusion</td>
                        </tr>
                        

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <ProductContact />
          </div>
          <div className='col-md-4'>
            <ProductsSidebar />
          </div>
        </div>

      </div>
        {/* <OtherProducts/> */}
      <TopFooter />
      <Footer />
      {/* <IndustriesFooter/> */}
      </>
  )
}

export default Mineral