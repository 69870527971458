import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import IntroBanner from '../../common/IntroBanner'
import ProductContact from './ProductContact'
import ProductsSidebar from '../Products/ProductsSideBar'
import OtherProducts from '../Products/OurProducts'
import IndustriesFooter from '../Industries/IndustriesFooter'

function Fibre() {
  return (
      <>
      <Menu />

      <IntroBanner imageUrl={'/assets/img/products/textile.jpg'} title={"Our Product"} subtext={"Masterbatches for Fibers and Nonwovens Innovating for a Stronger Tomorrow"} textAvatar="FB" />
      <div className="container">
        <div className='row gx-80 masterclass-container '>
          <div className='col-md-8'>
            <div >
              <div className='title-container'>
                <h3 className='title'>Largest Manufacturers of Masterbatches for Fibers and Nonwovens</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK Masterbatches leads the way in developing specialized masterbatches for the fibers and nonwovens industry, offering tailored solutions that enhance the properties of fibers and nonwoven fabrics. These masterbatches are designed to improve product performance in critical areas such as durability, color consistency, and environmental resistance, meeting the high demands of various applications ranging from hygiene products to automotive and filtration systems.</p>
              </div>

              <div className='title-container'>
                <h3 className='title'>Masterbatches for Fibers and Nonwovens</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK Masterbatches are meticulously crafted to meet the stringent requirements of industries ranging from hygiene products to automotive and filtration systems. We ensure durability, color consistency, and environmental resistance in every application.</p>
              </div>

              <div className=" ">
                <div className="row">
                  <div className="col">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-header" style={{ backgroundColor: 'black', color: 'white' }}>
                          <th scope="col">Products</th>
                          <th scope="col">Description</th>
                          <th scope="col">Application</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Antimicrobial Masterbatch</td>
                          <td>Infused with properties to inhibit the growth of bacteria and fungi, ideal for hygiene and medical applications.</td>
                          <td>Enhances the sanitary properties of nonwovens, crucial for medical textiles and hygiene products.</td>
                        </tr>
                        <tr>
                          <td>UV Stabilizer Masterbatch</td>
                          <td>Protects fibers and nonwovens from UV degradation, extending the life and performance of outdoor and exposed textiles.</td>
                          <td>Critical for outdoor applications, ensuring long-term durability and color stability.</td>
                        </tr>
                        <tr>
                          <td>Flame Retardant Masterbatch</td>
                          <td>Provides essential fire resistance for safety in automotive, construction, and upholstery applications.</td>
                          <td>Increases safety by meeting stringent flammability standards required in public and industrial environments.</td>
                        </tr>
                        <tr>
                          <td>Hydrophilic Masterbatch</td>
                          <td>Enhances the water-absorbing properties of nonwovens, used predominantly in personal care and hygiene products.</td>
                          <td>Improves comfort and performance in products like diapers and sanitary pads.</td>
                        </tr>
                        <tr>
                          <td>Thermal Bonding Masterbatch</td>
                          <td>Optimizes the heat-sealing process of fibers, improving the structural integrity of nonwoven fabrics.</td>
                          <td>Essential for producing strong, durable nonwovens in automotive and geotextile applications.</td>
                        </tr>
                        

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <ProductContact />
          </div>
          <div className='col-md-4'>
            <ProductsSidebar />
          </div>
        </div>

      </div>
        {/* <OtherProducts/> */}
      <TopFooter />
      <Footer />
      {/* <IndustriesFooter/> */}
      </>
  )
}

export default Fibre