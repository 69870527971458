import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';

const TopAbout = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140 pb-80 overflow-hidden" id="aboutSection">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="tp-about-left pr-90">
                <div className="tp-section-wrapper pt-5">
                  <span className="tp-section-subtitle">
                    ABOUT US
                  </span>
                  <h2 className="tp-section-title pt-25 mb-30">
                    We make plastics safer, sustainable and affordable
                  </h2>


                  <div className="tp-about-right p-relative py-4 d-block d-lg-none">
                    <div className="about-img text-md-end">
                      <Swiper
                        effect={'cards'}
                        grabCursor={true}
                        modules={[EffectCards]}
                        className="mySwiper"
                      // slidesPerView={1}
                      // spaceBetween={50}
                      autoplay
                      // onSlideChange={() => console.log('slide change')}
                      // onSwiper={(swiper) => console.log(swiper)}
                      >
                        <SwiperSlide>
                          <img src="assets/img/about/about1.jpg" alt="" className="about-gallery-image" />
                        </SwiperSlide>

                        <SwiperSlide>
                          <img src="assets/img/about/about2.jpg" alt="" className="about-gallery-image" />
                        </SwiperSlide>

                        <SwiperSlide>
                          <img src="assets/img/about/about3.jpg" alt="" className="about-gallery-image" />
                        </SwiperSlide>

                        <SwiperSlide>
                          <img src="assets/img/about/about4.jpg" alt="" className="about-gallery-image" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src="/assets/img/products/white.jpg" alt="" className="about-gallery-image" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src="/assets/img/products/black.jpg" alt="" className="about-gallery-image" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src="/assets/img/products/agriculture.jpg" alt="" className="about-gallery-image" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src="/assets/img/products/textile.jpg" alt="" className="about-gallery-image" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src="/assets/img/products/recycle.jpg" alt="" className="about-gallery-image" />
                        </SwiperSlide>

                      </Swiper>
                    </div>
                    {/* <div className="tp-about-info text-center grey-bg-4">
                  <h3>24</h3>
                  <h4>
                    Year of <br />
                    Experience
                  </h4>
                </div> */}
                  </div>

                  <p className="tp-section-description">
                    ALOK is a leading masterbatch provider, co-creating innovative, reliable and responsible applications for the plastic industry. Over the last few years we have put innovation and sustainability at the core of our existence. Today, with an amalgamation of research, world class manufacturing processes and an experienced team, we deliver high quality, consistent and cost effective solutions to cater to the current and future needs of our customers for both standard and speciality solutions.
                  </p>
                  {/* <p className="pt-10">
                    Sed ullamcorper morbi tincidunt ornare massa potenti nullam
                    ac tortor vitae purus. faucibus ornare suspendisse. Donec
                    enim diam vulputate ut pharetra sit amet aliquam id. Eu mi
                    bibendum neque egestas congue purus non enim praesent.
                  </p> */}
                </div>
                <div className="mt-45 mb-30 d-flex  ">
                  <Link
                    to="/about"
                    className="tp-slider-btn2 d-inline-block" 
                    
                    tabIndex="-1"
                  >
                    <span  className="color-primary" style={{ color: "#622466 !important" }}>
                      <svg
                        width="53"
                        height="8"
                        viewBox="0 0 53 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <svg
                        width="53"
                        height="8"
                        viewBox="0 0 53 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                    Learn More
                  </Link>
                  {/* <Link to="/about">
                    <div className="tp-theme-btn d-flex align-items-center">
                      <div className="tp-btn-inner">
                        <p className="mb-0">Let’t Talk</p>
                        <b>Get in Touch</b>
                        <span className="d-inline-block">
                          <svg
                            width="46"
                            height="8"
                            viewBox="0 0 46 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                              fill="currentColor"
                            />
                          </svg>
                          <svg
                            width="46"
                            height="8"
                            viewBox="0 0 46 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right p-relative d-none d-lg-flex align-items-center justify-content-end h-100">
                <div className="about-img text-md-end">

                  <Swiper
                    effect={'cards'}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className="mySwiper"
                  // slidesPerView={1}
                  // spaceBetween={50}
                  // autoplay
                  // onSlideChange={() => console.log('slide change')}
                  // onSwiper={(swiper) => console.log(swiper)}
                  >
                    <SwiperSlide>
                      <img src="assets/img/about/about1.jpg" alt="" className="about-gallery-image" />
                    </SwiperSlide>

                    <SwiperSlide>
                      <img src="assets/img/about/about2.jpg" alt="" className="about-gallery-image" />
                    </SwiperSlide>

                    <SwiperSlide>
                      <img src="assets/img/about/about3.jpg" alt="" className="about-gallery-image" />
                    </SwiperSlide>

                    <SwiperSlide>
                      <img src="assets/img/about/about4.jpg" alt="" className="about-gallery-image" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/assets/img/products/white.jpg" alt="" className="about-gallery-image" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/assets/img/products/black.jpg" alt="" className="about-gallery-image" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/assets/img/products/agriculture.jpg" alt="" className="about-gallery-image" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/assets/img/products/textile.jpg" alt="" className="about-gallery-image" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/assets/img/products/recycling.jpg" alt="" className="about-gallery-image" />
                    </SwiperSlide>

                  </Swiper>


                </div>
                {/* <div className="tp-about-info text-center grey-bg-4">
                  <h3>24</h3>
                  <h4>
                    Year of <br />
                    Experience
                  </h4>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default TopAbout;
