import React, { useState } from 'react'
import Menu from '../../common/Menu/Menu'
import CommonBanner from '../../common/CommonBanner'
import CommonContactCard from '../../common/CommonContactCard'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import { Link } from 'react-router-dom'

function Regulatory() {

    const [certs, setCerts] = useState([
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
            <rect width="45" height="45" rx="22.5" fill="white" fill-opacity="0.12"/>
            <path d="M12.9824 26.7936C16.056 25.8391 18.9095 24.3755 21.4497 22.5016C23.8327 24.2602 25.94 26.3811 27.6914 28.7853C25.8499 31.3131 24.4016 34.1532 23.4384 37.2129H23.4332C21.3138 32.585 17.5886 28.8668 12.9824 26.7936Z" fill="#B5B7E6"/>
            <path d="M31.9434 37.2129H23.437C24.4002 34.1529 25.8485 31.3128 27.6903 28.785C29.5324 31.3128 30.9801 34.1529 31.9434 37.2129Z" fill="#A3A7E0"/>
            <path d="M21.4494 22.502C18.9092 20.6277 16.0557 19.1642 12.9821 18.2094C17.5883 16.1362 21.3135 12.4179 23.4329 7.79036H23.438C24.4013 10.8497 25.8496 13.6904 27.6914 16.218C25.94 18.6222 23.8324 20.7428 21.4494 22.502Z" fill="#6F73C4"/>
            <path d="M23.437 7.78887H31.9434C30.9801 10.8486 29.5324 13.6889 27.6903 16.2168C25.8488 13.6889 24.4002 10.8486 23.437 7.78887Z" fill="#595DAA"/>
            <path d="M21.4512 22.4991C18.911 24.373 16.0574 25.8365 12.9839 26.791L12.9827 26.7901V18.2074L12.9839 18.2065C16.0574 19.1613 18.911 20.6249 21.4512 22.4991Z" fill="#8387D3"/>
          </svg>`,
            name: "ISO 9001 Certified Locations",
            detail: "Quality Management Systems are implemented at our facilities in:",
            class:"logo rotate180",
            locations: ["Rajasthan","Dadar and Nagar Haveli","Tamil Nadu"]
        },
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
            <rect width="45" height="45" rx="22.5" fill="white" fill-opacity="0.12"/>
            <path d="M31.9414 18.2084C28.8679 19.1629 26.0143 20.6264 23.4741 22.5003C21.0911 20.7418 18.9838 18.6209 17.2324 16.2167C19.0739 13.6888 20.5223 10.8488 21.4855 7.78906H21.4906C23.61 12.4169 27.3352 16.1352 31.9414 18.2084Z" fill="#ED98BB"/>
            <path d="M12.9805 7.78906L21.4869 7.78906C20.5237 10.8491 19.0753 13.6891 17.2335 16.217C15.3914 13.6891 13.9437 10.8491 12.9805 7.78906Z" fill="#DD7DA5"/>
            <path d="M23.4744 22.5C26.0146 24.3742 28.8682 25.8378 31.9417 26.7926C27.3355 28.8658 23.6103 32.5841 21.4909 37.2116H21.4858C20.5226 34.1522 19.0742 31.3115 17.2324 28.784C18.9838 26.3798 21.0914 24.2592 23.4744 22.5Z" fill="#DB518B"/>
            <path d="M21.4869 37.2131H12.9805C13.9437 34.1534 15.3914 31.313 17.2335 28.7852C19.075 31.313 20.5237 34.1534 21.4869 37.2131Z" fill="#C41E63"/>
            <path d="M23.4727 22.5029C26.0128 20.629 28.8664 19.1654 31.9399 18.2109L31.9411 18.2119V26.7945L31.9399 26.7954C28.8664 25.8406 26.0128 24.3771 23.4727 22.5029Z" fill="#CA487E"/>
          </svg>`,
            name: "ISO 14001 Certified Locations",
            class: "logo",
            detail: "Our environmental management systems meeting this international standard are:",
            locations: ["Rajasthan", "Tamil Nadu"]
        },
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
            <rect width="45" height="45" rx="22.5" fill="white" fill-opacity="0.12"/>
            <path d="M13.1192 26.7545C16.1927 25.8 19.0462 24.3364 21.5864 22.4625C23.9695 24.2211 26.0767 26.342 27.8281 28.7462C25.9866 31.2741 24.5383 34.1141 23.5751 37.1738H23.5699C21.4505 32.546 17.7253 28.8277 13.1192 26.7545Z" fill="#D5D5AE"/>
            <path d="M32.0801 37.1738H23.5737C24.5369 34.1138 25.9852 31.2738 27.827 28.7459C29.6691 31.2738 31.1169 34.1138 32.0801 37.1738Z" fill="#C8C88A"/>
            <path d="M21.5861 22.4609C19.0459 20.5867 16.1924 19.1232 13.1189 18.1684C17.725 16.0951 21.4502 12.3769 23.5696 7.74934H23.5748C24.538 10.8087 25.9863 13.6494 27.8281 16.177C26.0767 18.5812 23.9692 20.7018 21.5861 22.4609Z" fill="#A0A15E"/>
            <path d="M23.5737 7.7498H32.0801C31.1169 10.8095 29.6691 13.6499 27.827 16.1777C25.9855 13.6499 24.5369 10.8095 23.5737 7.7498Z" fill="#8A8B35"/>
            <path d="M21.5879 22.46C19.0477 24.3339 16.1941 25.7975 13.1206 26.752L13.1194 26.751V18.1684L13.1206 18.1675C16.1941 19.1222 19.0477 20.5858 21.5879 22.46Z" fill="#B6B77C"/>
          </svg>`,
            name: "ISO 45001 Certified Locations",
            class: "logo rotate180",
            detail: "Occupational health and safety management systems are rigorously followed in:",
            locations: ["Rajasthan", "Tamil Nadu"]
        },
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
            <rect width="45" height="45" rx="22.5" fill="white" fill-opacity="0.12"/>
            <path d="M18.2084 31.9433C19.1629 28.8698 20.6264 26.0163 22.5003 23.4761C20.7418 21.093 18.6209 18.9858 16.2167 17.2344C13.6888 19.0759 10.8488 20.5242 7.78906 21.4874V21.4926C12.4169 23.612 16.1352 27.3372 18.2084 31.9433Z" fill="#FDA060"/>
            <path d="M7.78906 12.9805V21.4869C10.8491 20.5237 13.6891 19.0753 16.217 17.2335C13.6891 15.3914 10.8491 13.9437 7.78906 12.9805Z" fill="#F38336"/>
            <path d="M22.5 23.4744C24.3742 26.0146 25.8378 28.8682 26.7926 31.9417C28.8658 27.3355 32.5841 23.6103 37.2116 21.4909V21.4858C34.1522 20.5226 31.3115 19.0742 28.784 17.2324C26.3798 18.9838 24.2592 21.0914 22.5 23.4744Z" fill="#FFC59E"/>
            <path d="M37.2092 21.4869V12.9805C34.1495 13.9437 31.3091 15.3914 28.7812 17.2335C31.3091 19.075 34.1495 20.5237 37.2092 21.4869Z" fill="#FFB37F"/>
            <path d="M22.499 23.4746C20.6251 26.0148 19.1615 28.8684 18.207 31.9419L18.208 31.9431H26.7906L26.7915 31.9419C25.8367 28.8684 24.3732 26.0148 22.499 23.4746Z" fill="#F99856"/>
          </svg>`,
            name: "OEKO-TEX Standard 100 & STEP",
            class: "logo rotate-90",
            detail: "Our facility is certified under OEKO-TEX Standard 100, and STEP certification is under process, reflecting our commitment to sustainable textile production are in: ",
            locations: ["Tamil Nadu II"]
        },
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
            <rect width="45" height="45" rx="22.5" fill="white" fill-opacity="0.12"/>
            <path d="M18.2084 13.0567C19.1629 16.1302 20.6264 18.9837 22.5003 21.5239C20.7418 23.907 18.6209 26.0142 16.2167 27.7656C13.6888 25.9241 10.8488 24.4758 7.78906 23.5126V23.5074C12.4169 21.388 16.1352 17.6628 18.2084 13.0567Z" fill="#6C9CA1"/>
            <path d="M7.78906 32.0195V23.5131C10.8491 24.4763 13.6891 25.9247 16.217 27.7665C13.6891 29.6086 10.8491 31.0563 7.78906 32.0195Z" fill="#518B91"/>
            <path d="M22.5 21.5256C24.3742 18.9854 25.8378 16.1318 26.7926 13.0583C28.8658 17.6645 32.5841 21.3897 37.2116 23.5091V23.5142C34.1522 24.4774 31.3115 25.9258 28.784 27.7676C26.3798 26.0162 24.2592 23.9086 22.5 21.5256Z" fill="#2B777F"/>
            <path d="M37.2092 23.5131V32.0195C34.1495 31.0563 31.3091 29.6086 28.7812 27.7665C31.3091 25.925 34.1495 24.4763 37.2092 23.5131Z" fill="#115E66"/>
            <path d="M22.499 21.5254C20.6251 18.9852 19.1615 16.1316 18.207 13.0581L18.208 13.0569H26.7906L26.7915 13.0581C25.8367 16.1316 24.3732 18.9852 22.499 21.5254Z" fill="#226971"/>
          </svg>`,
            name: "EcoVadis",
            class: "logo rotate90",
            detail: "We are in the process of obtaining the EcoVadis certification, demonstrating our commitment to CSR and sustainable development are in:",
            locations: ["Tamil Nadu I"]
        },
    ])

    return (
        <>
            <Menu />
            <CommonBanner imageUrl={'/assets/img/regulatory/image-1.png'} title={"Regulatory Compliance"} subtext={"Ensuring Global Standards for Safety, Ethics, and Environmental Care"} />
            <div className="tp-about-area  pt-140 pb-100 overflow-hidden regulatory-section-1">
                <div className="container">
                    <div className="row justify-content-end ">
                        <div className="col-lg-6 align-middle">
                            <div className="tp-about-left title-btn-container">
                                <div className="tp-section-wrapper">
                                    <div className='regulatory-title-container' >
                                        <p className="mb-0 tp-section-subtitle">
                                            Our Commitment
                                        </p>
                                        <h2 className="mb-0 tp-section-title">
                                            Our Commitment to Compliance
                                        </h2>
                                    </div>
                                    <p className="mb-0 tp-section-description">
                                        At ALOK, we uphold the highest standards of regulatory compliance to ensure that our products meet international and national norms for environmental protection, safety, and ethical practices. We are dedicated to providing products that are not only innovative but also responsible and safe for all stakeholders involved.
                                    </p>
                                </div>
                                {/* <div className="view-btn  ">
                                    <div className='join-team-btn'>

                                        <p className='mb-0 btn-text'>View our Compliances</p>
                                        <div className="offcanva-btn mt-60">
                                            <Link
                                                to="/contact-us"
                                                className="tp-slider-btn d-inline-block"
                                                tabIndex="-1"
                                            >
                                                <span>
                                                    <svg
                                                        width="53"
                                                        height="8"
                                                        viewBox="0 0 53 8"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                                                            fill="#7939A8"
                                                        ></path>
                                                    </svg>
                                                    <svg
                                                        width="53"
                                                        height="8"
                                                        viewBox="0 0 53 8"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                                                            fill="#7939A8"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            </Link>
                                        </div>

                                    </div>

                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="tp-about-right p-relative d-none d-lg-flex align-items-center justify-content-end h-100 ">
                                <div className="about-img text-md-end">
                                    <img src='assets/img/regulatory/image-2.png' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="regulatory-compliance-container w-100">
                <div className="row container-fluid mx-auto">
                    <div className="col-lg-6 p-0">
                        <img src='assets/img/regulatory/image-7.jpg' className='image' />
                    </div>
                    <div className="col-lg-6">
                        <div className='compliance-container'>
                            <div className='compliance-title-container'>
                                <p className='mb-0 title'></p>
                                <p className='mb-0 subtitle'>International and National Compliance</p>
                            </div>

                            <p className='description'>
                                Our masterbatches are formulated to comply with rigorous regulatory norms, including the EU Biocidal Products Regulation (EU-BPR) for products sold in Europe and standards set by national environmental protection agencies worldwide. This ensures our products are safe, effective, and environmentally friendly.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="regulatory-compliance-container w-100">
                <div className="row container-fluid mx-auto">
                    <div className="col-lg-6">
                        <div className='compliance-container'>
                            <div className='compliance-title-container'>
                                <p className='mb-0 title'></p>
                                <p className='mb-0 subtitle'>Conflict-Free Assurance</p>
                            </div>

                            <p className='description'>
                                We guarantee that all our products are conflict mineral-free, supporting global efforts to prevent the financial support of conflict through the extraction and sale of minerals.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 p-0">
                        <img src='assets/img/regulatory/image-4.jpg' className='image' />
                    </div>
                </div>
            </div>


            <div className="regulatory-compliance-container w-100">
                <div className="row container-fluid mx-auto">
                    <div className="col-lg-6 p-0">
                        <img src='assets/img/regulatory/image-3.jpg' className='image' />
                    </div>
                    <div className="col-lg-6">
                        <div className='compliance-container'>
                            <div className='compliance-title-container'>
                                <p className='mb-0 title'></p>
                                <p className='mb-0 subtitle'>Ethical Manufacturing</p>
                            </div>

                            <div className='description-container'>
                                <p className='mb-0 description'>
                                    ALOK Masterbatches is committed to ethical practices across all aspects of our business:
                                </p>

                                <div className='description-item'>
                                    <p className='mb-0 label'>Child Labor</p>
                                    <p className='mb-0 value'>We strictly prohibit the use of child labor in any part of our manufacturing or supply chain.</p>
                                </div>

                                <div className='description-item'>
                                    <p className='mb-0 label'>Worker Safety</p>
                                    <p className='mb-0 value'>We adhere to the strictest safety norms in our manufacturing processes to ensure the well-being of our workers.</p>
                                </div>

                                <div className='description-item'>
                                    <p className='mb-0 label'>Product Safety</p>
                                    <p className='mb-0 value'>All products comply with the applicable national regulatory requirements, ensuring their safety and quality.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="regulatory-compliance-container w-100">
                <div className="row container-fluid mx-auto">
                    <div className="col-lg-6">
                        <div className='compliance-container'>
                            <div className='compliance-title-container'>
                                <p className='mb-0 title'></p>
                                <p className='mb-0 subtitle'>Global Data Protection and Web Compliance</p>
                            </div>

                            <p className='description'>
                                GDPR: We are compliant with the General Data Protection Regulation (GDPR), ensuring the privacy and protection of all personal data from EU citizens. US Web Compliance: Our web practices meet the standards required by US regulations, ensuring accessibility and data security for all our users.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 p-0">
                        <img src='assets/img/regulatory/image-5.png' className='image' />
                    </div>
                </div>
            </div>


            <div className='w-100 certification-section mb-140'>
                <div className='container mx-auto'>
                    <div className='cert-title-container'>
                        <p className='mb-0 title'>Our Certifications</p>
                        <p className='mb-0 subtitle'>Our facilities have earned multiple certifications, underscoring our commitment to quality and sustainability</p>
                    </div>
                    <div className='certs row'>
                        {
                            certs.map((item, index) => (
                                <div className='cert col-lg-4' key={index}>
                                    <div className={item.class} dangerouslySetInnerHTML={{ __html: item.logo }}>

                                    </div>

                                    <div className='cert-details'>
                                        <p className='mb-0 name'>
                                            {item.name}
                                        </p>
                                        <p className='mb-0 value'>
                                            {item.detail}
                                        </p>
                                        <ul style={{marginLeft:"1em", color: "white"}}>
                                            {item.locations.map((item1, index1) => (
                                                <li className='list' style={{ listStyle: "disc"}}>{ item1 }</li>
                                            ))
                                            }
                                            
                                        </ul>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className='mt-130'>
                <CommonContactCard />
            </div>
            <TopFooter />
            <Footer />
        </>
    )
}

export default Regulatory