import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import IntroBanner from '../../common/IntroBanner'
import ProductContact from './ProductContact'
import ProductsSidebar from '../Products/ProductsSideBar'
import OtherProducts from '../Products/OurProducts'
import IndustriesFooter from '../Industries/IndustriesFooter'

function Black() {
  return (
      <>
      <Menu />

      <IntroBanner imageUrl={'/assets/img/products/black.jpg'} title={"Our Product"} subtext={"Black Masterbatches Deep Color, Superior Performance"} textAvatar="BM" />
      <div className="container">
        <div className='row gx-80 masterclass-container '>
          <div className='col-md-8'>
            <div >
              <div className='title-container'>
                <h3 className='title'>Wide Range of Black Masterbatches</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK’s range of Black Masterbatches are considered the Gold Standard in the industry. From complex applications that require superior filter life and have high performance and process demands. For potable water management applications, we offer specialized grades conforming to the strictest regulatory standards.</p>
              </div>

              <div className='title-container pt-80'>
                <h3 className='title'>Black Masterbatches</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK meets the strictest regulatory standards for black masterbatches, offering superior filtration and process efficiency. Our solutions are recognized as the gold standard in the industry.</p>
              </div>

              <div className=" ">
                <div className="row">
                  <div className="col overflow-auto">
                    <table className="table table-bordered masterclass-table">
                      <thead>
                        <tr className="table-header" style={{ backgroundColor: 'black', color: 'white' }}>
                          <th scope="col">Products</th>
                          <th scope="col">Description</th>
                          <th scope="col">Application</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>High Jetness Black</td>
                          <td>Offers exceptional Jetness with excellent dispersion and coverage.</td>
                          <td>Ideal for premium packaging and high-end consumer products.</td>
                        </tr>
                        <tr>
                          <td>Conductive Carbon Black</td>
                          <td>Incorporates conductive fillers to provide electrical conductivity.</td>
                          <td>Used in electronics and automotive applications requiring static dissipation.</td>
                        </tr>
                        <tr>
                          <td>P Type Black</td>
                          <td>An extremely clean carbon black grade that does not alter the organoleptic properties.</td>
                          <td>Drink water storage and transportation</td>
                        </tr>
                        <tr>
                          <td>NIR Black</td>
                          <td>Designed for near-infrared applications, allowing recyclability of black plastics.</td>
                          <td>Suitable for sorting and recycling processes in waste management.</td>
                        </tr>
                        <tr>
                          <td>UV Stable Black</td>
                          <td>Includes additives that enhance UV resistance, protecting products from solar degradation.</td>
                          <td>Perfect for agricultural films and outdoor applications.</td>
                        </tr>
                        <tr>
                          <td>Food Contact Black</td>
                          <td>Complies with food safety standards for applications involving direct food contact.</td>
                          <td>Used in food packaging materials and utensils.</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <ProductContact />
          </div>
          <div className='col-md-4'>
            <ProductsSidebar />
          </div>
        </div>

      </div>
        {/* <OtherProducts/> */}
      <TopFooter />
      <Footer />
      {/* <IndustriesFooter/> */}
      </>
  )
}

export default Black