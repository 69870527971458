



export default function NewsSection() {


    return (
        <div className="w-100" id="newsSection">
            <div className="container row mx-auto pb-100">
                <div class="news-section-1 col-lg-5  mb-xs-20 p0">
                  
                        <h4 class="investors-news-title">Exhibition Calendar</h4>
                        <div class="investors-news-row relative"> 
                            <h3 class="investors-news-desc">ALOK and ServiceNow Expand AI Collaboration to Revolutionize
                                Service Delivery 
                                {/* <img class="img-right-arrow" src="/content/dam/ALOK-web/en/global-resource/responsive/newdesign/right-arrow.svg" alt="arrow">  */}
                                </h3>
                        </div>

                        <div class="investors-news-row relative">
                            <h3 class="investors-news-desc">67% of Global Enterprises Have Sophisticated Cloud Strategies, Yet
                                Less Than 10% Have Fully Integrated AI, says MIT Technology...
                                 {/* <img class="img-right-arrow" src="/content/dam/alok-web/en/global-resource/responsive/newdesign/right-arrow.svg" alt="arrow">  */}
                                 </h3>
                        </div>

                        <div class="investors-news-row relative"> 
                        <h3 class="investors-news-desc">ALOK Collaborates with TDC Net to Accelerate Digital Transformation
                                {/* <img class="img-right-arrow" src="/content/dam/alok-web/en/global-resource/responsive/newdesign/right-arrow.svg" alt="arrow"> */}
                            </h3>
                         </div>

                    <div class="headline-card"> <span> <strong>Launched today: ALOK Topaz - An AI-first offering to
                            accelerate business value for global enterprises using generative AI</strong></span> </div>
                   
                </div>
                <div class="col-lg-7 ">
                    <div className="news-section-2 row h-100">
                            <div className="news-grid-card col-lg-6 col-md-6  mb-xs-20 ">
                                <img src="/assets/img/industries/building.jpg" className="image"/>
                                <div className="overlay">
                                <h4 className="title">ALOK Q1 FY25 Results - Archived Webcast</h4>
                                    <p className="date">July 18, 2024</p>
                                </div>
                            </div>

                            <div className="news-grid-card col-lg-6 col-md-6  mb-xs-20 ">
                                <img src="/assets/img/industries/building.jpg" className="image"/>
                                <div className="overlay">
                                <h4 className="title">ALOK Q1 FY25 Results - Archived Webcast</h4>
                                    {/* <p className="date">July 18, 2024</p> */}
                                </div>
                            </div>

                            <div className="news-grid-card col-lg-6 col-md-6  mb-xs-20 ">
                                <img src="/assets/img/industries/building.jpg" className="image"/>
                                <div className="overlay">
                                <h4 className="title">ALOK Q1 FY25 Results - Archived Webcast</h4>
                                    {/* <p className="date">July 18, 2024</p> */}
                                </div>
                            </div>

                            <div className="news-grid-card col-lg-6 col-md-6  mb-xs-20 ">
                                <img src="/assets/img/industries/building.jpg" className="image"/>
                                <div className="overlay">
                                <h4 className="title">ALOK Q1 FY25 Results - Archived Webcast</h4>
                                    {/* <p className="date">July 18, 2024</p> */}
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}