import React, { useState } from 'react'



export default function Jobs() {

    const [jobs, setJobs] = useState([
        // {
        //     title: "Design",
        //     positions: [
        //         {
        //             position: "Design Engineer, Web",
        //             location: "Remote"
        //         },
        //         {
        //             position: "Product Designer",
        //             location: "San Francisco, CA"
        //         },
        //     ]
        // },
        // {
        //     title: "Finance & Operations",
        //     positions: [
        //         {
        //             position: "Revenue Accounting Manager",
        //             location: "San Francisco, CA"
        //         }
        //     ]
        // },
        // {
        //     title: "Marketing",
        //     positions: [
        //         {
        //             position: "Content Marketing Manager",
        //             location: "New York, NY"
        //         },
        //         {
        //             position: "Head of Developer Relations",
        //             location: "San Francisco, CA"
        //         },
        //         {
        //             position: "Head of Product Marketing",
        //             location: "San Francisco, CA"
        //         },
        //         {
        //             position: "Performance Marketing Manager",
        //             location: "New York, NY"
        //         },
        //         {
        //             position: "Performance Marketing Manager",
        //             location: "San Francisco, CA"
        //         },
        //     ]
        // },
        // {
        //     title: "Professional Services",
        //     positions: [
        //         {
        //             position: "Service Delivery Manager, Professional Services",
        //             location: "San Francisco, CA"
        //         }
        //     ]
        // },
        // {
        //     title: "Revenue Operations",
        //     positions: [
        //         {
        //             position: "Revenue Operations, Systems Lead",
        //             location: "San Francisco, CA"
        //         },
        //         {
        //             position: "Revenue Operations, Systems Lead",
        //             location: "New York, NY"
        //         }
        //     ]
        // },
        // {
        //     title: "Sales",
        //     positions: [
        //         {
        //             position: "Technical Enablement Manager",
        //             location: "New York, NY"
        //         }
        //     ]
        // },
        // {
        //     title: "Support",
        //     positions: [
        //         {
        //             position: "Support Engineer",
        //             location: "New York, NY"
        //         }
        //     ]
        // },
    ]);

    return (
        <>
            <div className='w-100' id="jobs">
                <div className='container mx-auto jobs-section'>
                    <div className='section1'>
                        <div className='job-title-container'>
                            <p className='mb-0 subtitle'>Jobs @Alok</p>
                            <p className='mb-0 title'>Open positions at ALOK</p>
                        </div>

                        <div className='w-100 job-filter-container'>
                            <div className='w-100 filter-container'>
                                <div className='w-100 row g-4'>
                                    <div className='col-lg-4 '>
                                        <div className='field'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <g clipPath="url(#clip0_424_3574)">
                                                    <path d="M10.892 9.95319L13.7237 12.7843L12.7882 13.7198L9.9571 10.8881C8.90368 11.7325 7.59346 12.1918 6.24338 12.1899C2.95875 12.1899 0.292969 9.5241 0.292969 6.23948C0.292969 2.95485 2.95875 0.289062 6.24338 0.289062C9.52801 0.289062 12.1938 2.95485 12.1938 6.23948C12.1957 7.58956 11.7364 8.89977 10.892 9.95319ZM9.5657 9.46262C10.4048 8.59974 10.8734 7.44305 10.8715 6.23948C10.8715 3.68212 8.80008 1.61138 6.24338 1.61138C3.68603 1.61138 1.61528 3.68212 1.61528 6.23948C1.61528 8.79617 3.68603 10.8676 6.24338 10.8676C7.44695 10.8695 8.60365 10.4009 9.46652 9.56179L9.5657 9.46262Z" fill="#878787" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_424_3574">
                                                        <rect width="13.8843" height="13.8843" fill="white" transform="translate(0.292969 0.289062)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <input className='input-field' placeholder='Search Job or Title' />
                                        </div>
                                    </div>

                                    <div className='col-lg-4'>
                                        <div className='field'>
                                            <input className='input-field' placeholder='Department' />
                                        </div>
                                    </div>

                                    <div className='col-lg-4'>
                                        <div className='field'>
                                            <input className='input-field' placeholder='Location' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <p className='mb-0 filter-helper-text'>
                                <span>21 roles</span>
                                {" "}across all locations and departments.
                            </p> */}
                        </div>

                        <div className='jobs'>
                            {
                                jobs.map((item, index) => (
                                    <div className='job' key={index}>
                                        <div className='job-title'>
                                            <p className='mb-0 title'>{item.title}</p>
                                        </div>
                                        {
                                            item.positions.map((ele, index) => (
                                                <div className='job-desc row'>
                                                    <p className='mb-0 position col-lg-5'>{ele.position}</p>
                                                    <p className='mb-0 location col-lg-4'>{ele.location}</p>
                                                    {/* <p className='mb-0 apply col-lg-3'>Apply</p> */}
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}