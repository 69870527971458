import { Link } from "react-router-dom"



export default function OtherIndustries() {

    return (
        <div className="w-100 other-industries" 
>
            <img className="background" src="/assets/img/products/bg.png"/>
            <div className="container row" >
                <div className="industries col-lg-6">
                <h2 className="title">Other Industries</h2>

                <div className="industries-list row g-5">

                    <Link to={"/industries/building-and-contruction-industry"} className="industry-item col-lg-4">
                        <div className="logo rotate90">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <rect width="40" height="40" rx="20" fill="#F38336" />
                                <path d="M15.1514 30.913C16.2544 27.3614 17.9456 24.064 20.111 21.1286C18.0789 18.3749 15.6281 15.9399 12.8499 13.916C9.92883 16.044 6.64698 17.7176 3.11133 18.8307V18.8366C8.45906 21.2857 12.7557 25.5903 15.1514 30.913Z" fill="white" />
                                <path d="M3.11133 9V18.8296C6.64734 17.7166 9.92919 16.0429 12.8503 13.9146C9.92919 11.786 6.64734 10.1131 3.11133 9Z" fill="white" />
                                <path d="M20.1113 21.127C22.2771 24.0624 23.9683 27.3598 25.0716 30.9114C27.4674 25.5887 31.764 21.2841 37.1114 18.835V18.8291C33.5761 17.716 30.2935 16.0424 27.3728 13.9141C24.5946 15.9379 22.1441 18.3733 20.1113 21.127Z" fill="white" />
                                <path d="M37.112 18.8296V9C33.5763 10.1131 30.2941 11.786 27.373 13.9146C30.2941 16.0426 33.5763 17.7166 37.112 18.8296Z" fill="white" />
                                <path d="M20.1139 21.127C17.9485 24.0623 16.2573 27.3597 15.1543 30.9114L15.1554 30.9128H25.0731L25.0742 30.9114C23.9709 27.3597 22.2796 24.0623 20.1139 21.127Z" fill="white" />
                            </svg>
                        </div>

                        <p className="industry-name">
                            Building & Construction Industry
                        </p>
                    </Link>


                    <Link to={"/industries/consumer-durables-industry"} className="industry-item col-lg-4">
                        <div className="logo rotate-90">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <rect width="40" height="40" rx="20" fill="#115E66" />
                                <path d="M15.1514 8.99907C16.2544 12.5507 17.9456 15.8481 20.111 18.7835C18.0789 21.5372 15.6281 23.9723 12.8499 25.9961C9.92883 23.8681 6.64698 22.1945 3.11133 21.0815V21.0755C8.45906 18.6264 12.7557 14.3218 15.1514 8.99907Z" fill="white" />
                                <path d="M3.11133 30.9121V21.0825C6.64734 22.1955 9.92919 23.8692 12.8503 25.9975C9.92919 28.1261 6.64734 29.7991 3.11133 30.9121Z" fill="white" />
                                <path d="M20.1113 18.7851C22.2771 15.8497 23.9683 12.5523 25.0716 9.00067C27.4674 14.3234 31.764 18.628 37.1114 21.0771V21.0831C33.5761 22.1961 30.2935 23.8697 27.3728 25.998C24.5946 23.9742 22.1441 21.5388 20.1113 18.7851Z" fill="white" />
                                <path d="M37.112 21.0825V30.9121C33.5763 29.7991 30.2941 28.1261 27.373 25.9975C30.2941 23.8695 33.5763 22.1955 37.112 21.0825Z" fill="white" />
                                <path d="M20.1139 18.7852C17.9485 15.8498 16.2573 12.5524 15.1543 9.00075L15.1554 8.99935H25.0731L25.0742 9.00075C23.9709 12.5524 22.2796 15.8498 20.1139 18.7852Z" fill="white" />
                            </svg>
                        </div>

                        <p className="industry-name">
                            Consumer Durables Industry
                        </p>
                    </Link>


                    <Link to={"/industries/textile-and-fibers-industry"} className="industry-item col-lg-4">
                        <div className="logo">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <rect width="40" height="40" rx="20" fill="#8A8B35" />
                                <path d="M9.15532 24.9169C12.7069 23.814 16.0044 22.1227 18.9397 19.9573C21.6934 21.9894 24.1285 24.4403 26.1523 27.2184C24.0244 30.1395 22.3508 33.4214 21.2377 36.957H21.2317C18.7827 31.6093 14.478 27.3126 9.15532 24.9169Z" fill="white" />
                                <path d="M31.0684 36.957H21.2387C22.3518 33.421 24.0254 30.1392 26.1537 27.2181C28.2824 30.1392 29.9553 33.421 31.0684 36.957Z" fill="white" />
                                <path d="M18.9413 19.957C16.006 17.7913 12.7085 16.1 9.15692 14.9967C14.4796 12.601 18.7843 8.30434 21.2333 2.95696H21.2393C22.3524 6.49226 24.026 9.77483 26.1543 12.6956C24.1305 15.4737 21.695 17.9242 18.9413 19.957Z" fill="white" />
                                <path d="M21.2387 2.95637H31.0684C29.9553 6.49202 28.2824 9.77423 26.1537 12.6953C24.0258 9.77423 22.3518 6.49202 21.2387 2.95637Z" fill="white" />
                                <path d="M18.9414 19.9545C16.0061 22.1199 12.7086 23.8111 9.157 24.9141L9.1556 24.913V14.9953L9.157 14.9942C12.7086 16.0975 16.0061 17.7887 18.9414 19.9545Z" fill="white" />
                            </svg>
                        </div>

                        <p className="industry-name">
                            Textiles & Fibre Industry
                        </p>
                    </Link>


                    <Link to={"/industries/packaging-industry"} className="industry-item col-lg-4">
                            <div className="logo rotate180">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <rect width="40" height="40" rx="20" fill="#C41E63" />
                                <path d="M31.0683 14.9952C27.5167 16.0982 24.2192 17.7894 21.2839 19.9548C18.5302 17.9227 16.0951 15.4719 14.0713 12.6937C16.1992 9.77258 17.8729 6.49073 18.9859 2.95508H18.9919C21.441 8.30281 25.7456 12.5995 31.0683 14.9952Z" fill="white" />
                                <path d="M9.15527 2.95508L18.9849 2.95508C17.8718 6.49109 16.1982 9.77294 14.0699 12.694C11.9412 9.77294 10.2683 6.49109 9.15527 2.95508Z" fill="white" />
                                <path d="M21.2823 19.9551C24.2176 22.1208 27.5151 23.8121 31.0667 24.9154C25.744 27.3111 21.4394 31.6078 18.9903 36.9551H18.9843C17.8713 33.4198 16.1976 30.1373 14.0693 27.2166C16.0932 24.4384 18.5286 21.9879 21.2823 19.9551Z" fill="white" />
                                <path d="M18.9849 36.9557H9.15527C10.2683 33.4201 11.9413 30.1379 14.0699 27.2168C16.1979 30.1379 17.8718 33.4201 18.9849 36.9557Z" fill="white" />
                                <path d="M21.2822 19.9576C24.2175 17.7922 27.515 16.101 31.0666 14.998L31.068 14.9991V24.9169L31.0666 24.9179C27.515 23.8146 24.2175 22.1234 21.2822 19.9576Z" fill="white" />
                            </svg>
                        </div>

                        <p className="industry-name">
                            Packaging Industry
                        </p>
                    </Link>

                    <Link to={"/industries/agriculture"} className="industry-item col-lg-4">
                        <div className="logo ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <rect width="40" height="40" rx="20" fill="#595DAA" />
                                <path d="M9.15532 24.9169C12.7069 23.814 16.0044 22.1227 18.9397 19.9573C21.6934 21.9894 24.1285 24.4403 26.1523 27.2184C24.0244 30.1395 22.3508 33.4214 21.2377 36.957H21.2317C18.7827 31.6093 14.478 27.3126 9.15532 24.9169Z" fill="white" />
                                <path d="M31.0684 36.957H21.2387C22.3518 33.421 24.0254 30.1392 26.1537 27.2181C28.2824 30.1392 29.9553 33.421 31.0684 36.957Z" fill="white" />
                                <path d="M18.9413 19.957C16.006 17.7913 12.7085 16.1 9.15692 14.9967C14.4796 12.601 18.7843 8.30434 21.2333 2.95696H21.2393C22.3524 6.49226 24.026 9.77483 26.1543 12.6956C24.1305 15.4737 21.695 17.9242 18.9413 19.957Z" fill="white" />
                                <path d="M21.2387 2.95637H31.0684C29.9553 6.49202 28.2824 9.77423 26.1537 12.6953C24.0258 9.77423 22.3518 6.49202 21.2387 2.95637Z" fill="white" />
                                <path d="M18.9414 19.9545C16.0061 22.1199 12.7086 23.8111 9.157 24.9141L9.1556 24.913V14.9953L9.157 14.9942C12.7086 16.0975 16.0061 17.7887 18.9414 19.9545Z" fill="white" />
                            </svg>
                        </div>

                        <p className="industry-name">
                            Agriculture Industry
                        </p>
                    </Link>

                    <Link to={"/industries/automotive"} className="industry-item col-lg-4">
                            <div className="logo rotate-90">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <rect width="40" height="40" rx="20" fill="#622466" />
                                    <path d="M15.1524 8.99906C16.2554 12.5507 17.9466 15.8481 20.112 18.7835C18.0799 21.5372 15.6291 23.9723 12.8509 25.9961C9.92981 23.8681 6.64796 22.1945 3.1123 21.0815V21.0755C8.46004 18.6264 12.7567 14.3218 15.1524 8.99906Z" fill="white" />
                                    <path d="M3.1123 30.9111V21.0815C6.64831 22.1946 9.93017 23.8682 12.8512 25.9965C9.93017 28.1252 6.64831 29.7981 3.1123 30.9111Z" fill="white" />
                                    <path d="M20.1133 18.7851C22.279 15.8498 23.9703 12.5523 25.0736 9.00067C27.4693 14.3234 31.766 18.628 37.1133 21.0771V21.0831C33.578 22.1961 30.2955 23.8697 27.3748 25.998C24.5966 23.9742 22.1461 21.5388 20.1133 18.7851Z" fill="white" />
                                    <path d="M37.1139 21.0815V30.9111C33.5783 29.7981 30.2961 28.1252 27.375 25.9965C30.2961 23.8685 33.5783 22.1946 37.1139 21.0815Z" fill="white" />
                                    <path d="M20.1148 18.7842C17.9494 15.8489 16.2582 12.5514 15.1553 8.99977L15.1563 8.99837H25.0741L25.0751 8.99977C23.9718 12.5514 22.2806 15.8489 20.1148 18.7842Z" fill="white" />
                                </svg>
                            </div>

                            <p className="industry-name">
                                Automotive Industry
                            </p>
                        </Link>
                        
                </div>
                </div>

                <div className="col-lg-4">

                </div>

            </div>
        </div>
    )
}