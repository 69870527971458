import ProductsSidebar from "../../Products/ProductsSideBar";
import IndustrySidebar from "../IndustrySidebar";


export default function Applications() {

    return (
        <>
            <div className="w-100 applications">
                <div className="container mx-auto hero-container">
                    <div className="w-100 row gy-5 custom-gx" >
                    <div className="col-lg-7" style={{ overflowY: "scroll" }}>
                            <div className="application-details">

                                <div className="details">
                                    <div className="title-container">
                                        <p className="title mb-0">Consumer Durables Industry</p>
                                    </div>

                                    <div className="description-container">
                                        <p className="description mb-0">
                                        ALOK Masterbatches elevates the consumer durables industry by providing masterbatch solutions that ensure product consistency, safety, and enhanced performance. Our masterbatches are tailored to meet the demands of products ranging from household appliances to toys and sports equipment, adhering to the highest standards of quality and regulatory compliance.
                                            <br />
                                            <br />
                                            <span>Partner with us to experience the next level of excellence in consumer durable polymer technology.</span>
                                        </p>

                                    </div>

                                </div>

                                <div className="application-list">
                                    <div className="row application">
                                        <div className="col-2 col-sm-1 p-0 logo">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                                <rect width="40" height="40" rx="20" fill="#622466" />
                                                <path d="M15.1524 8.99906C16.2554 12.5507 17.9466 15.8481 20.112 18.7835C18.0799 21.5372 15.6291 23.9723 12.8509 25.9961C9.92981 23.8681 6.64796 22.1945 3.1123 21.0815V21.0755C8.46004 18.6264 12.7567 14.3218 15.1524 8.99906Z" fill="white" />
                                                <path d="M3.1123 30.9121V21.0825C6.64831 22.1955 9.93017 23.8692 12.8512 25.9975C9.93017 28.1261 6.64831 29.7991 3.1123 30.9121Z" fill="white" />
                                                <path d="M20.1133 18.7851C22.279 15.8498 23.9703 12.5523 25.0736 9.00067C27.4693 14.3234 31.766 18.628 37.1133 21.0771V21.0831C33.578 22.1961 30.2955 23.8697 27.3748 25.998C24.5966 23.9742 22.1461 21.5388 20.1133 18.7851Z" fill="white" />
                                                <path d="M37.1139 21.0825V30.9121C33.5783 29.7991 30.2961 28.1261 27.375 25.9975C30.2961 23.8695 33.5783 22.1955 37.1139 21.0825Z" fill="white" />
                                                <path d="M20.1148 18.7852C17.9494 15.8498 16.2582 12.5524 15.1553 9.00075L15.1563 8.99935H25.0741L25.0751 9.00075C23.9718 12.5524 22.2806 15.8498 20.1148 18.7852Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div className="col-10 col-sm-11 pl-3">
                                            <div className="details">
                                                <p className="title mb-0">Household Appliances</p>
                                                <p className="description mb-0">Our masterbatches play a pivotal role in the aesthetics and functionality of household appliances such as washing machines, refrigerators, and microwaves. These products benefit from enhanced color stability, heat resistance, and durability, ensuring they withstand everyday use while maintaining their visual appeal.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row application">
                                        <div className="col-2 col-sm-1 p-0 logo">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                                <rect width="40" height="40" rx="20" fill="#622466" />
                                                <path d="M15.1524 8.99906C16.2554 12.5507 17.9466 15.8481 20.112 18.7835C18.0799 21.5372 15.6291 23.9723 12.8509 25.9961C9.92981 23.8681 6.64796 22.1945 3.1123 21.0815V21.0755C8.46004 18.6264 12.7567 14.3218 15.1524 8.99906Z" fill="white" />
                                                <path d="M3.1123 30.9121V21.0825C6.64831 22.1955 9.93017 23.8692 12.8512 25.9975C9.93017 28.1261 6.64831 29.7991 3.1123 30.9121Z" fill="white" />
                                                <path d="M20.1133 18.7851C22.279 15.8498 23.9703 12.5523 25.0736 9.00067C27.4693 14.3234 31.766 18.628 37.1133 21.0771V21.0831C33.578 22.1961 30.2955 23.8697 27.3748 25.998C24.5966 23.9742 22.1461 21.5388 20.1133 18.7851Z" fill="white" />
                                                <path d="M37.1139 21.0825V30.9121C33.5783 29.7991 30.2961 28.1261 27.375 25.9975C30.2961 23.8695 33.5783 22.1955 37.1139 21.0825Z" fill="white" />
                                                <path d="M20.1148 18.7852C17.9494 15.8498 16.2582 12.5524 15.1553 9.00075L15.1563 8.99935H25.0741L25.0751 9.00075C23.9718 12.5524 22.2806 15.8498 20.1148 18.7852Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div className="col-10 col-sm-11 pl-3">
                                            <div className="details">
                                                <p className="title mb-0">Children’s Toys</p>
                                                <p className="description mb-0">Safety and color vibrancy are critical in children’s toys. ALOK Masterbatches delivers solutions that are non-toxic and durable, ensuring toys remain attractive and safe for children during prolonged use, and supporting compliance with stringent safety standards.</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row application">
                                        <div className="col-2 col-sm-1 p-0 logo">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                                <rect width="40" height="40" rx="20" fill="#622466" />
                                                <path d="M15.1524 8.99906C16.2554 12.5507 17.9466 15.8481 20.112 18.7835C18.0799 21.5372 15.6291 23.9723 12.8509 25.9961C9.92981 23.8681 6.64796 22.1945 3.1123 21.0815V21.0755C8.46004 18.6264 12.7567 14.3218 15.1524 8.99906Z" fill="white" />
                                                <path d="M3.1123 30.9121V21.0825C6.64831 22.1955 9.93017 23.8692 12.8512 25.9975C9.93017 28.1261 6.64831 29.7991 3.1123 30.9121Z" fill="white" />
                                                <path d="M20.1133 18.7851C22.279 15.8498 23.9703 12.5523 25.0736 9.00067C27.4693 14.3234 31.766 18.628 37.1133 21.0771V21.0831C33.578 22.1961 30.2955 23.8697 27.3748 25.998C24.5966 23.9742 22.1461 21.5388 20.1133 18.7851Z" fill="white" />
                                                <path d="M37.1139 21.0825V30.9121C33.5783 29.7991 30.2961 28.1261 27.375 25.9975C30.2961 23.8695 33.5783 22.1955 37.1139 21.0825Z" fill="white" />
                                                <path d="M20.1148 18.7852C17.9494 15.8498 16.2582 12.5524 15.1553 9.00075L15.1563 8.99935H25.0741L25.0751 9.00075C23.9718 12.5524 22.2806 15.8498 20.1148 18.7852Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div className="col-10 col-sm-11 pl-3">
                                            <div className="details">
                                                <p className="title mb-0">Sports Equipment</p>
                                                <p className="description mb-0">In the realm of sports equipment, our masterbatches contribute to the manufacture of items that are robust, weather-resistant, and visually appealing. Whether it’s outdoor gear or gym equipment, our products help maintain the integrity and appearance of sports equipment under various environmental conditions.</p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 position-relative"  >
                        <IndustrySidebar allowed={[1,2,3,4,6,7,8]} />

                            {/* <div className="products-list">

                                <div className="title-container">
                                    <p className="mb-0 title">Products</p>
                                </div>


                                <div className="consumer-products">

                                    <div className="product">
                                        <div className="image-container">
                                            <img src="/assets/img/products/product-1.png"/>
                                        </div>
                                        <div className="details">
                                            <p className="title mb-0">UV Stabilized Masterbatch</p>
                                            <p className="description mb-0">
                                            Ensures long-term color retention and resistance to weathering in outdoor and indoor applications
                                            <span>...read more</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="product">
                                        <div className="image-container">
                                            <img src="/assets/img/products/product-1.png"/>
                                        </div>
                                        <div className="details">
                                            <p className="title mb-0">Antimicrobial Masterbatch</p>
                                            <p className="description mb-0">
                                            Enhances the hygiene of products by inhibiting the growth of bacteria and mold, crucial for applications like children’s toys and sports equipment
                                            <span>...read more</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="product">
                                        <div className="image-container">
                                            <img src="/assets/img/products/product-1.png"/>
                                        </div>
                                        <div className="details">
                                            <p className="title mb-0">Impact Modifier Masterbatch</p>
                                            <p className="description mb-0">
                                            Provides increased toughness and durability, essential for high-impact applications such as sports equipment and appliance components
                                            <span>...read more</span>
                                            </p>
                                        </div>
                                    </div>

                                    

                                </div>

                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}