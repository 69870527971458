import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import team_data from "../../../data/teamData.json";
import Slider from "react-slick";

const Team = () => {

  const teams = [
    {
      image: "/assets/img/team/team-1.png",
      name: "Vikram Bhadauria",
      post: "Managing Director",
      id: 1
    },
    {
      image: "/assets/img/team/team-2.png",
      name: "Amit Puri",
      post: "Director",
      id: 2
    },
    // {
    //   image: "/assets/img/team/team-1.png",
    //   name: "Aditya Bhadauria",
    //   post: "Managing Director",
    //   id: 3
    // },
  ]

  const settings = {
    infinite: true,
    lazyLoad: true,
    // centerMode: true,
    speed: 500,
    slidesToShow: 2,
    // customPaging: "50px",
    // slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    dots: false,
    // fade: true,
    arrows: false,
    pauseOnHover: false, // Pause carousel on hover
    afterChange: (current) => {
      setActiveSlide(current)
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
    slidesToShow: 1.1,
        },
      },
    ],
  };


  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <>
      {/* <!-- team area start  --> */}
      <div className="tp-team-area pt-200 pb-110 grey-bg-4" id="teamSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="tp-section-wrapper text-start">
                <span className="tp-section-subtitle mb-30">
                  Our Team
                </span>
                <h2 className="tp-section-title">
                  People behind our Masterbatches team
                </h2>


                <div className="offcanva-btn accent-arrow-btn mt-60 d-none d-lg-flex">
                  <Link
                    to="/contact-us"
                    className="tp-slider-btn d-inline-block"
                    tabIndex="-1"
                  >
                    <span>
                      <svg
                        width="53"
                        height="8"
                        viewBox="0 0 53 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                          fill="#7738A5"
                        ></path>
                      </svg>
                      <svg
                        width="53"
                        height="8"
                        viewBox="0 0 53 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                          fill="#7738A5"
                        ></path>
                      </svg>
                    </span>
                    View All
                  </Link>
                </div>


                {/* <div className="view-all mt-60 d-none d-lg-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" width="78" height="56" viewBox="0 0 78 56" fill="none">
                    <circle cx="50.5" cy="28" r="27" stroke="#7738A5" />
                    <path d="M52.3536 28.3536C52.5488 28.1583 52.5488 27.8417 52.3536 27.6464L49.1716 24.4645C48.9763 24.2692 48.6597 24.2692 48.4645 24.4645C48.2692 24.6597 48.2692 24.9763 48.4645 25.1716L51.2929 28L48.4645 30.8284C48.2692 31.0237 48.2692 31.3403 48.4645 31.5355C48.6597 31.7308 48.9763 31.7308 49.1716 31.5355L52.3536 28.3536ZM-3.11023e-08 28.5L52 28.5L52 27.5L3.11023e-08 27.5L-3.11023e-08 28.5Z" fill="#622466" />
                  </svg>
                  View All
                </div> */}

              </div>
            </div>


            <div className="col-lg-8">

              <Slider  {...settings} ref={sliderRef}>
                {teams.map((item, index) => (
                  <div key={index} className="px-2 ">
                    <div className="team">
                      <img src={item.image} className="team-image" />
                      <div className="details-card">
                        <p className="title">{item.name}</p>
                        <p className="post">{item.post}</p>
                        <a className="linkedin">
                          View Profile
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect width="24" height="24" rx="3" fill="#622466" />
                            <path d="M7.38539 9.44286H4.25078V19.4736H7.38539V9.44286ZM7.66751 4.81808C7.66916 4.58098 7.62409 4.34587 7.53487 4.12617C7.44567 3.90648 7.31405 3.70651 7.14755 3.53769C6.98105 3.36886 6.78294 3.23448 6.56451 3.14223C6.34608 3.04997 6.11161 3.00164 5.87451 3H5.81808C5.3359 3 4.87346 3.19155 4.5325 3.5325C4.19155 3.87346 4 4.3359 4 4.81808C4 5.30027 4.19155 5.76271 4.5325 6.10367C4.87346 6.44462 5.3359 6.63617 5.81808 6.63617C6.05521 6.642 6.29116 6.60105 6.51245 6.51566C6.73374 6.43028 6.93605 6.30213 7.1078 6.13853C7.27954 5.97493 7.41738 5.77909 7.51342 5.5622C7.60945 5.34532 7.66182 5.11164 7.66751 4.87451V4.81808ZM20.4736 13.38C20.4736 10.3645 18.5552 9.1921 16.6494 9.1921C16.0254 9.16085 15.4041 9.29375 14.8474 9.57756C14.2909 9.86133 13.8183 10.2861 13.4772 10.8095H13.3894V9.44286H10.4428V19.4736H13.5775V14.1385C13.5322 13.5922 13.7043 13.0499 14.0564 12.6297C14.4085 12.2094 14.9123 11.9451 15.4582 11.8942H15.5774C16.5742 11.8942 17.3139 12.5211 17.3139 14.1009V19.4736H20.4486L20.4736 13.38Z" fill="white" />
                          </svg>
                        </a>
                      </div>

                    </div>
                  </div>
                ))}
              </Slider>


              <div className="view-all mt-60 d-flex d-lg-none">
                  <svg xmlns="http://www.w3.org/2000/svg" width="78" height="56" viewBox="0 0 78 56" fill="none">
                    <circle cx="50.5" cy="28" r="27" stroke="#7738A5" />
                    <path d="M52.3536 28.3536C52.5488 28.1583 52.5488 27.8417 52.3536 27.6464L49.1716 24.4645C48.9763 24.2692 48.6597 24.2692 48.4645 24.4645C48.2692 24.6597 48.2692 24.9763 48.4645 25.1716L51.2929 28L48.4645 30.8284C48.2692 31.0237 48.2692 31.3403 48.4645 31.5355C48.6597 31.7308 48.9763 31.7308 49.1716 31.5355L52.3536 28.3536ZM-3.11023e-08 28.5L52 28.5L52 27.5L3.11023e-08 27.5L-3.11023e-08 28.5Z" fill="#622466" />
                  </svg>
                  View All
                </div>

            </div>

          </div>

        </div>
      </div>
      {/* <!-- team area end  --> */}
    </>
  );
};

export default Team;
