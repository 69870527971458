import React from "react";
import { Link } from "react-router-dom";

const AboutBanner = () => {
  return (
    <>
      {/* <!-- breadcrumb area start --> */}
      <section
        className="about_banner"
        // style={{ height: "100vh" }}
      >
        <div className="container h-100">
          <div className="row h-100">
            

            <div className="m-auto mt-30" align="center">
              <div className="about-hero-2" align="center">
                  <div className="number-container">
                    <p className="number m-0">2</p>
                  </div>
                  <p className="number m-0">+</p>
                  <div className="number-container">
                    <p className="number m-0">2</p>
                  </div>
                  <p className="number m-0">=</p>
                  <div className="result-container">
                    <p className="result m-0">5</p>
                  </div>
              </div>
            </div>
            <div className=" ">

              <div className="about-hero-1" align="center">

                <p className="subtitle m-0">
                  “There is nothing so powerful as an idea whose time has come”
                </p>
                <p className="author m-auto text-center">VICTOR HUGO</p>
              </div>

            </div>
          </div>
        </div>
      </section>
      {/* <!-- breadcrumb area end --> */}
    </>
  );
};

export default AboutBanner;
