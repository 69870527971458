import React from 'react'



export default function JobForm() {

    return (
        <>
            <div className='job-form-section' id="joinform">
                <div className='container mx-auto'>
                    <div className='row g-5'>
                        <div className='col-lg-6'>
                            <div className='form-image'>
                                <img src='/assets/img/career/job-form.jpg' className='image' />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className="form">

                                <div className="form-fields">
                                    <div className="form-title-container">
                                        <h3 className="form-title">Join Us @ALOK</h3>
                                        <h2 className="form-subtitle">Join an Amazing team</h2>
                                    </div>

                                    <div className="row fields ">
                                        <div className="col-lg-6">
                                            <input placeholder="Full Name*" className=" input-field" />
                                        </div>
                                        <div className="col-lg-6">
                                            <input placeholder="Personal Email*" className="col-lg-6 input-field" />
                                        </div>
                                        <div className="col-lg-6">
                                            <input placeholder="Phone Number*" className="col-lg-6 input-field" />
                                        </div>
                                        <div className="col-lg-6">
                                            <select placeholder="Role Type" className="col-lg-6 select-field">
                                                <option disabled selected>Role Type</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-6">
                                            <input placeholder="Work Experience (in years)" className=" input-field" />

                                        </div>
                                        <div className="col-lg-6">
                                            <input placeholder="Current Organisation" className=" input-field" />


                                        </div>
                                        <div className="col-lg-12">
                                            <input placeholder="Address*" className=" input-field" />

                                        </div>
                                        <div className="col-lg-12">
                                            {/* <textarea placeholder="Enter your message" className=" input-field" rows={5} ></textarea> */}
                                            <div className="file-upload">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
                                                    <path d="M30.9331 28.0813L24.9548 22.6465L18.9766 28.0813" stroke="#622466" strokeWidth="2.17391" />
                                                    <path d="M24.9551 40.7624V22.6465" stroke="#622466" strokeWidth="2.17391" />
                                                    <path d="M37.9991 38.2244V41.4852C37.9991 43.2862 36.5391 44.7461 34.7382 44.7461H15.173C13.372 44.7461 11.9121 43.2862 11.9121 41.4852V38.2244" stroke="#622466" strokeWidth="2.17391" />
                                                    <path d="M35.2818 31.3409C39.7841 31.3409 43.434 27.691 43.434 23.1887C43.434 18.6864 39.7841 15.0365 35.2818 15.0365C35.2818 9.63373 30.902 5.25391 25.4992 5.25391C21.2172 5.25391 17.5778 8.00509 16.2521 11.8362C11.3647 12.3806 7.56445 16.5256 7.56445 21.5583C7.56445 26.4055 11.0899 30.4294 15.7166 31.2055" stroke="#622466" strokeWidth="2.17391" />
                                                </svg>
                                                <div className="file-upload-title">
                                                    <p className="mb-0 title">Drag or Upload Resume</p>
                                                    <p className="mb-0 desc">JPG/PNG/PDF allowed only (max. size: 10MB)</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <button type="submit" className="tp-btn-border">
                                                Submit Details{" "}
                                                <span>
                                                    <svg
                                                        width="22"
                                                        height="8"
                                                        viewBox="0 0 22 8"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                    <svg
                                                        width="22"
                                                        height="8"
                                                        viewBox="0 0 22 8"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                                <div className="terms-container">
                                    <p className="m-0 required-text">Fields marked with an asterisk (*) are required.</p>
                                    
                                    <div className="terms">
                                        <div className="terms-check">
                                            <input className="checkbox" type="checkbox" />
                                        </div>

                                        <p className="term-text">By clicking the "Submit Details" button above, you acknowledge that ALOK may use the information you provide to contact you about ALOK’s products and services and you agree to be contacted by Coda in accordance with ALOK’s Privacy Policy: alokmasterbatches.com/privacy.</p>
                                    </div>


                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}