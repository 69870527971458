import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import CommonBanner from '../../common/CommonBanner'
import { Link } from 'react-router-dom'
import ContactCard from '../Industries/ContactCard'
import CommonContactCard from '../../common/CommonContactCard'

function TermsAndCondition() {
  return (
    <>
      <Menu />
      <CommonBanner imageUrl={'/assets/img/sustainability.png'} title={"Terms And Condition"} subtext={""} />
      <>
        
      </>
      <div className='mt-130'>
      <CommonContactCard />
      </div>
      <TopFooter />
      <Footer />
    </>
  )
}

export default TermsAndCondition