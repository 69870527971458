import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import './Timeline.css';

const Timeline = () => {
  const sections = [
    { id: 'heroSection', label: 'HOME', height: "0px" },
    { id: 'aboutSection', label: 'ABOUT US', height: "57.5px" },
    { id: 'achievementSection', label: 'ACHIEVEMENTS', height: "135px" },
    { id: 'industriesSection', label: 'OUR INDUSTRIES', height: "200px" },
    // { id: 'newsSection', label: 'Exhibitions', height: "260px" },
    // { id: 'blogSection', label: 'OUR BLOGS',height: "325px" },
    { id: 'contactSection', label: 'CONTACT US', height: "260px" },
  ];

  const sectionRefs = useRef({});
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id);
            document.getElementById('timeline').style.setProperty("--timeline-progress-height",sections.find((ele)=>ele.id === entry.target.id).height)
          }
        });
      },
      { threshold: 0.5 }
    );

    sections.forEach((section) => {
      const element = document.getElementById(section.id);
      if (element) observer.observe(element);
    });

    return () => {
      sections.forEach((section) => {
        const element = document.getElementById(section.id);
        if (element) observer.unobserve(element);
      });
    };
  }, [sections]);

  return (
    <div className="timeline d-none d-lg-flex" id='timeline'>
      {/* <div className="line"></div> */}
      {sections.map((item, index) => (
        <div key={index} className={`timeline-item ${activeId === item.id ? 'timeline-item timeline-item-active' : ''}`}>
          <p className="label m-0">{item.label}</p>
          <a className="circle-outer-ring" href={'/#'+item.id}>
          <div className="circle">
          </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
