import React from "react";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      {/* <!-- service area start  --> */}
      <div className="w-100">
        <div className="container mx-auto about-location">
            <div className="location-container mb-5">
                <div className="location-text-container">
                    <p className="mb-0 subtitle" style={{color : "#fff"}}>Our Global Footprints</p>
              <h2 className="mb-0 title">Adding good - To make plastics Safer, Affordable & Sustainable</h2>
                </div>
                
                <button type="submit" className="tp-btn-border">
                     Contact Us{" "}
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </button>

            </div>

            <div className="map-container">
                <img src="/assets/img/about/about-india-map.png"/>
            </div>
        </div>
      </div>
      {/* <!-- service area end  -->   */}
    </>
  );
};

export default Services;
