import React from 'react';

function OurPhiloshophy() {
    return (
        <div className='w-100'>
            <section className="philosophySection">

                <div className=' mx-auto '>
                    <img src='/assets/img/ethics.png' className='max-w-100'/>
                    {/* <div className='title-container' >
                        <h2 className='title m-0'>OUR PHILOSOPHY</h2>
                        <h3 className='subtitle m-0'>The ‘Four’ Pillars of Change</h3>
                    </div> */}
                    {/* <div className="pillarsContainer">
        <img src='/assets/img/philoshophy.png' className='max-w-100'/>

                    </div> */}

                </div>
            </section>
        </div>

    );
}

export default OurPhiloshophy;