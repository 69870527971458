import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import IntroBanner from '../../common/IntroBanner'
import ProductContact from './ProductContact'
import ProductsSidebar from '../Products/ProductsSideBar'
import OtherProducts from '../Products/OurProducts'
import IndustriesFooter from '../Industries/IndustriesFooter'

function Color() {
  return (
      <>
      <Menu />

      <IntroBanner imageUrl={'/assets/img/products/color.jpg'} title={"Our Product"} subtext={"Color Masterbatches and Single Pigment Concentrates Vivid Colors, Infinite Possibilities"} textAvatar="CM" />
      <div className="container">
        <div className='row gx-80 masterclass-container '>
          <div className='col-md-8'>
            <div >
              <div className='title-container'>
                <h3 className='title'>Largest Manufacturers of Color Masterbatches and Single Pigment Concentrates</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>At ALOK, we understand that color is not just an aesthetic choice but a crucial element of product design. Our Color Masterbatches and Single Pigment Concentrates are crafted to meet diverse technical and creative needs across industries. With advanced technologies and an expansive color library, we provide custom and standard color solutions that enhance product appeal and functionality. Our offerings are designed for excellent dispersion, consistent coloration, and superior performance in all conventional carrier materials and engineering thermoplastics.</p>
              </div>

              <div className='title-container'>
                <h3 className='title'>Masterbatches for Color and Single Pigment Concentrates</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK Masterbatches provide consistent coloration and superior dispersion across various engineering thermoplastics. Our products are engineered to enhance both the aesthetic appeal and functionality of your products.</p>
              </div>

              <div className=" ">
                <div className="row">
                  <div className="col overflow-auto">
                    <table className="table table-bordered masterclass-table">
                      <thead>
                        <tr className="table-header" style={{ backgroundColor: 'black', color: 'white' }}>
                          <th scope="col">Products</th>
                          <th scope="col">Description</th>
                          <th scope="col">Application</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Standard Color Masterbatches</td>
                          <td>Designed to meet general color requirements across a wide range of applications.</td>
                          <td>High color strength, UV stability, and easy processing.</td>
                        </tr>
                        <tr>
                          <td>Custom Color Masterbatches</td>
                          <td>Tailor-made solutions created to match specific customer requirements and applications.</td>
                          <td>Customized hues, optimized for specific polymers and end-uses.</td>
                        </tr>
                        <tr>
                          <td>Special Effects Master batches</td>
                          <td>Includes options like metallic, fluorescent, pearlescent, and transparent effects.</td>
                          <td>Enhances product aesthetics with unique visual and tactile effects.</td>
                        </tr>
                        <tr>
                          <td>Food Contact Color Masterbatches</td>
                          <td>Suitable for applications that require direct contact with food, complying with food safety regulations.</td>
                          <td>Safe for use in food packaging, utensils, and containers.</td>
                        </tr>
                        <tr>
                          <td>High-Performance Pigments</td>
                          <td>Single pigment concentrates designed for excellent dispersion and stability in demanding applications.</td>
                          <td>Low viscosity, high fastness, and resistance to heat and weather.</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <ProductContact />
          </div>
          <div className='col-md-4'>
            <ProductsSidebar />
          </div>
        </div>

      </div>
        {/* <OtherProducts/> */}
      <TopFooter />
      <Footer />
      {/* <IndustriesFooter/> */}
      </>
  )
}

export default Color