import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Calendar from "../common/CustomCalender";

const OurBlogs = () => {
    const [selectedDate, setSelectedDate] = useState(new Date);
    const industries = [
        {
            image: "/assets/img/blog/blog.jpeg",
            title: "Database Technology At An Agency (Case Study)",
            tag: "BUSINESS",
            id: 1,
            date: "2024-07-12T18:30:00.000Z"
        },
        {
            image: "/assets/img/blog/blog2.jpeg",
            title: "How To Design A Simple UI When You Have A Complex Solution",
            tag: "LEARNING",
            id: 2,
             date: "2024-07-13T18:30:00.000Z"
        },
        {
            image: "/assets/img/blog/blog3.jpeg",
            title: "How To Design A Simple UI When You Have A Complex Solution",
            tag: "BUSINESS",
            id: 3,
             date: "2024-07-14T18:30:00.000Z"
        },
        {
            image: "/assets/img/blog/blog.jpeg",
            title: "Database Technology At An Agency (Case Study)",
            tag: "BUSINESS",
            id: 1,
             date: "2024-07-15T18:30:00.000Z"
        }
    ]


    const settings = {
        infinite: false,
        lazyLoad: true,
        // centerMode: true,
        speed: 500,
        slidesToShow: 3,
        // customPaging: "50px",
        // slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 7000,
        dots: false,
        // fade: true,
        arrows: false,
        // pauseOnHover: false, // Pause carousel on hover
        afterChange: (current) => {
            setSlideIndex(current)
        },
        beforeChange: (prev, next) => {
        },
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    centerMode: false,
                },
            },
            {
                breakpoint: 760,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    centerMode: false,
                },
            },
            {
                breakpoint: 520,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    centerMode: false,
                },
            },
        ],
    };


    const sliderRef = useRef(null);
    const sliderSectionRef = useRef(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const [slideIndex, setSlideIndex] = useState(0);



    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         (entries) => {
    //             entries.forEach((entry) => {
    //                 // if (entry.isIntersecting) {
    //                 //     setIsAutoplaying(true);
    //                 // } else {
    //                 //     setIsAutoplaying(false);
    //                 // }
    //                 // alert("Entered to that ZONE")
    //                 sliderRef.current.slickPlay();
    //             });
    //         },
    //         { threshold: 0.5 }
    //     );

    //     if (sliderSectionRef.current) {
    //         observer.observe(sliderSectionRef.current);
    //     }

    //     return () => {
    //         if (sliderSectionRef.current) {
    //             // alert("Exited to that ZONE")
    //             observer.unobserve(sliderSectionRef.current);
    //         }
    //     };
    // }, []);


    return (
        <>
            {/* <!-- cta area start --> */}
            <div className="tp-cta-area our-blogs pt-105 pb-105 mb-105 " id="blogSection" ref={sliderSectionRef}>
                <div className="container">
                    <div className="tp-cta-wrapper pb-30">
                        <div className="row align-items-center justify-content-evenly">

                            <div className="col-lg-12 d-flex justify-content-between align-items-end">
                                <div className="tp-section-wrapper">
                                    <p className="tp-section-subtitle mb-25">
                                        Exhibition Calender
                                    </p>
                                    <h2 className="tp-section-title mb-25">
                                        Upcoming Events
                                    </h2>
                                </div>

                                {/* <div className="d-none d-lg-flex blogs-slider-arrows">
                                    <button
                                        onClick={() => {
                                            if (slideIndex !== 0) {
                                                sliderRef.current?.slickPrev()
                                            }
                                        }}
                                        type="button"
                                    >
                                        {
                                            slideIndex === 0 ?
                                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.2">
                                                        <circle cx="27" cy="27" r="26" stroke={"#9E9FA3"} strokeWidth="2" />
                                                        <path d="M31.5 36L22.5 27L31.5 18" stroke={"#9E9FA3"} strokeWidth="2" strokeLinejoin="round" />
                                                    </g>
                                                </svg>
                                                :

                                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.98">
                                                        <circle cx="27" cy="27" r="26" stroke={"white"} strokeWidth="2" />
                                                        <path d="M31.5 36L22.5 27L31.5 18" stroke={"white"} strokeWidth="2" strokeLinejoin="round" />
                                                    </g>
                                                </svg>
                                        }

                                    </button>
                                    <button
                                        onClick={() => {
                                            if (slideIndex !== industries.length / 2) {
                                                sliderRef.current?.slickNext()
                                            }
                                        }}
                                        type="button"
                                    >
                                        {
                                            (slideIndex === industries.length / 2) ?
                                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle opacity="0.2" cx="27" cy="27" r="26" stroke={"#9E9FA3"} strokeWidth="2" />
                                                    <path d="M22.5 36L31.5 27L22.5 18" stroke={"#9E9FA3"} strokeWidth="2" strokeLinejoin="round" />
                                                </svg>
                                                :
                                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle opacity="0.98" cx="27" cy="27" r="26" stroke={"white"} strokeWidth="2" />
                                                    <path d="M22.5 36L31.5 27L22.5 18" stroke={"white"} strokeWidth="2" strokeLinejoin="round" />
                                                </svg>
                                        }

                                    </button>

                                </div> */}

                            </div>

                            <div className="col-lg-12 mb-25">
                                <div className="upcoming-events-date-container">
                                    <div className="upcoming-date">
                                        <p className="date mb-0">AUG 31, 2024</p>
                                    </div>

                                    <div className="upcoming-date">
                                        <p className="date mb-0">SEP 21, 2024</p>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>

                    {/* <!-- line  --> */}
                    {/* <div className="tp-border-line d-block mb-60">
                        <hr />
                    </div> */}
                    {/* <!-- line  --> */}
                    <div className="row mb-75 gx-lg-5 gy-5">
                        <div className="col-lg-6">

                            <Calendar setSelectedDate={setSelectedDate} selectedDate={selectedDate} dates={industries.map((item)=>item.date)} />
                            {/* <Slider  {...settings} ref={sliderRef}>
                                {industries.map((item, index) => (
                                    <div key={index} className="px-2 ">
                                        <div className="blog">
                                            <img src={item.image} className="blog-image" />
                                            <p className="blog-tag">{item.tag}</p>
                                            <div className="overlay">
                                                <p className="title">{item.title}</p>
                                                <a className="read-more"> READ MORE <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                    <path d="M15 15.5V5.5H5" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
                                                    <path d="M14.5 6L5.00195 15.498" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
                                                </svg></a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider> */}
                        </div>

                        <div className="col-lg-6">
                            <div className="blog">
                                <img src={industries.find((item,index)=>item.date === selectedDate.toJSON())?.image || "/assets/img/blog/blog2.jpeg"} className="blog-image" />
                                <p className="blog-tag">{industries.find((item,index)=>item.date === selectedDate.toJSON())?.tag || "BUSINESS"}</p>
                                <div className="overlay">
                                    <p className="title">{industries.find((item,index)=>item.date === selectedDate.toJSON())?.title || "Database Technology At An Agency (Case Study)"}</p>
                                    <a className="read-more"> READ MORE <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                        <path d="M15 15.5V5.5H5" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
                                        <path d="M14.5 6L5.00195 15.498" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row gx-lg-5">

                        {
                            industries.map((item, index) => (
                                <div className="col-lg-6 mb-25" key={index}>
                                    <div className="blog2 row gy-">

                                        <div className="image-container col-lg-4">
                                            <img src={item.image} className="image max-w-100" />
                                            <div className="overlay"></div>
                                        </div>

                                        <div className="blog-details col-lg-8">
                                            <p className="blog-tag">{item.tag}</p>
                                            <p className="title">{item.title}</p>
                                            <a className="read-more"> READ MORE <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                <path d="M15 15.5V5.5H5" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
                                                <path d="M14.5 6L5.00195 15.498" stroke="white" strokeWidth="1.2" strokeLinejoin="round" />
                                            </svg></a>
                                        </div>

                                    </div>
                                </div>
                            ))
                        }



                    </div>

                </div>
            </div>
            {/* <!-- cta end end --> */}
        </>
    );
};

export default OurBlogs;
