

export default function HeroSection() {

    return(
        <>
            <div className="w-100 industries-hero">
                <div className="container mx-auto hero-container">
                    <p className="subtitle">Our Industries</p>
                    <p className="title">Driving Innovation with High-Performance <br/>Automotive Masterbatches</p>
                </div>
            </div>
        </>
    )
}