import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import IntroBanner from '../../common/IntroBanner'
import ProductContact from './ProductContact'
import ProductsSidebar from './ProductsSideBar'
import OtherProducts from './OurProducts'
import IndustriesFooter from '../Industries/IndustriesFooter'

function Products() {
  return (
    <>
      <Menu />

      <IntroBanner imageUrl={'/assets/img/products/product-bg.png'} title={"Our Products"} subtext={"UV Masterbatch (Light stabilixation Solutions)"} textAvatar={"UV"} />
      <div className="container">
        <div className='row gx-5 masterclass-container '>
          <div className='col-md-7'>
            <div >
              <div className='title-container'>
                <h3 className='title'>Largest Manufacturers of UV Masterbatches</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK is the largest manufacturer of UV Masterbatches in India. With a team of dedicated research scientists with unparalleled experience in the field of Light Stabilisation of polymers, we offer our customers the expertise in material science and degradation behaviours. Our custom product portfolio for UV Masterbatches ranges from elementary applications like raffia, FIBC and outdoor furniture to more complex products for automotive, Agriculture and Fibre applications. Our products are designed to have low interaction with other additives used in polymer processing. For applications where aggressive chemical exposure can be experienced ALOK offers custom formulations that are resistant to pesticides, sulphur and other noxious chemicals.</p>
              </div>

              <div className='title-container pt-80'>
                <h3 className='title'>UV Masterbatches Applications</h3>
              </div>
              <div className='subtitle subtitle-padding'>
                <p className='subtitle_text' style={{ fontSize: "16px" }}>For water management applications in the building and construction sector, we offer a range of UV Masterbatches that are resistant to hydrolysis. Here are some of them:</p>
              </div>

              <div className=" ">
                <div className="row">
                  <div className="col overflow-auto">
                    <table className="table table-bordered masterclass-table">
                      <thead>
                        <tr className="table-header" style={{ backgroundColor: 'black', color: 'white' }}>
                          <th scope="col">Products</th>
                          <th scope="col">Description</th>
                          <th scope="col">Application</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>UvNox FGL</td>
                          <td>Food contact safe suitable for PE & PP applications</td>
                          <td>Food contact safe suitable for PE & PP applications</td>
                        </tr>

                        <tr>
                          <td>UvNox 20</td>
                          <td>Mineral filled, non-food grade UV for woven sacks, FIBC & other PP applications</td>
                          <td>Mineral filled, non-food grade UV for woven sacks, FIBC & other PP applications</td>
                        </tr>

                        <tr>
                          <td>UvNox FGL (WT)</td>
                          <td>Economical, mineral filled, food grade white color UV for woven sack & FIBC</td>
                          <td>Economical, mineral filled, food grade white color UV for woven sack & FIBC</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>


              <div className='key-benefits'>
                <div className='title-container'>
                  <h3 className='title'>Key Benefits</h3>
                </div>
                <div className='features'>
                  <div className='feature row'>
                    <div className='col-1 bar'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20" fill="none">
                        <path d="M2 20L2 -2.98023e-07" stroke="#171717" strokeWidth="3" />
                      </svg>
                    </div>

                    <div className='col-11 details'>
                      <p className='title'>Diverse Product Portfolio: </p>
                      <p className='description'>
                        ALOK provides a wide range of UV Masterbatch products tailored for various applications, including automotive, agriculture, fiber, and outdoor furniture.
                      </p>
                    </div>

                  </div>


                  <div className='feature row'>
                    <div className='col-1 bar'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20" fill="none">
                        <path d="M2 20L2 -2.98023e-07" stroke="#171717" strokeWidth="3" />
                      </svg>
                    </div>

                    <div className='col-11 details'>
                      <p className='title'>Custom Formulations: </p>
                      <p className='description'>
                      For environments with aggressive chemical exposures, such as pesticides and sulfur, ALOK offers specially formulated products that are resistant to these chemicals.
                      </p>
                    </div>

                  </div>


                  <div className='feature row'>
                    <div className='col-1 bar'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20" fill="none">
                        <path d="M2 20L2 -2.98023e-07" stroke="#171717" strokeWidth="3" />
                      </svg>
                    </div>

                    <div className='col-11 details'>
                      <p className='title'>Hydrolysis Resistance: </p>
                      <p className='description'>
                      Offers UV Masterbatches specifically designed for water management applications in the building and construction sector, which are resistant to hydrolysis.
                      </p>
                    </div>

                  </div>

                  <div className='feature row'>
                    <div className='col-1 bar'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20" fill="none">
                        <path d="M2 20L2 -2.98023e-07" stroke="#171717" strokeWidth="3" />
                      </svg>
                    </div>

                    <div className='col-11 details'>
                      <p className='title'>Application-Specific Products:  </p>
                      <p className='description'>
                      Includes specialized products like UvNox FGL for food contact applications, UvNox DLR for POM applications in automotive, UvNox ABS for ABS applications, and more.
                      </p>
                    </div>

                  </div>

                  <div className='feature row'>
                    <div className='col-1 bar'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20" fill="none">
                        <path d="M2 20L2 -2.98023e-07" stroke="#171717" strokeWidth="3" />
                      </svg>
                    </div>

                    <div className='col-11 details'>
                      <p className='title'>Pesticide Resistance: </p>
                      <p className='description'>
                      Several products, such as UvNox Mulch and UvNox NOW, are specifically designed to be resistant to pesticides, enhancing their suitability for agricultural applications.
                      </p>
                    </div>

                  </div>

                  <div className='feature row'>
                    <div className='col-1 bar'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20" fill="none">
                        <path d="M2 20L2 -2.98023e-07" stroke="#171717" strokeWidth="3" />
                      </svg>
                    </div>

                    <div className='col-11 details'>
                      <p className='title'>Food Safety Compliance: </p>
                      <p className='description'>
                      Products like UvNox Tank are designed to be safe for contact with food, particularly suitable for outdoor water tanks.
                      </p>
                    </div>

                  </div>


                  <div className='feature row'>
                    <div className='col-1 bar'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20" fill="none">
                        <path d="M2 20L2 -2.98023e-07" stroke="#171717" strokeWidth="3" />
                      </svg>
                    </div>

                    <div className='col-11 details'>
                      <p className='title'>Economical Options: </p>
                      <p className='description'>
                      Offers economically priced variants like UvNox, UvNox 20, and UvNox BT, which are mineral-filled and designed for non-food grade applications.
                      </p>
                    </div>

                  </div>



                </div>

                <p className='desc'>
                *These benefits highlight ALOK's commitment to providing high-quality, specialized solutions for diverse applications in polymer processing and stabilization.
                </p>
              </div>




            </div>
            <ProductContact />
          </div>
          <div className='col-md-5'>
            {/* <ProductsSidebar /> */}
          </div>
        </div>
        {/* <OtherProducts/> */}
      </div>
      <TopFooter />
      <Footer />
      <IndustriesFooter/>
    </>
  )
}

export default Products