import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function ProductsSidebar({ title = "Industries", sequence = "" }) {

  const [industries, setIndustries] = useState([
    {
      title: "Automotive",
      icon: `<svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.7488 30.5602C16.1884 29.1815 20.3102 27.0675 23.9793 24.3607C27.4215 26.9009 30.4653 29.9644 32.9951 33.4371C30.3352 37.0885 28.2431 41.1908 26.8518 45.6104H26.8444C23.783 38.9257 18.4022 33.5549 11.7488 30.5602Z" fill="#D0ADD2"/>
<path d="M39.1396 45.6104H26.8526C28.2439 41.1903 30.336 37.088 32.9964 33.4367C35.6572 37.088 37.7483 41.1903 39.1396 45.6104Z" fill="#C195C4"/>
<path d="M23.9808 24.3604C20.3117 21.6532 16.1899 19.5391 11.7503 18.16C18.4037 15.1653 23.7845 9.79449 26.8459 3.11027H26.8533C28.2446 7.52939 30.3367 11.6326 32.9971 15.2835C30.4673 18.7562 27.423 21.8193 23.9808 24.3604Z" fill="#763C7B"/>
<path d="M26.8526 3.10952L39.1396 3.10952C37.7483 7.52909 35.6572 11.6319 32.9963 15.2832C30.3364 11.6319 28.2439 7.52909 26.8526 3.10952Z" fill="#622466"/>
<path d="M23.9805 24.3582C20.3113 27.0649 16.1895 29.1789 11.75 30.5576L11.7482 30.5563L11.7482 18.1591L11.75 18.1578C16.1895 19.5369 20.3113 21.651 23.9805 24.3582Z" fill="#9D69A6"/>
</svg>
`,
      links: [
        {
          name: 'Driving the future with high-performance polymer compounds and functional Masterbatches for automotive manufacturing.',
          link: "/industries/automotive"
        }
      ]
    },
    {
      title: "Agriculture",
      icon: `<svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.949 31.1447C16.3886 29.766 20.5104 27.6519 24.1795 24.9452C27.6217 27.4853 30.6655 30.5489 33.1953 34.0216C30.5354 37.6729 28.4433 41.7753 27.052 46.1948H27.0446C23.9832 39.5102 18.6024 34.1393 11.949 31.1447Z" fill="#B5B7E6"/>
<path d="M39.3379 46.1948H27.0509C28.4422 41.7748 30.5342 37.6725 33.1946 34.0211C35.8554 37.6725 37.9466 41.7748 39.3379 46.1948Z" fill="#A3A7E0"/>
<path d="M24.1791 24.9448C20.5099 22.2376 16.3881 20.1236 11.9486 18.7445C18.602 15.7498 23.9828 10.379 27.0441 3.69474H27.0516C28.4429 8.11386 30.5349 12.2171 33.1953 15.868C30.6655 19.3407 27.6212 22.4038 24.1791 24.9448Z" fill="#6F73C4"/>
<path d="M27.0509 3.69461H39.3379C37.9466 8.11417 35.8554 12.2169 33.1946 15.8683C30.5346 12.2169 28.4422 8.11417 27.0509 3.69461Z" fill="#595DAA"/>
<path d="M24.1816 24.9416C20.5125 27.6484 16.3907 29.7624 11.9511 31.1411L11.9494 31.1398V18.7426L11.9511 18.7413C16.3907 20.1204 20.5125 22.2345 24.1816 24.9416Z" fill="#8387D3"/>
</svg>
`,
      links: [
        {
          name: 'Innovating with advanced Masterbatches that enhance the performance and durability of agricultural films',
          link: "/industries/agriculture"
        }
      ]
    },
    {
      title: "Packaging",
      icon: `<svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6658 31.2553C16.1054 29.8766 20.2272 27.7625 23.8963 25.0558C27.3385 27.5959 30.3823 30.6595 32.9121 34.1322C30.2522 37.7835 28.1601 41.8859 26.7688 46.3054H26.7614C23.7 39.6208 18.3192 34.2499 11.6658 31.2553Z" fill="#ED98BB"/>
<path d="M39.0547 46.3055H26.7677C28.159 41.8855 30.251 37.7832 32.9114 34.1319C35.5722 37.7832 37.6634 41.8855 39.0547 46.3055Z" fill="#DD7DA5"/>
<path d="M23.8959 25.0554C20.2267 22.3482 16.1049 20.2342 11.6654 18.855C18.3188 15.8604 23.6996 10.4896 26.7609 3.80534H26.7684C28.1597 8.22445 30.2517 12.3277 32.9121 15.9786C30.3823 19.4513 27.338 22.5144 23.8959 25.0554Z" fill="#DB518B"/>
<path d="M26.7677 3.8052H39.0547C37.6634 8.22477 35.5722 12.3275 32.9114 15.9789C30.2514 12.3275 28.159 8.22477 26.7677 3.8052Z" fill="#C41E63"/>
<path d="M23.8984 25.0522C20.2293 27.759 16.1075 29.873 11.6679 31.2517L11.6662 31.2504V18.8532L11.6679 18.8519C16.1075 20.231 20.2293 22.3451 23.8984 25.0522Z" fill="#CA487E"/>
</svg>
`,
      links: [
        {
          name: 'Revolutionizing packaging solutions with high-quality Masterbatches that improve aesthetics and functionality.',
          link: "/industries/packaging-industry"
        }
      ]
    },
    {
      title: "Building & Construction",
      icon: `<svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.8611 18.9369C16.3007 20.3156 20.4225 22.4296 24.0916 25.1363C27.5338 22.5962 30.5776 19.5327 33.1074 16.06C30.4475 12.4086 28.3554 8.30628 26.9641 3.88672H26.9567C23.8953 10.5714 18.5145 15.9422 11.8611 18.9369Z" fill="#FDA060"/>
<path d="M39.252 3.88672L26.9649 3.88672C28.3562 8.30673 30.4483 12.409 33.1087 16.0604C35.7695 12.409 37.8606 8.30673 39.252 3.88672Z" fill="#F38336"/>
<path d="M24.0932 25.1367C20.424 27.8439 16.3022 29.9579 11.8626 31.3371C18.516 34.3317 23.8968 39.7026 26.9582 46.3868H26.9656C28.357 41.9677 30.449 37.8645 33.1094 34.2136C30.5796 30.7408 27.5353 27.6777 24.0932 25.1367Z" fill="#FFC59E"/>
<path d="M26.9649 46.3827H39.252C37.8606 41.9631 35.7695 37.8603 33.1087 34.209C30.4487 37.8603 28.3562 41.9631 26.9649 46.3827Z" fill="#FFB37F"/>
<path d="M24.0938 25.135C20.4246 22.4283 16.3028 20.3142 11.8632 18.9355L11.8615 18.9369V31.3341L11.8632 31.3354C16.3028 29.9562 20.4246 27.8422 24.0938 25.135Z" fill="#F99856"/>
</svg>
`,
      links: [
        {
          name: 'Building stronger and more sustainable structures with specialized Masterbatches for construction applications',
          link: "/industries/building-and-contruction-industry"
        }
      ]
    },
    {
      title: "Consumer Durables",
      icon: `<svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.7498 31.0631C16.1893 29.6844 20.3112 27.5704 23.9803 24.8637C27.4225 27.4038 30.4663 30.4673 32.9961 33.94C30.3361 37.5914 28.2441 41.6937 26.8528 46.1133H26.8453C23.784 39.4286 18.4032 34.0578 11.7498 31.0631Z" fill="#6C9CA1"/>
<path d="M39.1406 46.1133H26.8536C28.2449 41.6933 30.3369 37.591 32.9973 33.9396C35.6582 37.591 37.7493 41.6933 39.1406 46.1133Z" fill="#518B91"/>
<path d="M23.9818 24.8633C20.3127 22.1561 16.1908 20.0421 11.7513 18.6629C18.4047 15.6683 23.7855 10.2974 26.8469 3.6132H26.8543C28.2456 8.03232 30.3377 12.1355 32.998 15.7864C30.4682 19.2592 27.424 22.3223 23.9818 24.8633Z" fill="#2B777F"/>
<path d="M26.8536 3.61734H39.1406C37.7493 8.0369 35.6581 12.1397 32.9973 15.791C30.3374 12.1397 28.2449 8.0369 26.8536 3.61734Z" fill="#115E66"/>
<path d="M23.9824 24.865C20.3133 27.5717 16.1914 29.6858 11.7519 31.0645L11.7502 31.0631V18.6659L11.7519 18.6646C16.1914 20.0438 20.3133 22.1578 23.9824 24.865Z" fill="#226971"/>
</svg>
`,
      links: [
        {
          name: 'Enhancing consumer products with reliable and safe Masterbatches for toys, sports equipment, and household appliances.',
          link: "/industries/consumer-durables-industry"
        }
      ]
    },
    {
      title: "Textiles & Fibre",
      icon: `<svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.949 31.1447C16.3886 29.766 20.5104 27.6519 24.1795 24.9452C27.6217 27.4853 30.6655 30.5489 33.1953 34.0216C30.5354 37.6729 28.4433 41.7753 27.052 46.1948H27.0446C23.9832 39.5102 18.6024 34.1393 11.949 31.1447Z" fill="#D5D5AE"/>
<path d="M39.3379 46.1948H27.0509C28.4422 41.7748 30.5342 37.6725 33.1946 34.0211C35.8554 37.6725 37.9466 41.7748 39.3379 46.1948Z" fill="#C8C88A"/>
<path d="M24.1791 24.9448C20.5099 22.2376 16.3881 20.1236 11.9486 18.7445C18.602 15.7498 23.9828 10.379 27.0441 3.69474H27.0516C28.4429 8.11386 30.5349 12.2171 33.1953 15.868C30.6655 19.3407 27.6212 22.4038 24.1791 24.9448Z" fill="#A0A15E"/>
<path d="M27.0509 3.69461H39.3379C37.9466 8.11417 35.8554 12.2169 33.1946 15.8683C30.5346 12.2169 28.4422 8.11417 27.0509 3.69461Z" fill="#8A8B35"/>
<path d="M24.1816 24.9416C20.5125 27.6484 16.3907 29.7624 11.9511 31.1411L11.9494 31.1398V18.7426L11.9511 18.7413C16.3907 20.1204 20.5125 22.2345 24.1816 24.9416Z" fill="#B6B77C"/>
</svg>
`,
      links: [
        {
          name: 'Pioneering textile innovations with Masterbatches that transform fiber properties for enhanced performance.',
          link: "/industries/textile-and-fibers-industry"
        }
      ]
    },
  ])

  return (
    <div className='products-sidebar '>
      <div className='text-center' style={{ backgroundColor: "#F5F5F3", padding: "20px", paddingBottom: "20px", lineHeight: 1, width: "100%" }}>
        <h3 className='heading mb-0'>{title}</h3>
      </div>

      <div className='industries'>
        {
          industries.map((item, index) => {
            if (sequence != index)
              return (
                <div className='industry' key={index}>
                  <div className='d-flex justify-content-start align-items-center industry-name-container '>
                    <div className=' industry-logo' dangerouslySetInnerHTML={{ __html: item.icon }}>

                    </div>

                    <div className=''>
                      <p className='mb-0 industry-name'>{item.title}</p>
                    </div>
                  </div>

                  <div className='row industry-details'>
                    <div className='col-lg-2'>
                    </div>
                    <div className='col-lg-10 '>

                      {
                        item.links.map((link, linkIndex) => (
                          <div className='row detail w-100'>
                            <div className='col-10'>
                              <p className='mb-0 detail-name'>{link.name}</p>
                            </div>
                            <div className='col-2'>
                              <Link className="tp-btn-sm  details-arrow" to={link.link}>
                                <span>
                                  <svg
                                    width="36"
                                    height="8"
                                    viewBox="0 0 36 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                                      fill="#fff"
                                    />
                                  </svg>
                                  <svg
                                    width="36"
                                    height="8"
                                    viewBox="0 0 36 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                                      fill="#fff"
                                    />
                                  </svg>
                                </span>
                              </Link>
                            </div>
                          </div>
                        ))
                      }


                    </div>
                  </div>
                </div>
              )
          })
        }
      </div>
    </div>
  )
}

export default ProductsSidebar