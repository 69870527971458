import React from 'react'
import Menu from '../../../common/Menu/Menu'
import TopFooter from '../../../common/TopFooter/TopFooter'
import Footer from '../../../common/Footer/Footer'
import HeroSection from './HeroSection'
import Applications from './Applications'
import OtherIndustries from '../OtherIndustries'
import ContactCard from './ContactCard'
import HeroIndustries from '../Agriculture/HeroIndustries'
import IndustriesFooter from '../IndustriesFooter'
import OtherProducts from '../../Products/OurProducts'

function TextileAndFiber() {
  return (
      <>
          <Menu />
      <HeroIndustries imageUrl={'/assets/img/industries/textile.jpg'} title={"Our Industries"} subtext={"Advancing Textiles with Innovative Fiber Solutions"} />
          <Applications/>
          <OtherIndustries/>
          <ContactCard/>
          <TopFooter />
          <Footer />
          {/* <IndustriesFooter/> */}
      </>
  )
}

export default TextileAndFiber