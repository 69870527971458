import React, { useState } from 'react'
import { Link } from 'react-router-dom'



export default function IndustriesFooter() {

    const [industries, setIndustries] = useState([
        {
            title: "Automotive",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 51 50" fill="none">
          <rect x="0.5" width="50" height="50" rx="25" fill="white"/>
          <path d="M19.9398 11.2488C21.3185 15.6884 23.4325 19.8102 26.1393 23.4793C23.5991 26.9215 20.5356 29.9653 17.0629 32.4951C13.4115 29.8352 9.30921 27.7431 4.88965 26.3518V26.3444C11.5743 23.283 16.9451 17.9022 19.9398 11.2488Z" fill="#D0ADD2"/>
          <path d="M4.88965 38.6396V26.3526C9.30966 27.7439 13.412 29.836 17.0633 32.4964C13.412 35.1572 9.30966 37.2483 4.88965 38.6396Z" fill="#C195C4"/>
          <path d="M26.1396 23.4808C28.8468 19.8117 30.9609 15.6899 32.34 11.2503C35.3347 17.9037 40.7055 23.2845 47.3897 26.3459V26.3533C42.9706 27.7446 38.8674 29.8367 35.2165 32.4971C31.7438 29.9673 28.6807 26.923 26.1396 23.4808Z" fill="#763C7B"/>
          <path d="M47.3905 26.3526V38.6396C42.9709 37.2483 38.8681 35.1572 35.2168 32.4963C38.8681 29.8364 42.9709 27.7439 47.3905 26.3526Z" fill="#622466"/>
          <path d="M26.1418 23.4805C23.4351 19.8113 21.3211 15.6895 19.9424 11.25L19.9437 11.2482H32.3409L32.3422 11.25C30.9631 15.6895 28.849 19.8113 26.1418 23.4805Z" fill="#9D69A6"/>
        </svg>`,
            link: "/industries/automotive",
            links: [
                {
                    name: 'Polypropylene Compounds',
                    link: "/"
                },
                {
                    name: 'Mineral Filled Masterbatches',
                    link: "/"
                },
                {
                    name: 'Light Stabilizer Masterbatches',
                    link: "/"
                },
            ]
        },
        {
            title: "Packaging",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 51 50" fill="none">
          <rect x="0.5" width="50" height="50" rx="25" fill="white"/>
          <path d="M39.3342 18.7445C34.8946 20.1232 30.7728 22.2372 27.1037 24.944C23.6615 22.4038 20.6177 19.3403 18.0879 15.8676C20.7478 12.2162 22.8399 8.1139 24.2312 3.69434H24.2386C27.3 10.379 32.6808 15.7498 39.3342 18.7445Z" fill="#ED98BB"/>
          <path d="M11.9453 3.69434L24.2323 3.69434C22.841 8.11434 20.749 12.2167 18.0886 15.868C15.4278 12.2167 13.3366 8.11434 11.9453 3.69434Z" fill="#DD7DA5"/>
          <path d="M27.1041 24.9443C30.7733 27.6515 34.8951 29.7656 39.3346 31.1447C32.6812 34.1394 27.3004 39.5102 24.2391 46.1944H24.2316C22.8403 41.7753 20.7483 37.6721 18.0879 34.0212C20.6177 30.5484 23.662 27.4854 27.1041 24.9443Z" fill="#DB518B"/>
          <path d="M24.2323 46.1952H11.9453C13.3366 41.7756 15.4278 37.6728 18.0886 34.0215C20.7486 37.6728 22.841 41.7756 24.2323 46.1952Z" fill="#C41E63"/>
          <path d="M27.1016 24.9475C30.7707 22.2408 34.8925 20.1267 39.3321 18.748L39.3338 18.7494V31.1466L39.3321 31.1479C34.8925 29.7687 30.7707 27.6547 27.1016 24.9475Z" fill="#CA487E"/>
        </svg>`,
            link: "/industries/packaging-industry",
            links: [
                {
                    name: 'Polypropylene Compounds',
                    link: "/industries/automotive"
                },
                {
                    name: 'Mineral Filled Masterbatches',
                    link: "/industries/automotive"
                },
                {
                    name: 'Light Stabilizer Masterbatches',
                    link: "/industries/automotive"
                },
            ]
        },
        {
            title: "Building & Construction",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 51 50" fill="none">
          <rect x="0.5" width="50" height="50" rx="25" fill="white"/>
          <path d="M19.4369 38.6389C20.8156 34.1993 22.9296 30.0775 25.6363 26.4084C23.0962 22.9662 20.0327 19.9224 16.56 17.3926C12.9086 20.0525 8.80628 22.1446 4.38672 23.5359V23.5433C11.0714 26.6047 16.4422 31.9855 19.4369 38.6389Z" fill="#FDA060"/>
          <path d="M4.38672 11.248V23.5351C8.80673 22.1438 12.909 20.0517 16.5604 17.3913C12.909 14.7305 8.80673 12.6394 4.38672 11.248Z" fill="#F38336"/>
          <path d="M25.6367 26.4068C28.3439 30.076 30.4579 34.1978 31.8371 38.6374C34.8317 31.984 40.2026 26.6032 46.8868 23.5418V23.5344C42.4677 22.143 38.3645 20.051 34.7136 17.3906C31.2408 19.9204 28.1777 22.9647 25.6367 26.4068Z" fill="#FFC59E"/>
          <path d="M46.8827 23.5351V11.248C42.4631 12.6394 38.3603 14.7305 34.709 17.3913C38.3603 20.0513 42.4631 22.1438 46.8827 23.5351Z" fill="#FFB37F"/>
          <path d="M25.635 26.4062C22.9283 30.0754 20.8142 34.1972 19.4355 38.6368L19.4369 38.6385H31.8341L31.8354 38.6368C30.4562 34.1972 28.3422 30.0754 25.635 26.4062Z" fill="#F99856"/>
        </svg>`,
            link: "/industries/automotive",
            links: [
                {
                    name: 'Polypropylene Compounds',
                    link: "/industries/building-and-contruction-industry"
                },
                {
                    name: 'Mineral Filled Masterbatches',
                    link: "/industries/building-and-contruction-industry"
                },
                {
                    name: 'Light Stabilizer Masterbatches',
                    link: "/industries/building-and-contruction-industry"
                },
            ]
        },
        {
            title: "Agriculture",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 51 50" fill="none">
          <rect x="0.5" width="50" height="50" rx="25" fill="white"/>
          <path d="M11.949 31.1452C16.3886 29.7665 20.5104 27.6524 24.1795 24.9457C27.6217 27.4858 30.6655 30.5493 33.1953 34.0221C30.5354 37.6734 28.4433 41.7757 27.052 46.1953H27.0446C23.9832 39.5106 18.6024 34.1398 11.949 31.1452Z" fill="#B5B7E6"/>
          <path d="M39.3379 46.1953H27.0509C28.4422 41.7753 30.5342 37.673 33.1946 34.0216C35.8554 37.673 37.9466 41.7753 39.3379 46.1953Z" fill="#A3A7E0"/>
          <path d="M24.1791 24.9453C20.5099 22.2381 16.3881 20.1241 11.9486 18.7449C18.602 15.7503 23.9828 10.3794 27.0441 3.69523H27.0516C28.4429 8.11435 30.5349 12.2176 33.1953 15.8685C30.6655 19.3412 27.6212 22.4043 24.1791 24.9453Z" fill="#6F73C4"/>
          <path d="M27.0509 3.69546H39.3379C37.9466 8.11503 35.8554 12.2178 33.1946 15.8691C30.5346 12.2178 28.4422 8.11503 27.0509 3.69546Z" fill="#595DAA"/>
          <path d="M24.1816 24.9421C20.5125 27.6489 16.3907 29.7629 11.9511 31.1416L11.9494 31.1403V18.7431L11.9511 18.7418C16.3907 20.1209 20.5125 22.235 24.1816 24.9421Z" fill="#8387D3"/>
        </svg>`,
            link: "/industries/agriculture",
            links: [
                {
                    name: 'Polypropylene Compounds',
                    link: "/industries/agriculture"
                },
                {
                    name: 'Mineral Filled Masterbatches',
                    link: "/industries/agriculture"
                },
                {
                    name: 'Light Stabilizer Masterbatches',
                    link: "/industries/agriculture"
                },
            ]
        },
        {
            title: "Consumer Durables",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 51 50" fill="none">
          <rect x="0.5" width="50" height="50" rx="25" fill="white"/>
          <path d="M19.4369 11.2498C20.8156 15.6893 22.9296 19.8112 25.6363 23.4803C23.0962 26.9225 20.0327 29.9663 16.56 32.4961C12.9086 29.8361 8.80628 27.7441 4.38672 26.3528V26.3453C11.0714 23.284 16.4422 17.9032 19.4369 11.2498Z" fill="#6C9CA1"/>
          <path d="M4.38672 38.6406V26.3536C8.80673 27.7449 12.909 29.8369 16.5604 32.4973C12.909 35.1582 8.80673 37.2493 4.38672 38.6406Z" fill="#518B91"/>
          <path d="M25.6367 23.4818C28.3439 19.8127 30.4579 15.6908 31.8371 11.2513C34.8317 17.9047 40.2026 23.2855 46.8868 26.3469V26.3543C42.4677 27.7456 38.3645 29.8377 34.7136 32.498C31.2408 29.9682 28.1777 26.924 25.6367 23.4818Z" fill="#2B777F"/>
          <path d="M46.8827 26.3536V38.6406C42.4631 37.2493 38.3603 35.1581 34.709 32.4973C38.3603 29.8374 42.4631 27.7449 46.8827 26.3536Z" fill="#115E66"/>
          <path d="M25.635 23.4824C22.9283 19.8133 20.8142 15.6914 19.4355 11.2519L19.4369 11.2502H31.8341L31.8354 11.2519C30.4562 15.6914 28.3422 19.8133 25.635 23.4824Z" fill="#226971"/>
        </svg>`,
            link: "/industries/consumer-durables-industry",
            links: [
                {
                    name: 'Polypropylene Compounds',
                    link: "/industries/consumer-durables-industry"
                },
                {
                    name: 'Mineral Filled Masterbatches',
                    link: "/industries/consumer-durables-industry"
                },
                {
                    name: 'Light Stabilizer Masterbatches',
                    link: "/industries/consumer-durables-industry"
                },
            ]
        },
        {
            title: "Textiles & Fibre",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 51 50" fill="none">
          <rect x="0.5" width="50" height="50" rx="25" fill="white"/>
          <path d="M11.949 31.1452C16.3886 29.7665 20.5104 27.6524 24.1795 24.9457C27.6217 27.4858 30.6655 30.5493 33.1953 34.0221C30.5354 37.6734 28.4433 41.7757 27.052 46.1953H27.0446C23.9832 39.5106 18.6024 34.1398 11.949 31.1452Z" fill="#D5D5AE"/>
          <path d="M39.3379 46.1953H27.0509C28.4422 41.7753 30.5342 37.673 33.1946 34.0216C35.8554 37.673 37.9466 41.7753 39.3379 46.1953Z" fill="#C8C88A"/>
          <path d="M24.1791 24.9453C20.5099 22.2381 16.3881 20.1241 11.9486 18.7449C18.602 15.7503 23.9828 10.3794 27.0441 3.69523H27.0516C28.4429 8.11435 30.5349 12.2176 33.1953 15.8685C30.6655 19.3412 27.6212 22.4043 24.1791 24.9453Z" fill="#A0A15E"/>
          <path d="M27.0509 3.69546H39.3379C37.9466 8.11503 35.8554 12.2178 33.1946 15.8691C30.5346 12.2178 28.4422 8.11503 27.0509 3.69546Z" fill="#8A8B35"/>
          <path d="M24.1816 24.9421C20.5125 27.6489 16.3907 29.7629 11.9511 31.1416L11.9494 31.1403V18.7431L11.9511 18.7418C16.3907 20.1209 20.5125 22.235 24.1816 24.9421Z" fill="#B6B77C"/>
        </svg>`,
            link: "/industries/textile-and-fibers-industry",
            links: [
                {
                    name: 'Polypropylene Compounds',
                    link: "/industries/packaging-industry"
                },
                {
                    name: 'Mineral Filled Masterbatches',
                    link: "/industries/packaging-industry"
                },
                {
                    name: 'Light Stabilizer Masterbatches',
                    link: "/industries/packaging-industry"
                },
            ]
        },
        
    ])

    return (
        <div>
            <div className='industries-footer'>
                <div className="industries-list">

                    {
                        industries.map((item, index) => (
                            <Link to={item.link} className="industry-item col-lg-3">
                                <div className='footer-tooltip'>
                                    <div className='industry-detail'>
                                        {
                                            item.links.map((link, linkIndex) => (
                                                <div className='row detail w-100'>
                                                    <div className='col-10'>
                                                        <p className='mb-0 detail-name'>{link.name}</p>
                                                    </div>
                                                    <div className='col-1'>
                                                        <Link className="tp-btn-sm  details-arrow" to={link.link} style={{ width: "30px", height: '30px' }}>
                                                            <span >
                                                                <svg
                                                                    width="36"
                                                                    height="8"
                                                                    viewBox="0 0 36 8"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                                                                        fill="#fff"
                                                                    />
                                                                </svg>
                                                                <svg
                                                                    width="36"
                                                                    height="8"
                                                                    viewBox="0 0 36 8"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                                                                        fill="#fff"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        <div className='arrow-container'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none">
                                                <path d="M0 0H24L12.5455 14L0 0Z" fill="#622466" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="logo" dangerouslySetInnerHTML={{ __html: item.icon }}>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <rect width="40" height="40" rx="20" fill="#622466" />
                                        <path d="M15.1524 8.99906C16.2554 12.5507 17.9466 15.8481 20.112 18.7835C18.0799 21.5372 15.6291 23.9723 12.8509 25.9961C9.92981 23.8681 6.64796 22.1945 3.1123 21.0815V21.0755C8.46004 18.6264 12.7567 14.3218 15.1524 8.99906Z" fill="white" />
                                        <path d="M3.1123 30.9111V21.0815C6.64831 22.1946 9.93017 23.8682 12.8512 25.9965C9.93017 28.1252 6.64831 29.7981 3.1123 30.9111Z" fill="white" />
                                        <path d="M20.1133 18.7851C22.279 15.8498 23.9703 12.5523 25.0736 9.00067C27.4693 14.3234 31.766 18.628 37.1133 21.0771V21.0831C33.578 22.1961 30.2955 23.8697 27.3748 25.998C24.5966 23.9742 22.1461 21.5388 20.1133 18.7851Z" fill="white" />
                                        <path d="M37.1139 21.0815V30.9111C33.5783 29.7981 30.2961 28.1252 27.375 25.9965C30.2961 23.8685 33.5783 22.1946 37.1139 21.0815Z" fill="white" />
                                        <path d="M20.1148 18.7842C17.9494 15.8489 16.2582 12.5514 15.1553 8.99977L15.1563 8.99837H25.0741L25.0751 8.99977C23.9718 12.5514 22.2806 15.8489 20.1148 18.7842Z" fill="white" />
                                    </svg> */}
                                </div>

                                <p className="industry-name">
                                    {item.title}
                                </p>
                            </Link>
                        ))
                    }

                </div>
            </div>
            <div className='footer-bottom-spacing'></div>
        </div>
    )
}