import React, { useState } from "react";
import { Link } from "react-router-dom";
import useSticky from "../../../hooks/use-sticky";
import Menus from "./Menus";
import OffCanvas from "./OffCanvas";

const Menu = ({ header_style_2 }) => {
  const [isToggleSearch, setToggleSearch] = useState(false);

  const [isOffCanvas, setOffCanvas] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showIndustries, setShowIndustries] = useState(false);

  const { sticky } = useSticky();
  return (
    <>
      <header>
        <div
          id="header-sticky"
          className={`header__area ${header_style_2
            ? "header__area-2 pl-110 pr-110"
            : "header__transparent pr-90 pl-90"
            } header-sticky-active ${sticky ? "header-sticky" : ""}`}
        >
          <div className="header__main" id="header-sticky">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-2 col-md-4 col-7">
                  {/* {
                    !isOffCanvas && */}
                  <div className="logo">
                    <Link to="/">
                      {/* <img
                        src={
                          header_style_2
                            ? "/assets/img/logo/logo-black.png"
                            : "/assets/img/logo/logo.png"
                        }
                        alt="logo"
                      /> */}

                      <svg xmlns="http://www.w3.org/2000/svg" width="164" height="90" viewBox="0 0 164 90" fill="none" className="d-none d-lg-block">
                        <g clipPath="url(#clip0_100_10060)">
                          <path d="M139.5 34.3939C136.359 30.0767 133.906 25.2294 132.307 20.0059C128.831 27.833 122.6 34.1629 114.843 37.7651V37.7739C119.971 39.4108 124.732 41.871 128.968 45.0014C132.997 42.0251 136.552 38.4441 139.5 34.3939Z" fill="#F38336" />
                          <path d="M146.694 20.0059C145.093 25.2289 142.64 30.0767 139.499 34.3939C142.447 38.4441 146.002 42.0251 150.031 45.0014C154.268 41.871 159.029 39.4108 164.156 37.7734V37.7651C156.4 34.1629 150.168 27.8325 146.694 20.0059Z" fill="#C41E63" />
                          <path d="M139.5 34.3927C142.641 30.0754 145.094 25.2282 146.695 20.0046L146.693 20H132.308L132.307 20.0036C133.906 25.2282 136.359 30.0754 139.5 34.3927Z" fill="#E92644" />
                          <path d="M132.305 20.006L132.303 20H132.307L132.305 20.006Z" fill="#9A9D9F" />
                          <path d="M146.693 20.0042L146.691 20H146.694L146.693 20.0042Z" fill="#9A9D9F" />
                          <path d="M132.306 69.998C133.906 64.775 136.359 59.9257 139.5 55.6089C136.552 51.5593 132.997 47.9782 128.968 45.002C124.731 48.1314 119.971 50.5926 114.843 52.2295V52.2383C122.599 55.8399 128.831 62.1704 132.306 69.998Z" fill="#1A9949" />
                          <path d="M114.843 37.7734V52.229C119.971 50.5921 124.732 48.1309 128.968 45.001C124.732 41.8705 119.971 39.4103 114.843 37.7734Z" fill="#8A8B35" />
                          <path d="M139.499 55.6075C142.64 59.9242 145.093 64.7735 146.694 69.9966C150.168 62.169 156.4 55.8385 164.156 52.2368V52.2281C159.029 50.5912 154.268 48.1299 150.031 45C146.002 47.9763 142.447 51.5578 139.499 55.6075Z" fill="#595DAA" />
                          <path d="M164.156 52.229V37.7734C159.028 39.4103 154.267 41.8705 150.03 45.001C154.267 48.1304 159.028 50.5921 164.156 52.229Z" fill="#622466" />
                          <path d="M139.5 55.6094C136.359 59.9261 133.906 64.7754 132.307 69.9985L132.308 70.0005H146.693L146.695 69.9985C145.094 64.7754 142.641 59.9261 139.5 55.6094Z" fill="#115E66" />
                          <path d="M11.3599 32.791L0.144531 57.4731H5.97802L8.4702 52.2232H19.8392L22.3314 57.4731H28.2932L17.2501 32.791H11.3599ZM17.513 46.4145H11.1387L14.38 38.9596L17.513 46.4145Z" fill="white" />
                          <path d="M38.0726 32.6602H31.9648V57.3087H50.2345V51.932H38.0726V32.6602Z" fill="white" />
                          <path d="M74.7282 35.9602C73.5811 34.8136 72.2154 33.8949 70.6693 33.2298C69.119 32.5642 67.4491 32.2266 65.7061 32.2266C63.9398 32.2266 62.2637 32.5642 60.7243 33.2309C59.189 33.8954 57.8294 34.8136 56.6828 35.9602C55.5363 37.1068 54.617 38.473 53.953 40.0186C53.2869 41.5684 52.9492 43.2496 52.9492 45.0159C52.9492 46.7832 53.2869 48.4593 53.9535 49.9977C54.6175 51.5325 55.5357 52.892 56.6828 54.0386C57.8299 55.1862 59.1895 56.1044 60.7243 56.7684C62.2622 57.4345 63.9382 57.7722 65.7061 57.7722C67.4502 57.7722 69.12 57.435 70.6693 56.769C72.2154 56.1049 73.5806 55.1862 74.7282 54.0386C75.8748 52.8925 76.7982 51.5335 77.4736 49.9997C78.1515 48.4587 78.4954 46.7816 78.4954 45.0159C78.4954 43.2501 78.1515 41.5689 77.4736 40.016C76.7987 38.4714 75.8748 37.1068 74.7282 35.9602ZM72.3876 45.0159C72.3876 45.9397 72.2113 46.8188 71.8633 47.6277C71.5127 48.4412 71.0286 49.1609 70.4228 49.7662C69.814 50.3745 69.0979 50.8664 68.2936 51.2262C67.4992 51.5835 66.6284 51.7645 65.7061 51.7645C64.7822 51.7645 63.9052 51.583 63.0979 51.2247C62.2838 50.8648 61.5631 50.3745 60.9552 49.7662C60.3479 49.1594 59.8571 48.4386 59.4962 47.623C59.1384 46.8167 58.9575 45.9392 58.9575 45.0159C58.9575 44.093 59.1384 43.2156 59.4967 42.4087C59.8576 41.5926 60.3495 40.8652 60.9583 40.246C61.5641 39.6299 62.2838 39.1344 63.0984 38.7736C63.9063 38.4158 64.7837 38.2343 65.7061 38.2343C66.6268 38.2343 67.4976 38.4153 68.2941 38.7725C69.0979 39.1329 69.813 39.6289 70.4192 40.246C71.0266 40.8636 71.5122 41.5895 71.8628 42.4036C72.2113 43.214 72.3876 44.0925 72.3876 45.0159Z" fill="white" />
                          <path d="M92.9297 44.8473L105.918 32.7246H97.9852L89.4069 40.8111V32.7246H83.0996V57.4732H89.4069V49.3862L97.9852 57.4732H106.628L92.9297 44.8473Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_100_10060">
                            <rect width="164" height="90" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      <svg xmlns="http://www.w3.org/2000/svg" width="118" height="54" viewBox="0 0 118 54" fill="none" className="d-block d-lg-none">
                        <g clipPath="url(#clip0_100_16394)">
                          <path d="M100.374 19.0039C98.1143 15.8955 96.3493 12.4055 95.1983 8.64453C92.6977 14.2801 88.2141 18.8376 82.6328 21.4312V21.4375C86.323 22.6161 89.7479 24.3874 92.7964 26.6413C95.6953 24.4984 98.253 21.9201 100.374 19.0039Z" fill="#F38336" />
                          <path d="M105.548 8.64453C104.396 12.4051 102.631 15.8955 100.371 19.0039C102.493 21.9201 105.05 24.4984 107.949 26.6413C110.997 24.3874 114.423 22.6161 118.112 21.4371V21.4312C112.532 18.8376 108.048 14.2797 105.548 8.64453Z" fill="#C41E63" />
                          <path d="M100.373 19.0034C102.633 15.8949 104.398 12.4049 105.55 8.64397L105.549 8.64062H95.1984L95.1973 8.64322C96.3483 12.4049 98.1133 15.8949 100.373 19.0034Z" fill="#E92644" />
                          <path d="M95.1973 8.64555L95.1953 8.64062H95.1988L95.1973 8.64555Z" fill="#9A9D9F" />
                          <path d="M105.548 8.64366L105.547 8.64062H105.549L105.548 8.64366Z" fill="#9A9D9F" />
                          <path d="M95.1979 44.6378C96.3489 40.8772 98.1139 37.3857 100.374 34.2777C98.253 31.3619 95.6953 28.7836 92.796 26.6406C89.7476 28.8938 86.3226 30.6659 82.6328 31.8445V31.8508C88.2137 34.444 92.6977 39.0019 95.1979 44.6378Z" fill="#1A9949" />
                          <path d="M82.6328 21.4375V31.8455C86.323 30.667 89.7479 28.8949 92.7964 26.6413C89.7479 24.3874 86.323 22.6161 82.6328 21.4375Z" fill="#8A8B35" />
                          <path d="M100.371 34.278C102.631 37.3861 104.396 40.8776 105.548 44.6382C108.048 39.0023 112.532 34.4443 118.112 31.8511V31.8448C114.423 30.6663 110.997 28.8942 107.949 26.6406C105.05 28.7836 102.493 31.3623 100.371 34.278Z" fill="#595DAA" />
                          <path d="M118.113 31.8455V21.4375C114.423 22.6161 110.998 24.3874 107.949 26.6413C110.998 28.8945 114.423 30.667 118.113 31.8455Z" fill="#622466" />
                          <path d="M100.373 34.2793C98.1133 37.3873 96.3483 40.8788 95.1973 44.6394L95.1984 44.6409H105.549L105.55 44.6394C104.398 40.8788 102.633 37.3873 100.373 34.2793Z" fill="#115E66" />
                          <path d="M8.17504 17.8496L0.105469 35.6207H4.30273L6.09589 31.8408H14.276L16.0692 35.6207H20.3588L12.4131 17.8496H8.17504ZM12.6023 27.6585H8.01591L10.348 22.291L12.6023 27.6585Z" fill="white" />
                          <path d="M27.3966 17.7559H23.002V35.5028H36.1472V31.6316H27.3966V17.7559Z" fill="white" />
                          <path d="M53.7699 20.1316C52.9445 19.306 51.9619 18.6446 50.8494 18.1657C49.734 17.6865 48.5325 17.4434 47.2783 17.4434C46.0074 17.4434 44.8015 17.6865 43.6939 18.1665C42.5892 18.6449 41.611 19.306 40.786 20.1316C39.961 20.9571 39.2996 21.9408 38.8218 23.0536C38.3426 24.1695 38.0996 25.3799 38.0996 26.6517C38.0996 27.9241 38.3426 29.1309 38.8222 30.2386C39.3 31.3436 39.9606 32.3225 40.786 33.148C41.6114 33.9743 42.5895 34.6354 43.6939 35.1135C44.8004 35.5931 46.0063 35.8362 47.2783 35.8362C48.5332 35.8362 49.7347 35.5935 50.8494 35.1139C51.9619 34.6358 52.9441 33.9743 53.7699 33.148C54.5949 32.3228 55.2592 31.3444 55.7452 30.24C56.233 29.1305 56.4804 27.923 56.4804 26.6517C56.4804 25.3803 56.233 24.1698 55.7452 23.0518C55.2596 21.9397 54.5949 20.9571 53.7699 20.1316ZM52.0858 26.6517C52.0858 27.3169 51.9589 27.9497 51.7085 28.5322C51.4563 29.1179 51.108 29.6361 50.6721 30.0719C50.234 30.5099 49.7188 30.864 49.1401 31.1231C48.5685 31.3804 47.9419 31.5107 47.2783 31.5107C46.6136 31.5107 45.9826 31.38 45.4017 31.122C44.816 30.8629 44.2974 30.5099 43.86 30.0719C43.4231 29.635 43.0699 29.1161 42.8103 28.5288C42.5528 27.9483 42.4226 27.3165 42.4226 26.6517C42.4226 25.9872 42.5528 25.3554 42.8106 24.7745C43.0703 24.1869 43.4242 23.6632 43.8623 23.2173C44.2981 22.7738 44.816 22.417 45.4021 22.1572C45.9833 21.8996 46.6147 21.7689 47.2783 21.7689C47.9408 21.7689 48.5673 21.8992 49.1405 22.1565C49.7188 22.4159 50.2333 22.773 50.6695 23.2173C51.1065 23.662 51.4559 24.1847 51.7081 24.7708C51.9589 25.3543 52.0858 25.9868 52.0858 26.6517Z" fill="white" />
                          <path d="M66.8658 26.5311L76.2114 17.8027H70.5033L64.3311 23.625V17.8027H59.793V35.6217H64.3311V29.7991L70.5033 35.6217H76.7219L66.8658 26.5311Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_100_16394">
                            <rect width="118" height="54" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </div>
{/* } */}
                </div>
                <div className=" col-lg-9 col-md-6 d-none d-md-block">
                  <div
                    className={`main-menu ${header_style_2 ? "normal-menu" : ""
                      } text-center`}
                  >
                    <nav id="mobile-menu" className="d-none d-xl-block">
                      {
                        !isOffCanvas &&
                      <Menus setOffCanvas={setOffCanvas} showIndustries={showIndustries} setShowIndustries={setShowIndustries} showProducts={showProducts} setShowProducts={setShowProducts} />
                      }
                    </nav>
                  </div>
                </div>
                <div className="col-lg-1 col-md-2 col-5">
                  <div className="header__main_right d-flex justify-content-end align-items-center">
                    <div className="tp-header-search-icons normal-search mr-80 p-relative ">
                      <div
                        className={`search-btn-wrap ${isToggleSearch ? "header_search-open" : ""
                          }`}
                      >
                        <button
                          onClick={() => setToggleSearch(!isToggleSearch)}
                          className="button-search-toggle position-relative"
                        >


                          <i
                            className={`header_search-button ${header_style_2 ? "" : "text-white"
                              } far fa-search`}
                          ></i>
                          <i
                            className={`header_search-close ${header_style_2 ? "" : "text-white"
                              } far fa-times`}
                          ></i>
                        </button>
                        <div className="tp-search-form p-relative">
                          <form action="#">
                            <input type="text" placeholder="Search ..." />
                            <button
                              type="submit"
                              className="search-submit-icon"
                            >
                              <i className="far fa-search"></i>
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tp-bar-icon ${header_style_2 ? "tp-bar-icon-dark" : ""
                        }`}
                    >
                      <button
                        onClick={() => setOffCanvas(true)}
                        className="offcanvas-toggle-btn"
                      >
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="20" cy="20" r="20" fill="white" />
                          <path d="M14 14H26" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M14 26H26" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M17 20H22" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        {/* <i className="fal fa-bars" /> */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* off canvas start */}
      <OffCanvas isOffCanvas={isOffCanvas} setOffCanvas={setOffCanvas} showIndustries={showIndustries} setShowIndustries={setShowIndustries} showProducts={showProducts} setShowProducts={setShowProducts} />
      {/* off canvas end */}
    </>
  );
};

export default Menu;
