// import React, { useRef, useState } from 'react'
// import Slider from 'react-slick';



// export default function OurMission() {

//     const sliderRef = useRef(null);
//     const [missions, setMissions] = useState([
//         {
//             image: "/assets/img/about/about-bg1.png"
//         },
//         {
//             image: "/assets/img/about/about-bg1.png"
//         },
//         {
//             image: "/assets/img/about/about-bg1.png"
//         },
//         {
//             image: "/assets/img/about/about-bg1.png"
//         },
//         {
//             image: "/assets/img/about/about-bg1.png"
//         },
//         {
//             image: "/assets/img/about/about-bg1.png"
//         },
//     ]);


//     const settings = {
//         autoplay: true,
//         autoplaySpeed: 7000,
//         lazyLoad: true,
//         speed: 500,
//         dots: false,
//         // fade: true,
//         arrows: false,
//         pauseOnHover: false, // Pause carousel on hover
//         afterChange: (current) => {
//             setSlideIndex(current)
//             // setActiveSlide(current)
//         },
//         beforeChange: (prev, next) => {
//         },
//         responsive: [
//             {
//                 breakpoint: 992,
//                 settings: {
//                     arrows: false,
//                 },
//             },
//         ],
//     };

//     const [activeSlide, setActiveSlide] = useState(0);
//     const [slideIndex, setSlideIndex] = useState(0);

//     return (
//         <>

//             <div className='our-mission-section w-100' >
//                 <div className='container mx-auto'>

//                     <div className='section-1'>
//                         <div className='title-tab-container'>

//                             <div className='mission-title-container'>
//                                 <p className='mb-0 subtitle'>Our Mission & Vision</p>
//                                 <p className='mb-0 title'>Combining the Power of Chemistry with Technology</p>
//                             </div>

//                             <div className='row g-5'>

//                                 <div className='col-lg-6'>
//                                     <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
//                                         <div className='mission-card'>
//                                             {/* <div className='icon'>
//                                                 <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                     <circle cx="30.5" cy="30" r="30" fill="white" fill-opacity="0.12" />
//                                                     <path d="M20.0009 34.6682C23.3436 33.6301 26.4471 32.0384 29.2097 30.0004C31.8015 31.9129 34.0933 34.2196 35.998 36.8343C33.9953 39.5836 32.4201 42.6723 31.3725 46H31.3669C29.0619 40.9669 25.0105 36.923 20.0009 34.6682Z" fill="#B5B7E6" />
//                                                     <path d="M40.623 46H31.3717C32.4193 42.672 33.9944 39.5832 35.9975 36.834C38.001 39.5832 39.5755 42.672 40.623 46Z" fill="#A3A7E0" />
//                                                     <path d="M29.2094 30C26.4468 27.9617 23.3433 26.3699 20.0006 25.3315C25.0102 23.0767 29.0616 19.0328 31.3666 14H31.3722C32.4198 17.3273 33.9949 20.4168 35.998 23.1657C34.0933 25.7805 31.8011 28.0868 29.2094 30Z" fill="#6F73C4" />
//                                                     <path d="M31.3717 14H40.623C39.5755 17.3277 38.001 20.4168 35.9975 23.166C33.9948 20.4168 32.4193 17.3277 31.3717 14Z" fill="#595DAA" />
//                                                     <path d="M29.2109 29.9982C26.4483 32.0362 23.3448 33.6279 20.0021 34.666L20.0008 34.665V25.3307L20.0021 25.3297C23.3448 26.3681 26.4483 27.9599 29.2109 29.9982Z" fill="#8387D3" />
//                                                 </svg>
//                                             </div> */}
//                                             <div className='mission-details' style={{gap : "0px"}}>
//                                                 <h2 className='mb-0 name'>Our Mission</h2>
//                                                 <p className='mb-0 details'>With our extensive industry experience and commitment to excellence, we ensure each client receives personalized solutions that propel their products to the forefront of the market. Let ALOK be your gateway to mastering the art of Masterbatches, where tradition meets innovation for your success.</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className='col-lg-6'>
//                                     <img src='/assets/img/about/about-bg1.png' className='w-100 h-100 image' />
//                                 </div>

//                             </div>


//                             <div className='row g-5'>

//                                 <div className='col-lg-6'>
//                                     <img src='/assets/img/about/geomembrane.jpg' className='w-100 h-100 image' />
//                                 </div>

//                                 <div className='col-lg-6'>
//                                     <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
//                                         <div className='mission-card'>

//                                             <div className='mission-details' style={{ gap: "0px" }}>
//                                                 <h2 className='mb-0 name' >Our Vision</h2>
//                                                 <p className='mb-0 details'>To be the global leader in the Masterbatches industry, innovating sustainable and high-quality solutions that advance the polymer sector worldwide.</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                             </div>
//                             <div className='row g-5'>

//                                 <div className='col-lg-6'>
//                                     <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
//                                         <div className='mission-card'>
//                                             {/* <div className='icon'>
//                                                 <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                     <circle cx="30.5" cy="30" r="30" fill="white" fill-opacity="0.12" />
//                                                     <path d="M20.0009 34.6682C23.3436 33.6301 26.4471 32.0384 29.2097 30.0004C31.8015 31.9129 34.0933 34.2196 35.998 36.8343C33.9953 39.5836 32.4201 42.6723 31.3725 46H31.3669C29.0619 40.9669 25.0105 36.923 20.0009 34.6682Z" fill="#B5B7E6" />
//                                                     <path d="M40.623 46H31.3717C32.4193 42.672 33.9944 39.5832 35.9975 36.834C38.001 39.5832 39.5755 42.672 40.623 46Z" fill="#A3A7E0" />
//                                                     <path d="M29.2094 30C26.4468 27.9617 23.3433 26.3699 20.0006 25.3315C25.0102 23.0767 29.0616 19.0328 31.3666 14H31.3722C32.4198 17.3273 33.9949 20.4168 35.998 23.1657C34.0933 25.7805 31.8011 28.0868 29.2094 30Z" fill="#6F73C4" />
//                                                     <path d="M31.3717 14H40.623C39.5755 17.3277 38.001 20.4168 35.9975 23.166C33.9948 20.4168 32.4193 17.3277 31.3717 14Z" fill="#595DAA" />
//                                                     <path d="M29.2109 29.9982C26.4483 32.0362 23.3448 33.6279 20.0021 34.666L20.0008 34.665V25.3307L20.0021 25.3297C23.3448 26.3681 26.4483 27.9599 29.2109 29.9982Z" fill="#8387D3" />
//                                                 </svg>
//                                             </div> */}
//                                             <div className='mission-details' style={{ gap: "0px" }}>
//                                                 <h2 className='mb-0 name'>Our Values</h2>
//                                                 <p className='mb-0 details'>With our extensive industry experience and commitment to excellence, we ensure each client receives personalized solutions that propel their products to the forefront of the market. Let ALOK be your gateway to mastering the art of Masterbatches, where tradition meets innovation for your success.</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className='col-lg-6'>
//                                     <img src='/assets/img/about/about-bg1.png' className='w-100 h-100 image' />
//                                 </div>

//                             </div>

//                             {/* <div className='divider'></div>
//                             <div className='tab-container'>
//                                 <div className='tab tab-active'>
//                                     <p className='mb-0 tab-title'>We are Passionate</p>
//                                 </div>

//                                 <div className='tab'>
//                                     <p className='mb-0 tab-title'>We are Curious</p>
//                                 </div>

//                                 <div className='tab'>
//                                     <p className='mb-0 tab-title'>We are Honest</p>
//                                 </div>

//                                 <div className='tab'>
//                                     <p className='mb-0 tab-title'>We value Relationships</p>
//                                 </div>
//                             </div> */}

//                         </div>

//                         <div className='values-container'>
//                             <div className='values-title-container'>
//                                 <p className='mb-0 subtitle'>Our Pillars Of Success</p>
//                                 <p className='mb-0 title'>Creating a more equitable world for everyone</p>
//                             </div>

//                             <div className='row values'>
//                                 <div className='col-lg-3'>
//                                     <div className='value'>
//                                         <div className='icon'>
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
//                                                 <g clipPath="url(#clip0_556_877)">
//                                                     <path d="M23.77 1.2207L12.4066 15.4249L8.00586 11.0243" stroke="#A0A15E" strokeWidth="2" />
//                                                     <path d="M16.9109 4.49454C15.4338 3.64184 13.7461 3.18164 12.0109 3.18164C9.41184 3.18164 6.91924 4.21414 5.08134 6.05204C3.24344 7.88984 2.21094 10.3824 2.21094 12.9816C2.21094 15.5808 3.24344 18.0734 5.08134 19.9113C6.91924 21.7491 9.41184 22.7816 12.0109 22.7816C14.6101 22.7816 17.1027 21.7491 18.9406 19.9113C20.7784 18.0734 21.8109 15.5808 21.8109 12.9816C21.8109 11.975 21.6561 10.9844 21.3596 10.0416" stroke="#A0A15E" strokeWidth="2" />
//                                                 </g>
//                                                 <defs>
//                                                     <clipPath id="clip0_556_877">
//                                                         <rect width="24" height="24" fill="white" transform="translate(0.5)" />
//                                                     </clipPath>
//                                                 </defs>
//                                             </svg>
//                                         </div>

//                                         <div className='value-details'>
//                                             <p className='mb-0 name'>Curiosity:</p>
//                                             <p className='mb-0 desc'>
//                                                 We perpetually challenge the status quo to enhance our solutions.
//                                             </p>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className='col-lg-3'>
//                                     <div className='value'>
//                                         <div className='icon'>
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
//                                                 <g clipPath="url(#clip0_556_877)">
//                                                     <path d="M23.77 1.2207L12.4066 15.4249L8.00586 11.0243" stroke="#A0A15E" strokeWidth="2" />
//                                                     <path d="M16.9109 4.49454C15.4338 3.64184 13.7461 3.18164 12.0109 3.18164C9.41184 3.18164 6.91924 4.21414 5.08134 6.05204C3.24344 7.88984 2.21094 10.3824 2.21094 12.9816C2.21094 15.5808 3.24344 18.0734 5.08134 19.9113C6.91924 21.7491 9.41184 22.7816 12.0109 22.7816C14.6101 22.7816 17.1027 21.7491 18.9406 19.9113C20.7784 18.0734 21.8109 15.5808 21.8109 12.9816C21.8109 11.975 21.6561 10.9844 21.3596 10.0416" stroke="#A0A15E" strokeWidth="2" />
//                                                 </g>
//                                                 <defs>
//                                                     <clipPath id="clip0_556_877">
//                                                         <rect width="24" height="24" fill="white" transform="translate(0.5)" />
//                                                     </clipPath>
//                                                 </defs>
//                                             </svg>
//                                         </div>

//                                         <div className='value-details'>
//                                             <p className='mb-0 name'>Honesty:</p>
//                                             <p className='mb-0 desc'>
//                                                 Committed to integrity, we deliver on our promises and strive to exceed expectations.
//                                             </p>
//                                         </div>
//                                     </div>
//                                 </div>


//                                 <div className='col-lg-3'>
//                                     <div className='value'>
//                                         <div className='icon'>
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
//                                                 <g clipPath="url(#clip0_556_877)">
//                                                     <path d="M23.77 1.2207L12.4066 15.4249L8.00586 11.0243" stroke="#A0A15E" strokeWidth="2" />
//                                                     <path d="M16.9109 4.49454C15.4338 3.64184 13.7461 3.18164 12.0109 3.18164C9.41184 3.18164 6.91924 4.21414 5.08134 6.05204C3.24344 7.88984 2.21094 10.3824 2.21094 12.9816C2.21094 15.5808 3.24344 18.0734 5.08134 19.9113C6.91924 21.7491 9.41184 22.7816 12.0109 22.7816C14.6101 22.7816 17.1027 21.7491 18.9406 19.9113C20.7784 18.0734 21.8109 15.5808 21.8109 12.9816C21.8109 11.975 21.6561 10.9844 21.3596 10.0416" stroke="#A0A15E" strokeWidth="2" />
//                                                 </g>
//                                                 <defs>
//                                                     <clipPath id="clip0_556_877">
//                                                         <rect width="24" height="24" fill="white" transform="translate(0.5)" />
//                                                     </clipPath>
//                                                 </defs>
//                                             </svg>
//                                         </div>

//                                         <div className='value-details'>
//                                             <p className='mb-0 name'>Relationship:</p>
//                                             <p className='mb-0 desc'>
//                                                 We cultivate strong partnerships with our stakeholders, believing they are essential to mutual success.
//                                             </p>
//                                         </div>
//                                     </div>
//                                 </div>


//                                 <div className='col-lg-3'>
//                                     <div className='value'>
//                                         <div className='icon'>
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
//                                                 <g clipPath="url(#clip0_556_877)">
//                                                     <path d="M23.77 1.2207L12.4066 15.4249L8.00586 11.0243" stroke="#A0A15E" strokeWidth="2" />
//                                                     <path d="M16.9109 4.49454C15.4338 3.64184 13.7461 3.18164 12.0109 3.18164C9.41184 3.18164 6.91924 4.21414 5.08134 6.05204C3.24344 7.88984 2.21094 10.3824 2.21094 12.9816C2.21094 15.5808 3.24344 18.0734 5.08134 19.9113C6.91924 21.7491 9.41184 22.7816 12.0109 22.7816C14.6101 22.7816 17.1027 21.7491 18.9406 19.9113C20.7784 18.0734 21.8109 15.5808 21.8109 12.9816C21.8109 11.975 21.6561 10.9844 21.3596 10.0416" stroke="#A0A15E" strokeWidth="2" />
//                                                 </g>
//                                                 <defs>
//                                                     <clipPath id="clip0_556_877">
//                                                         <rect width="24" height="24" fill="white" transform="translate(0.5)" />
//                                                     </clipPath>
//                                                 </defs>
//                                             </svg>
//                                         </div>

//                                         <div className='value-details'>
//                                             <p className='mb-0 name'>Passion:</p>
//                                             <p className='mb-0 desc'>
//                                                 Our drive to innovate makes the world a better place through advanced chemistry.
//                                             </p>
//                                         </div>
//                                     </div>
//                                 </div>


//                             </div>
//                         </div>
//                         {/* <div className='row tab-content-container g-5'>
//                             <div className='col-lg-6'>
//                                 <div className='mission-details'>
//                                     <h3 className='mb-0 details-title'>
//                                         We believe that chemistry can make the world a better place
//                                     </h3>

//                                     <div className='missions'>
//                                         <div className='mission'>
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
//                                                 <path d="M6.00093 20.6682C9.34361 19.6301 12.4471 18.0384 15.2097 16.0004C17.8015 17.9129 20.0933 20.2196 21.998 22.8343C19.9953 25.5836 18.4201 28.6723 17.3725 32H17.3669C15.0619 26.9669 11.0105 22.923 6.00093 20.6682Z" fill="#B5B7E6" />
//                                                 <path d="M26.623 32H17.3717C18.4193 28.672 19.9944 25.5832 21.9975 22.834C24.001 25.5832 25.5755 28.672 26.623 32Z" fill="#A3A7E0" />
//                                                 <path d="M15.2094 16C12.4468 13.9617 9.34328 12.3699 6.00059 11.3315C11.0102 9.07672 15.0616 5.03282 17.3666 1.90735e-05H17.3722C18.4198 3.32734 19.9949 6.41681 21.998 9.16571C20.0933 11.7805 17.8011 14.0868 15.2094 16Z" fill="#6F73C4" />
//                                                 <path d="M17.3717 -1.90735e-06H26.623C25.5755 3.32765 24.001 6.41678 21.9975 9.16602C19.9948 6.41678 18.4193 3.32765 17.3717 -1.90735e-06Z" fill="#595DAA" />
//                                                 <path d="M15.2109 15.9982C12.4483 18.0362 9.34482 19.6279 6.00213 20.666L6.00081 20.665V11.3307L6.00213 11.3297C9.34482 12.3681 12.4483 13.9599 15.2109 15.9982Z" fill="#8387D3" />
//                                             </svg>

//                                             <p className='mb-0 mission-name'>We do it right the first time and we deliver more than we promise</p>
//                                         </div>

//                                         <div className='mission'>
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
//                                                 <g clipPath="url(#clip0_424_2469)">
//                                                     <path d="M26.622 11.072C23.2794 12.1101 20.1759 13.7018 17.4133 15.7398C14.8216 13.8272 12.5298 11.5206 10.625 8.90586C12.6278 6.15664 14.2029 3.06787 15.2505 -0.259766H15.2561C17.5611 4.77334 21.6125 8.81721 26.622 11.072Z" fill="#ED98BB" />
//                                                     <path d="M6 -0.259766L15.2513 -0.259766C14.2037 3.0682 12.6286 6.15698 10.6255 8.9062C8.62206 6.15698 7.04757 3.0682 6 -0.259766Z" fill="#DD7DA5" />
//                                                     <path d="M17.4136 15.7402C20.1762 17.7786 23.2797 19.3703 26.6224 20.4087C21.6128 22.6635 17.5614 26.7074 15.2564 31.7401H15.2508C14.2033 28.4128 12.6281 25.3234 10.625 22.5745C12.5298 19.9597 14.8219 17.6534 17.4136 15.7402Z" fill="#DB518B" />
//                                                     <path d="M15.2513 31.7402H6C7.04757 28.4125 8.62207 25.3234 10.6255 22.5742C12.6283 25.3234 14.2037 28.4125 15.2513 31.7402Z" fill="#C41E63" />
//                                                     <path d="M17.4121 15.742C20.1747 13.704 23.2782 12.1123 26.6209 11.0742L26.6222 11.0752V20.4095L26.6209 20.4105C23.2782 19.3721 20.1747 17.7803 17.4121 15.742Z" fill="#CA487E" />
//                                                 </g>
//                                                 <defs>
//                                                     <clipPath id="clip0_424_2469">
//                                                         <rect width="32" height="32" fill="white" />
//                                                     </clipPath>
//                                                 </defs>
//                                             </svg>

//                                             <p className='mb-0 mission-name'>We ensure that our products and solutions have zero defect</p>
//                                         </div>

//                                         <div className='mission'>
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
//                                                 <g clipPath="url(#clip0_424_2478)">
//                                                     <path d="M6.37788 20.8537C9.72056 19.8157 12.824 18.2239 15.5867 16.1859C18.1784 18.0985 20.4702 20.4051 22.375 23.0199C20.3722 25.7691 18.7971 28.8579 17.7495 32.1855H17.7439C15.4389 27.1524 11.3875 23.1085 6.37788 20.8537Z" fill="#D5D5AE" />
//                                                     <path d="M27 32.1855H17.7486C18.7962 28.8576 20.3714 25.7688 22.3745 23.0195C24.3779 25.7688 25.9524 28.8576 27 32.1855Z" fill="#C8C88A" />
//                                                     <path d="M15.5863 16.1855C12.8237 14.1472 9.72023 12.5555 6.37755 11.5171C11.3871 9.26227 15.4385 5.21836 17.7435 0.185566H17.7491C18.7967 3.51289 20.3719 6.60235 22.375 9.35125C20.4702 11.966 18.1781 14.2723 15.5863 16.1855Z" fill="#A0A15E" />
//                                                     <path d="M17.7486 0.185545H27C25.9524 3.5132 24.3779 6.60232 22.3745 9.35156C20.3717 6.60232 18.7962 3.5132 17.7486 0.185545Z" fill="#8A8B35" />
//                                                     <path d="M15.5879 16.1838C12.8252 18.2218 9.72177 19.8135 6.37909 20.8516L6.37777 20.8506V11.5163L6.37909 11.5153C9.72177 12.5537 12.8252 14.1454 15.5879 16.1838Z" fill="#B6B77C" />
//                                                 </g>
//                                                 <defs>
//                                                     <clipPath id="clip0_424_2478">
//                                                         <rect width="32" height="32" fill="white" />
//                                                     </clipPath>
//                                                 </defs>
//                                             </svg>

//                                             <p className='mb-0 mission-name'>We use our experience and our understanding of chemistry to ensure that our products are always ahead of the curve</p>
//                                         </div>

//                                         <div className='mission'>
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
//                                                 <path d="M26.5616 20.6681C23.2189 19.6301 20.1154 18.0383 17.3528 16.0003C14.7611 17.9129 12.4692 20.2195 10.5645 22.8343C12.5672 25.5835 14.1424 28.6723 15.19 32H15.1956C17.5006 26.9669 21.552 22.9229 26.5616 20.6681Z" fill="#FDA060" />
//                                                 <path d="M5.9375 32H15.1889C14.1413 28.672 12.5661 25.5832 10.563 22.834C8.55959 25.5832 6.98508 28.672 5.9375 32Z" fill="#F38336" />
//                                                 <path d="M17.3512 16C20.1138 13.9617 23.2173 12.3699 26.56 11.3315C21.5504 9.0767 17.499 5.03278 15.194 -3.24249e-05H15.1884C14.1408 3.3273 12.5656 6.41677 10.5625 9.16569C12.4673 11.7804 14.7594 14.0868 17.3512 16Z" fill="#FFC59E" />
//                                                 <path d="M15.1889 0.00387573H5.9375C6.98508 3.33154 8.5596 6.42068 10.563 9.16992C12.5658 6.42068 14.1413 3.33154 15.1889 0.00387573Z" fill="#FFB37F" />
//                                                 <path d="M17.3506 16.0021C20.1132 18.0401 23.2167 19.6318 26.5594 20.6699L26.5607 20.6689V11.3346L26.5594 11.3336C23.2167 12.372 20.1132 13.9638 17.3506 16.0021Z" fill="#F99856" />
//                                             </svg>

//                                             <p className='mb-0 mission-name'>We delight our customers by our commitment to see them succeed</p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className='col-lg-6'>
//                                 <div className='mission-images position-relative'>

//                                     <div className='about-corausel-left-btn'>
//                                         <button
//                                                 onClick={() => {
//                                                     if (slideIndex !== 0) {
//                                                         sliderRef.current?.slickPrev()
//                                                     }
//                                                 }}
//                                                 type="button"
//                                             >
//                                                 {
//                                                     slideIndex === 0 ?
//                                                         <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                             <g opacity="0.2">
//                                                                 <circle cx="27" cy="27" r="26" stroke={"#9E9FA3"} strokeWidth="2" />
//                                                                 <path d="M31.5 36L22.5 27L31.5 18" stroke={"#9E9FA3"} strokeWidth="2" strokeLinejoin="round" />
//                                                             </g>
//                                                         </svg>
//                                                         :

//                                                         <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                             <g opacity="0.98">
//                                                                 <circle cx="27" cy="27" r="26" stroke={"white"} strokeWidth="2" />
//                                                                 <path d="M31.5 36L22.5 27L31.5 18" stroke={"white"} strokeWidth="2" strokeLinejoin="round" />
//                                                             </g>
//                                                         </svg>
//                                                 }

//                                             </button>
//                                     </div>

//                                     <Slider ref={sliderRef} {...settings}>
//                                         {missions.map((item) => (
//                                             <div key={item.id} className='image'>
//                                                 <img src={item.image} className='max-w-100' />
//                                             </div>
//                                         ))}
//                                     </Slider>

//                                 <div className='about-corausel-right-btn'>
//                                     <button
//                                         onClick={() => {
//                                             if (slideIndex !== missions.length / 2) {
//                                                 sliderRef.current?.slickNext()
//                                             }
//                                         }}
//                                         type="button"
//                                     >
//                                         {
//                                             (slideIndex === missions.length / 2) ?
//                                                 <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                     <circle opacity="0.2" cx="27" cy="27" r="26" stroke={"#9E9FA3"} strokeWidth="2" />
//                                                     <path d="M22.5 36L31.5 27L22.5 18" stroke={"#9E9FA3"} strokeWidth="2" strokeLinejoin="round" />
//                                                 </svg>
//                                                 :
//                                                 <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                     <circle opacity="0.98" cx="27" cy="27" r="26" stroke={"white"} strokeWidth="2" />
//                                                     <path d="M22.5 36L31.5 27L22.5 18" stroke={"white"} strokeWidth="2" strokeLinejoin="round" />
//                                                 </svg>
//                                         }

//                                     </button>
//                                 </div>

//                                 </div>
//                             </div>

//                         </div> */}

//                     </div>
//                 </div >
//             </div >
//         </>
//     )
// }











import { right } from '@popperjs/core';
import React, { useRef, useState } from 'react'
import Slider from 'react-slick';



export default function OurMission() {

    const sliderRef = useRef(null);
    const [missions, setMissions] = useState([
        {
            image: "/assets/img/vission/passionate.jpg"
        }
    ]);
    const [missions1, setMissions1] = useState([
        {
            image: "/assets/img/vission/curious.jpg"
        }
    ]);
    const [missions2, setMissions2] = useState([
        {
            image: "/assets/img/vission/honest.jpg"
        }
    ]);
    const [missions3, setMissions3] = useState([
        {
            image: "/assets/img/vission/relationship.jpg"
        }
    ]);
    const [activeTab, setActiveTab] = useState("1");


    const settings = {
        autoplay: true,
        autoplaySpeed: 7000,
        lazyLoad: true,
        speed: 500,
        dots: false,
        // fade: true,
        arrows: false,
        pauseOnHover: false, // Pause carousel on hover
        afterChange: (current) => {
            console.log("logger123...12345", current)
            setSlideIndex(current)
            // setActiveSlide(current)
        },
        beforeChange: (prev, next) => {
        },
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    const [activeSlide, setActiveSlide] = useState(0);
    const [slideIndex, setSlideIndex] = useState(0);

    return (
        <>

            <div className='our-mission-section w-100' >
                <div className='container-fluid mx-auto' style={{ paddingRight: "0px" }}>

                    <div className='section-1'>
                        <div className='title-tab-container'>

                            <div className='mission-title-container'>
                                <p className='mb-0 subtitle'>Our Mission & Vision</p>
                                {/* <p className='mb-0 title'>Combining the Power of Chemistry with Technology</p> */}
                            </div>
                            <div className=' w-100' >
                                <div className='row '>
                                    <div className='col-lg-6' style={{ padding: 0 }}>
                                        <img src='/assets/img/mission.jpg' className='w-100 h-100 image' />
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                                            <div className='mission-card'>
                                                <div className='mission-details ' style={{ gap: "0px" }}>
                                                    <h2 className='mb-0 name'>Our Mission</h2>
                                                    <p className='mb-0 details'>To combine the power of chemistry with technology to make plastics safer,<br/>affordable and sustainable.</p>
                                                </div>
                                            </div>

                                        </div>



                                    </div>


                                    <div className='row p-0'>



                                        <div className='col-lg-6'>
                                            <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                                                <div className='mission-card'>

                                                    <div className='mission-details' style={{ gap: "0px" }}>
                                                        <h2 className='mb-0 name' >Our Vision</h2>
                                                        <p className='mb-0 details'>We do not aim to be the largest masterbatch manufacture but the BEST.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6' style={{ padding: 0, paddingLeft: "12px" }}>
                                            <img src='/assets/img/vission.jpg' className='w-100 h-100 image' />
                                        </div>

                                    </div>
                                </div>





                                <div className='divider'></div>
                                <div className='mission-title-container'>
                                    <p className='mb-0 subtitle'>Our Values</p>
                                    {/* <p className='mb-0 title'>Combining the Power of Chemistry with Technology</p> */}
                                </div>
                                <div className='tab-container'>
                                    <div className={activeTab === "1" ? 'tab tab-active' : "tab"} onClick={() => setActiveTab("1")}>
                                        <p className='mb-0 tab-title'>We are Passionate</p>
                                    </div>

                                    <div className={activeTab === "2" ? 'tab tab-active' : "tab"} onClick={() => setActiveTab("2")}>
                                        <p className='mb-0 tab-title'>We are Curious</p>
                                    </div>

                                    <div className={activeTab === "3" ? 'tab tab-active' : "tab"} onClick={() => setActiveTab("3")}>
                                        <p className='mb-0 tab-title'>We are Honest</p>
                                    </div>

                                    <div className={activeTab === "4" ? 'tab tab-active' : "tab"} onClick={() => setActiveTab("4")}>
                                        <p className='mb-0 tab-title'>We value Relationships</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            activeTab === "1" &&
                            <div className='row tab-content-container container mx-auto g-5'>
                                <div className='col-lg-6'>
                                    <div className='mission-details'>
                                        <h3 className='mb-0 details-title'>
                                                We use the power of chemistry to make the world a better place
                                        </h3>

                                        <div className='missions'>
                                            <div className='mission'>
                                                <div className="rotate180">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                    <path d="M6.00093 20.6682C9.34361 19.6301 12.4471 18.0384 15.2097 16.0004C17.8015 17.9129 20.0933 20.2196 21.998 22.8343C19.9953 25.5836 18.4201 28.6723 17.3725 32H17.3669C15.0619 26.9669 11.0105 22.923 6.00093 20.6682Z" fill="#B5B7E6" />
                                                    <path d="M26.623 32H17.3717C18.4193 28.672 19.9944 25.5832 21.9975 22.834C24.001 25.5832 25.5755 28.672 26.623 32Z" fill="#A3A7E0" />
                                                    <path d="M15.2094 16C12.4468 13.9617 9.34328 12.3699 6.00059 11.3315C11.0102 9.07672 15.0616 5.03282 17.3666 1.90735e-05H17.3722C18.4198 3.32734 19.9949 6.41681 21.998 9.16571C20.0933 11.7805 17.8011 14.0868 15.2094 16Z" fill="#6F73C4" />
                                                    <path d="M17.3717 -1.90735e-06H26.623C25.5755 3.32765 24.001 6.41678 21.9975 9.16602C19.9948 6.41678 18.4193 3.32765 17.3717 -1.90735e-06Z" fill="#595DAA" />
                                                    <path d="M15.2109 15.9982C12.4483 18.0362 9.34482 19.6279 6.00213 20.666L6.00081 20.665V11.3307L6.00213 11.3297C9.34482 12.3681 12.4483 13.9599 15.2109 15.9982Z" fill="#8387D3" />
                                                </svg>
                                                </div>

                                                    <p className='mb-0 mission-name'>We are the craftsmen of your dream because we do it right the first time, just as promised.</p>
                                            </div>

                                            <div className='mission'>
                                                    <div className="">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                    <g clip-path="url(#clip0_424_2469)">
                                                        <path d="M26.622 11.072C23.2794 12.1101 20.1759 13.7018 17.4133 15.7398C14.8216 13.8272 12.5298 11.5206 10.625 8.90586C12.6278 6.15664 14.2029 3.06787 15.2505 -0.259766H15.2561C17.5611 4.77334 21.6125 8.81721 26.622 11.072Z" fill="#ED98BB" />
                                                        <path d="M6 -0.259766L15.2513 -0.259766C14.2037 3.0682 12.6286 6.15698 10.6255 8.9062C8.62206 6.15698 7.04757 3.0682 6 -0.259766Z" fill="#DD7DA5" />
                                                        <path d="M17.4136 15.7402C20.1762 17.7786 23.2797 19.3703 26.6224 20.4087C21.6128 22.6635 17.5614 26.7074 15.2564 31.7401H15.2508C14.2033 28.4128 12.6281 25.3234 10.625 22.5745C12.5298 19.9597 14.8219 17.6534 17.4136 15.7402Z" fill="#DB518B" />
                                                        <path d="M15.2513 31.7402H6C7.04757 28.4125 8.62207 25.3234 10.6255 22.5742C12.6283 25.3234 14.2037 28.4125 15.2513 31.7402Z" fill="#C41E63" />
                                                        <path d="M17.4121 15.742C20.1747 13.704 23.2782 12.1123 26.6209 11.0742L26.6222 11.0752V20.4095L26.6209 20.4105C23.2782 19.3721 20.1747 17.7803 17.4121 15.742Z" fill="#CA487E" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_424_2469">
                                                            <rect width="32" height="32" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                </div>

                                                    <p className='mb-0 mission-name'>We innovate products with skill and creativity to have zero defects, as we are the architects of excellence.</p>
                                            </div>

                                            <div className='mission'>
                                                    <div className="rotate180">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                    <g clip-path="url(#clip0_424_2478)">
                                                        <path d="M6.37788 20.8537C9.72056 19.8157 12.824 18.2239 15.5867 16.1859C18.1784 18.0985 20.4702 20.4051 22.375 23.0199C20.3722 25.7691 18.7971 28.8579 17.7495 32.1855H17.7439C15.4389 27.1524 11.3875 23.1085 6.37788 20.8537Z" fill="#D5D5AE" />
                                                        <path d="M27 32.1855H17.7486C18.7962 28.8576 20.3714 25.7688 22.3745 23.0195C24.3779 25.7688 25.9524 28.8576 27 32.1855Z" fill="#C8C88A" />
                                                        <path d="M15.5863 16.1855C12.8237 14.1472 9.72023 12.5555 6.37755 11.5171C11.3871 9.26227 15.4385 5.21836 17.7435 0.185566H17.7491C18.7967 3.51289 20.3719 6.60235 22.375 9.35125C20.4702 11.966 18.1781 14.2723 15.5863 16.1855Z" fill="#A0A15E" />
                                                        <path d="M17.7486 0.185545H27C25.9524 3.5132 24.3779 6.60232 22.3745 9.35156C20.3717 6.60232 18.7962 3.5132 17.7486 0.185545Z" fill="#8A8B35" />
                                                        <path d="M15.5879 16.1838C12.8252 18.2218 9.72177 19.8135 6.37909 20.8516L6.37777 20.8506V11.5163L6.37909 11.5153C9.72177 12.5537 12.8252 14.1454 15.5879 16.1838Z" fill="#B6B77C" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_424_2478">
                                                            <rect width="32" height="32" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                </div>

                                                    <p className='mb-0 mission-name'>We combine our years of experience with understanding of chemistry, to transform everyday materials into magic with our innovative alchemy.</p>
                                            </div>

                                            <div className='mission'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                    <path d="M26.5616 20.6681C23.2189 19.6301 20.1154 18.0383 17.3528 16.0003C14.7611 17.9129 12.4692 20.2195 10.5645 22.8343C12.5672 25.5835 14.1424 28.6723 15.19 32H15.1956C17.5006 26.9669 21.552 22.9229 26.5616 20.6681Z" fill="#FDA060" />
                                                    <path d="M5.9375 32H15.1889C14.1413 28.672 12.5661 25.5832 10.563 22.834C8.55959 25.5832 6.98508 28.672 5.9375 32Z" fill="#F38336" />
                                                    <path d="M17.3512 16C20.1138 13.9617 23.2173 12.3699 26.56 11.3315C21.5504 9.0767 17.499 5.03278 15.194 -3.24249e-05H15.1884C14.1408 3.3273 12.5656 6.41677 10.5625 9.16569C12.4673 11.7804 14.7594 14.0868 17.3512 16Z" fill="#FFC59E" />
                                                    <path d="M15.1889 0.00387573H5.9375C6.98508 3.33154 8.5596 6.42068 10.563 9.16992C12.5658 6.42068 14.1413 3.33154 15.1889 0.00387573Z" fill="#FFB37F" />
                                                    <path d="M17.3506 16.0021C20.1132 18.0401 23.2167 19.6318 26.5594 20.6699L26.5607 20.6689V11.3346L26.5594 11.3336C23.2167 12.372 20.1132 13.9638 17.3506 16.0021Z" fill="#F99856" />
                                                </svg>

                                                    <p className='mb-0 mission-name'>We are the passionate about being the craftsmen of precision; we are committed to our customer’s success.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className='mission-images position-relative'>

                                        

                                        <Slider ref={sliderRef} {...settings}>
                                            {missions.map((item) => (
                                                <div key={item.id} className='image'>
                                                    <img src={item.image} className='max-w-100' />
                                                </div>
                                            ))}
                                        </Slider>

                                        

                                    </div>
                                </div>

                            </div>
                        }

                        {
                            activeTab === "2" &&
                            <div className='row tab-content-container container mx-auto g-5'>

                                <div className='col-lg-6'>
                                    <div className='mission-images position-relative'>

                                        

                                        <Slider ref={sliderRef} {...settings}>
                                            {missions1.map((item) => (
                                                <div key={item.id} className='image'>
                                                    <img src={item.image} className='max-w-100' />
                                                </div>
                                            ))}
                                        </Slider>

                                        

                                    </div>
                                </div>

                                    <div className='col-lg-6'>
                                        <div className='mission-details'>
                                            <h3 className='mb-0 details-title'>
                                                We challenge traditions and conventions with innovation
                                            </h3>

                                            <div className='missions'>
                                                <div className='mission'>
                                                    <div className="rotate180">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <path d="M6.00093 20.6682C9.34361 19.6301 12.4471 18.0384 15.2097 16.0004C17.8015 17.9129 20.0933 20.2196 21.998 22.8343C19.9953 25.5836 18.4201 28.6723 17.3725 32H17.3669C15.0619 26.9669 11.0105 22.923 6.00093 20.6682Z" fill="#B5B7E6" />
                                                            <path d="M26.623 32H17.3717C18.4193 28.672 19.9944 25.5832 21.9975 22.834C24.001 25.5832 25.5755 28.672 26.623 32Z" fill="#A3A7E0" />
                                                            <path d="M15.2094 16C12.4468 13.9617 9.34328 12.3699 6.00059 11.3315C11.0102 9.07672 15.0616 5.03282 17.3666 1.90735e-05H17.3722C18.4198 3.32734 19.9949 6.41681 21.998 9.16571C20.0933 11.7805 17.8011 14.0868 15.2094 16Z" fill="#6F73C4" />
                                                            <path d="M17.3717 -1.90735e-06H26.623C25.5755 3.32765 24.001 6.41678 21.9975 9.16602C19.9948 6.41678 18.4193 3.32765 17.3717 -1.90735e-06Z" fill="#595DAA" />
                                                            <path d="M15.2109 15.9982C12.4483 18.0362 9.34482 19.6279 6.00213 20.666L6.00081 20.665V11.3307L6.00213 11.3297C9.34482 12.3681 12.4483 13.9599 15.2109 15.9982Z" fill="#8387D3" />
                                                        </svg>
                                                    </div>

                                                    <p className='mb-0 mission-name'>We explore all innovation possibilities to be the pioneer of progress in developing you custom solutions for your organization</p>
                                                </div>

                                                <div className='mission'>
                                                    <div className="">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <g clip-path="url(#clip0_424_2469)">
                                                                <path d="M26.622 11.072C23.2794 12.1101 20.1759 13.7018 17.4133 15.7398C14.8216 13.8272 12.5298 11.5206 10.625 8.90586C12.6278 6.15664 14.2029 3.06787 15.2505 -0.259766H15.2561C17.5611 4.77334 21.6125 8.81721 26.622 11.072Z" fill="#ED98BB" />
                                                                <path d="M6 -0.259766L15.2513 -0.259766C14.2037 3.0682 12.6286 6.15698 10.6255 8.9062C8.62206 6.15698 7.04757 3.0682 6 -0.259766Z" fill="#DD7DA5" />
                                                                <path d="M17.4136 15.7402C20.1762 17.7786 23.2797 19.3703 26.6224 20.4087C21.6128 22.6635 17.5614 26.7074 15.2564 31.7401H15.2508C14.2033 28.4128 12.6281 25.3234 10.625 22.5745C12.5298 19.9597 14.8219 17.6534 17.4136 15.7402Z" fill="#DB518B" />
                                                                <path d="M15.2513 31.7402H6C7.04757 28.4125 8.62207 25.3234 10.6255 22.5742C12.6283 25.3234 14.2037 28.4125 15.2513 31.7402Z" fill="#C41E63" />
                                                                <path d="M17.4121 15.742C20.1747 13.704 23.2782 12.1123 26.6209 11.0742L26.6222 11.0752V20.4095L26.6209 20.4105C23.2782 19.3721 20.1747 17.7803 17.4121 15.742Z" fill="#CA487E" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_424_2469">
                                                                    <rect width="32" height="32" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div>

                                                    <p className='mb-0 mission-name'>We are the catalysts of curiosity and we are not afraid to question industry conventions and norms to bring you cutting edge solution for all your problems</p>
                                                </div>

                                                <div className='mission'>
                                                    <div className="rotate180">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <g clip-path="url(#clip0_424_2478)">
                                                                <path d="M6.37788 20.8537C9.72056 19.8157 12.824 18.2239 15.5867 16.1859C18.1784 18.0985 20.4702 20.4051 22.375 23.0199C20.3722 25.7691 18.7971 28.8579 17.7495 32.1855H17.7439C15.4389 27.1524 11.3875 23.1085 6.37788 20.8537Z" fill="#D5D5AE" />
                                                                <path d="M27 32.1855H17.7486C18.7962 28.8576 20.3714 25.7688 22.3745 23.0195C24.3779 25.7688 25.9524 28.8576 27 32.1855Z" fill="#C8C88A" />
                                                                <path d="M15.5863 16.1855C12.8237 14.1472 9.72023 12.5555 6.37755 11.5171C11.3871 9.26227 15.4385 5.21836 17.7435 0.185566H17.7491C18.7967 3.51289 20.3719 6.60235 22.375 9.35125C20.4702 11.966 18.1781 14.2723 15.5863 16.1855Z" fill="#A0A15E" />
                                                                <path d="M17.7486 0.185545H27C25.9524 3.5132 24.3779 6.60232 22.3745 9.35156C20.3717 6.60232 18.7962 3.5132 17.7486 0.185545Z" fill="#8A8B35" />
                                                                <path d="M15.5879 16.1838C12.8252 18.2218 9.72177 19.8135 6.37909 20.8516L6.37777 20.8506V11.5163L6.37909 11.5153C9.72177 12.5537 12.8252 14.1454 15.5879 16.1838Z" fill="#B6B77C" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_424_2478">
                                                                    <rect width="32" height="32" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div>

                                                    <p className='mb-0 mission-name'>We construct and design imaginative solutions to become trailblazers of technology with inventive solutions born from our deep curiosity in chemistry.</p>
                                                </div>

                                                <div className='mission'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                        <path d="M26.5616 20.6681C23.2189 19.6301 20.1154 18.0383 17.3528 16.0003C14.7611 17.9129 12.4692 20.2195 10.5645 22.8343C12.5672 25.5835 14.1424 28.6723 15.19 32H15.1956C17.5006 26.9669 21.552 22.9229 26.5616 20.6681Z" fill="#FDA060" />
                                                        <path d="M5.9375 32H15.1889C14.1413 28.672 12.5661 25.5832 10.563 22.834C8.55959 25.5832 6.98508 28.672 5.9375 32Z" fill="#F38336" />
                                                        <path d="M17.3512 16C20.1138 13.9617 23.2173 12.3699 26.56 11.3315C21.5504 9.0767 17.499 5.03278 15.194 -3.24249e-05H15.1884C14.1408 3.3273 12.5656 6.41677 10.5625 9.16569C12.4673 11.7804 14.7594 14.0868 17.3512 16Z" fill="#FFC59E" />
                                                        <path d="M15.1889 0.00387573H5.9375C6.98508 3.33154 8.5596 6.42068 10.563 9.16992C12.5658 6.42068 14.1413 3.33154 15.1889 0.00387573Z" fill="#FFB37F" />
                                                        <path d="M17.3506 16.0021C20.1132 18.0401 23.2167 19.6318 26.5594 20.6699L26.5607 20.6689V11.3346L26.5594 11.3336C23.2167 12.372 20.1132 13.9638 17.3506 16.0021Z" fill="#F99856" />
                                                    </svg>

                                                    <p className='mb-0 mission-name'>We are navigators of new horizons and we are out to explore with a spirit of curiosity, possibility and opportunity to bring you the newest.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                            </div>
                        }

                        {
                            activeTab === "3" &&
                            <div className='row tab-content-container container mx-auto g-5'>
                                    <div className='col-lg-6'>
                                        <div className='mission-details'>
                                            <h3 className='mb-0 details-title'>
                                                We deliver what we promised, as we promised, to add value by exceeding expectations.
                                            </h3>

                                            <div className='missions'>
                                                <div className='mission'>
                                                    <div className="rotate180">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <path d="M6.00093 20.6682C9.34361 19.6301 12.4471 18.0384 15.2097 16.0004C17.8015 17.9129 20.0933 20.2196 21.998 22.8343C19.9953 25.5836 18.4201 28.6723 17.3725 32H17.3669C15.0619 26.9669 11.0105 22.923 6.00093 20.6682Z" fill="#B5B7E6" />
                                                            <path d="M26.623 32H17.3717C18.4193 28.672 19.9944 25.5832 21.9975 22.834C24.001 25.5832 25.5755 28.672 26.623 32Z" fill="#A3A7E0" />
                                                            <path d="M15.2094 16C12.4468 13.9617 9.34328 12.3699 6.00059 11.3315C11.0102 9.07672 15.0616 5.03282 17.3666 1.90735e-05H17.3722C18.4198 3.32734 19.9949 6.41681 21.998 9.16571C20.0933 11.7805 17.8011 14.0868 15.2094 16Z" fill="#6F73C4" />
                                                            <path d="M17.3717 -1.90735e-06H26.623C25.5755 3.32765 24.001 6.41678 21.9975 9.16602C19.9948 6.41678 18.4193 3.32765 17.3717 -1.90735e-06Z" fill="#595DAA" />
                                                            <path d="M15.2109 15.9982C12.4483 18.0362 9.34482 19.6279 6.00213 20.666L6.00081 20.665V11.3307L6.00213 11.3297C9.34482 12.3681 12.4483 13.9599 15.2109 15.9982Z" fill="#8387D3" />
                                                        </svg>
                                                    </div>

                                                    <p className='mb-0 mission-name'>We have crystal-clear manufacturing techniques, to create accurate solutions to your unique problem</p>
                                                </div>

                                                <div className='mission'>
                                                    <div className="">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <g clip-path="url(#clip0_424_2469)">
                                                                <path d="M26.622 11.072C23.2794 12.1101 20.1759 13.7018 17.4133 15.7398C14.8216 13.8272 12.5298 11.5206 10.625 8.90586C12.6278 6.15664 14.2029 3.06787 15.2505 -0.259766H15.2561C17.5611 4.77334 21.6125 8.81721 26.622 11.072Z" fill="#ED98BB" />
                                                                <path d="M6 -0.259766L15.2513 -0.259766C14.2037 3.0682 12.6286 6.15698 10.6255 8.9062C8.62206 6.15698 7.04757 3.0682 6 -0.259766Z" fill="#DD7DA5" />
                                                                <path d="M17.4136 15.7402C20.1762 17.7786 23.2797 19.3703 26.6224 20.4087C21.6128 22.6635 17.5614 26.7074 15.2564 31.7401H15.2508C14.2033 28.4128 12.6281 25.3234 10.625 22.5745C12.5298 19.9597 14.8219 17.6534 17.4136 15.7402Z" fill="#DB518B" />
                                                                <path d="M15.2513 31.7402H6C7.04757 28.4125 8.62207 25.3234 10.6255 22.5742C12.6283 25.3234 14.2037 28.4125 15.2513 31.7402Z" fill="#C41E63" />
                                                                <path d="M17.4121 15.742C20.1747 13.704 23.2782 12.1123 26.6209 11.0742L26.6222 11.0752V20.4095L26.6209 20.4105C23.2782 19.3721 20.1747 17.7803 17.4121 15.742Z" fill="#CA487E" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_424_2469">
                                                                    <rect width="32" height="32" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div>

                                                    <p className='mb-0 mission-name'>We are honest artisans that work like open books, to craft you solutions with integrity of an artist that is faithful to your vision</p>
                                                </div>

                                                <div className='mission'>
                                                    <div className="rotate180">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <g clip-path="url(#clip0_424_2478)">
                                                                <path d="M6.37788 20.8537C9.72056 19.8157 12.824 18.2239 15.5867 16.1859C18.1784 18.0985 20.4702 20.4051 22.375 23.0199C20.3722 25.7691 18.7971 28.8579 17.7495 32.1855H17.7439C15.4389 27.1524 11.3875 23.1085 6.37788 20.8537Z" fill="#D5D5AE" />
                                                                <path d="M27 32.1855H17.7486C18.7962 28.8576 20.3714 25.7688 22.3745 23.0195C24.3779 25.7688 25.9524 28.8576 27 32.1855Z" fill="#C8C88A" />
                                                                <path d="M15.5863 16.1855C12.8237 14.1472 9.72023 12.5555 6.37755 11.5171C11.3871 9.26227 15.4385 5.21836 17.7435 0.185566H17.7491C18.7967 3.51289 20.3719 6.60235 22.375 9.35125C20.4702 11.966 18.1781 14.2723 15.5863 16.1855Z" fill="#A0A15E" />
                                                                <path d="M17.7486 0.185545H27C25.9524 3.5132 24.3779 6.60232 22.3745 9.35156C20.3717 6.60232 18.7962 3.5132 17.7486 0.185545Z" fill="#8A8B35" />
                                                                <path d="M15.5879 16.1838C12.8252 18.2218 9.72177 19.8135 6.37909 20.8516L6.37777 20.8506V11.5163L6.37909 11.5153C9.72177 12.5537 12.8252 14.1454 15.5879 16.1838Z" fill="#B6B77C" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_424_2478">
                                                                    <rect width="32" height="32" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div>

                                                    <p className='mb-0 mission-name'>We make mirror-images of your vision and discuss every step with our customers</p>
                                                </div>

                                                <div className='mission'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                        <path d="M26.5616 20.6681C23.2189 19.6301 20.1154 18.0383 17.3528 16.0003C14.7611 17.9129 12.4692 20.2195 10.5645 22.8343C12.5672 25.5835 14.1424 28.6723 15.19 32H15.1956C17.5006 26.9669 21.552 22.9229 26.5616 20.6681Z" fill="#FDA060" />
                                                        <path d="M5.9375 32H15.1889C14.1413 28.672 12.5661 25.5832 10.563 22.834C8.55959 25.5832 6.98508 28.672 5.9375 32Z" fill="#F38336" />
                                                        <path d="M17.3512 16C20.1138 13.9617 23.2173 12.3699 26.56 11.3315C21.5504 9.0767 17.499 5.03278 15.194 -3.24249e-05H15.1884C14.1408 3.3273 12.5656 6.41677 10.5625 9.16569C12.4673 11.7804 14.7594 14.0868 17.3512 16Z" fill="#FFC59E" />
                                                        <path d="M15.1889 0.00387573H5.9375C6.98508 3.33154 8.5596 6.42068 10.563 9.16992C12.5658 6.42068 14.1413 3.33154 15.1889 0.00387573Z" fill="#FFB37F" />
                                                        <path d="M17.3506 16.0021C20.1132 18.0401 23.2167 19.6318 26.5594 20.6699L26.5607 20.6689V11.3346L26.5594 11.3336C23.2167 12.372 20.1132 13.9638 17.3506 16.0021Z" fill="#F99856" />
                                                    </svg>

                                                    <p className='mb-0 mission-name'>Our transparent processes reveals our genuine intentions and our work always has the hallmark of authenticity and integrity</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                <div className='col-lg-6'>
                                    <div className='mission-images position-relative'>

                                        

                                        <Slider ref={sliderRef} {...settings}>
                                            {missions2.map((item) => (
                                                <div key={item.id} className='image'>
                                                    <img src={item.image} className='max-w-100' />
                                                </div>
                                            ))}
                                        </Slider>

                                        

                                    </div>
                                </div>

                            </div>
                        }


                        {
                            activeTab === "4" &&
                            <div className='row tab-content-container container mx-auto g-5'>

                                <div className='col-lg-6'>
                                    <div className='mission-images position-relative'>

                                        

                                        <Slider ref={sliderRef} {...settings}>
                                            {missions3.map((item) => (
                                                <div key={item.id} className='image'>
                                                    <img src={item.image} className='max-w-100' />
                                                </div>
                                            ))}
                                        </Slider>

                                        

                                    </div>
                                </div>

                                    <div className='col-lg-6'>
                                        <div className='mission-details'>
                                            <h3 className='mb-0 details-title'>
                                                Our organisation stands on the shoulders of our partners, customers and employees. (They are foundational to our success)
                                            </h3>

                                            <div className='missions'>
                                                <div className='mission'>
                                                    <div className="rotate180">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <path d="M6.00093 20.6682C9.34361 19.6301 12.4471 18.0384 15.2097 16.0004C17.8015 17.9129 20.0933 20.2196 21.998 22.8343C19.9953 25.5836 18.4201 28.6723 17.3725 32H17.3669C15.0619 26.9669 11.0105 22.923 6.00093 20.6682Z" fill="#B5B7E6" />
                                                            <path d="M26.623 32H17.3717C18.4193 28.672 19.9944 25.5832 21.9975 22.834C24.001 25.5832 25.5755 28.672 26.623 32Z" fill="#A3A7E0" />
                                                            <path d="M15.2094 16C12.4468 13.9617 9.34328 12.3699 6.00059 11.3315C11.0102 9.07672 15.0616 5.03282 17.3666 1.90735e-05H17.3722C18.4198 3.32734 19.9949 6.41681 21.998 9.16571C20.0933 11.7805 17.8011 14.0868 15.2094 16Z" fill="#6F73C4" />
                                                            <path d="M17.3717 -1.90735e-06H26.623C25.5755 3.32765 24.001 6.41678 21.9975 9.16602C19.9948 6.41678 18.4193 3.32765 17.3717 -1.90735e-06Z" fill="#595DAA" />
                                                            <path d="M15.2109 15.9982C12.4483 18.0362 9.34482 19.6279 6.00213 20.666L6.00081 20.665V11.3307L6.00213 11.3297C9.34482 12.3681 12.4483 13.9599 15.2109 15.9982Z" fill="#8387D3" />
                                                        </svg>
                                                    </div>

                                                    <p className='mb-0 mission-name'>We are masters in Bridge-Building with our partners, customers and employees. Good business are born out of strong relationships.</p>
                                                </div>

                                                <div className='mission'>
                                                    <div className="">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <g clip-path="url(#clip0_424_2469)">
                                                                <path d="M26.622 11.072C23.2794 12.1101 20.1759 13.7018 17.4133 15.7398C14.8216 13.8272 12.5298 11.5206 10.625 8.90586C12.6278 6.15664 14.2029 3.06787 15.2505 -0.259766H15.2561C17.5611 4.77334 21.6125 8.81721 26.622 11.072Z" fill="#ED98BB" />
                                                                <path d="M6 -0.259766L15.2513 -0.259766C14.2037 3.0682 12.6286 6.15698 10.6255 8.9062C8.62206 6.15698 7.04757 3.0682 6 -0.259766Z" fill="#DD7DA5" />
                                                                <path d="M17.4136 15.7402C20.1762 17.7786 23.2797 19.3703 26.6224 20.4087C21.6128 22.6635 17.5614 26.7074 15.2564 31.7401H15.2508C14.2033 28.4128 12.6281 25.3234 10.625 22.5745C12.5298 19.9597 14.8219 17.6534 17.4136 15.7402Z" fill="#DB518B" />
                                                                <path d="M15.2513 31.7402H6C7.04757 28.4125 8.62207 25.3234 10.6255 22.5742C12.6283 25.3234 14.2037 28.4125 15.2513 31.7402Z" fill="#C41E63" />
                                                                <path d="M17.4121 15.742C20.1747 13.704 23.2782 12.1123 26.6209 11.0742L26.6222 11.0752V20.4095L26.6209 20.4105C23.2782 19.3721 20.1747 17.7803 17.4121 15.742Z" fill="#CA487E" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_424_2469">
                                                                    <rect width="32" height="32" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div>

                                                    <p className='mb-0 mission-name'>We build strong, supportive and well-intentioned relationships with our customers to earn their trust.</p>
                                                </div>

                                                <div className='mission'>
                                                    <div className="rotate180">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <g clip-path="url(#clip0_424_2478)">
                                                                <path d="M6.37788 20.8537C9.72056 19.8157 12.824 18.2239 15.5867 16.1859C18.1784 18.0985 20.4702 20.4051 22.375 23.0199C20.3722 25.7691 18.7971 28.8579 17.7495 32.1855H17.7439C15.4389 27.1524 11.3875 23.1085 6.37788 20.8537Z" fill="#D5D5AE" />
                                                                <path d="M27 32.1855H17.7486C18.7962 28.8576 20.3714 25.7688 22.3745 23.0195C24.3779 25.7688 25.9524 28.8576 27 32.1855Z" fill="#C8C88A" />
                                                                <path d="M15.5863 16.1855C12.8237 14.1472 9.72023 12.5555 6.37755 11.5171C11.3871 9.26227 15.4385 5.21836 17.7435 0.185566H17.7491C18.7967 3.51289 20.3719 6.60235 22.375 9.35125C20.4702 11.966 18.1781 14.2723 15.5863 16.1855Z" fill="#A0A15E" />
                                                                <path d="M17.7486 0.185545H27C25.9524 3.5132 24.3779 6.60232 22.3745 9.35156C20.3717 6.60232 18.7962 3.5132 17.7486 0.185545Z" fill="#8A8B35" />
                                                                <path d="M15.5879 16.1838C12.8252 18.2218 9.72177 19.8135 6.37909 20.8516L6.37777 20.8506V11.5163L6.37909 11.5153C9.72177 12.5537 12.8252 14.1454 15.5879 16.1838Z" fill="#B6B77C" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_424_2478">
                                                                    <rect width="32" height="32" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div>

                                                    <p className='mb-0 mission-name'>We weave connections with all our customers to figure out their unique plastic need to craft them a tailored solution for their organization</p>
                                                </div>

                                                
                                            </div>
                                        </div>
                                    </div>



                            </div>
                        }



                    </div>
                </div>
            </div>
        </>
    )
}