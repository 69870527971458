import React, { useRef, useState } from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import CommonBanner from '../../common/CommonBanner'
import { Link } from 'react-router-dom'
import ContactCard from '../Industries/ContactCard'
import CommonContactCard from '../../common/CommonContactCard'
import Slider from 'react-slick'

function Sustainability() {

  const [industries, setIndustries] = useState([
    {
      image: "/assets/img/sustain-poster.png",
      title: "SUSTAINABILITY FOR",
      description: "Smoothing the way for recycled plastic",
    },
    {
      image: "/assets/img/sustain-poster.png",
      title: "SUSTAINABILITY FOR",
      description: "Smoothing the way for recycled plastic",
    },
    {
      image: "/assets/img/sustain-poster.png",
      title: "SUSTAINABILITY FOR",
      description: "Smoothing the way for recycled plastic",
    },
  ])

  const settings = {
    infinite: true,
    // lazyLoad: true,
    // centerMode: true,
    speed: 500,
    // slidesToShow: 1.1,
    // customPaging: "10px",
    // slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    // fade: true,
    arrows: false,
    pauseOnHover: false, // Pause carousel on hover
    afterChange: (current) => {
      setActiveSlide(current)
    },
  };


  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  return (
    <>
      <Menu />
      <CommonBanner imageUrl={'/assets/img/sustainability.png'} title={"Sustainability"} subtext={"From Plastic to Fantastic: We work with Approach to Plastic"} />
      <>
        {/* <!-- about area start --> */}
        <div className="tp-about-area grey-bg-4 pt-140 pb-100 overflow-hidden">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-lg-6">
                <div className="tp-about-left pr-90">
                  <div className="tp-section-wrapper pt-5">
                    <span className="tp-section-subtitle">
                      Our Motive
                    </span>
                    <h2 className="tp-section-title pt-25 mb-30">
                      We make plastics safer, affordable and sustainable
                    </h2>
                    <p className="tp-section-description">
                      At ALOK, we are pioneers in breakthrough solutions for the plastics industry. Over the past three decades, we have experienced and even led changes in the market, anticipating trends and keeping abreast of developments as they unfold.
                    </p>
                    <p className="pt-10">
                      Arguably one of the biggest changes and challenges in recent years has been the global demand for more sustainable practices in plastics manufacturing. With a deep-rooted belief that what is good for the environment is good for us too, we have made this a priority not only in the way we run our own R&D and production facilities, but also in terms of developing a variety of innovative products and solutions that enable our partners and customers both to deliver sustainable products, and to operate in a more environmentally-friendly way.
                    </p>
                  </div>
                  <div className="mt-45 mb-30 d-flex justify-content-center alignb-items-center d-lg-none ">

                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="tp-about-right p-relative d-none d-lg-flex align-items-center justify-content-end h-100">
                  <div className="about-img text-md-end">

                    <img src='assets/img/sustain1.png' />


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tp-service-ara pt-140 pb-140">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="tp-section-wrapper text-center mb-70">
                  <h2 className="tp-section-title">How our Sustainable Products help in making a difference for future</h2>
                </div>
              </div>
              <div className="row justify-content-end gx-130">
                <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                  <div className='w-100 p-0'>
                  <Slider  {...settings} ref={sliderRef}>
                    {industries.map((item, index) => (
                      <div className='sustain-slider-image' >
                        <img src={item.image} className='image' />
                        {/* <div className='sustain-overlay'>
                              <h2 className='sustain-title'>{item.title}</h2>
                              <h3 className='sustain-subtitle'>{item.description}</h3>
                          </div> */}
                      </div>

                    ))}
                  </Slider>
                  </div>

                  <div className='sustain-controls py-3 w-100'>
                    <div className='left-btn'>
                      <button type="submit" className="slider-btn" onClick={()=>sliderRef.current?.slickPrev()}>
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                        {" "} PREV
                      </button>

                    </div>

                    <div className='right-btn'>
                      <button type="submit" className="slider-btn" onClick={()=>sliderRef.current?.slickNext()}>
                        NEXT {" "}
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>

                      </button>
                    </div>
                  </div>

                </div>
                <div className="col-lg-6">
                  <div>


                    <p>
                      At ALOK, we are committed to environmental stewardship and sustainable business practices that contribute positively to our planet and communities. Our dedication to sustainability is reflected in our initiatives for efficient water and power management, as well as our investment in renewable energy sources.
                    </p>
                    <div>
                      <h4 className='text-black'>Efficient Water Management</h4>
                      <p>We prioritize responsible water usage across our operations. Through advanced technologies and continuous improvement strategies, we have significantly reduced our water consumption and minimized wastage. This commitment has been recognized with prestigious awards, underscoring our leadership in sustainable water management practices. For our efforts, ALOK has been awarded the CII National Awards for Excellence in Water Management in 2020 and 2021. ALOK was also awarded by FICCI for Efficiency in Water Usage in 2021.</p>
                    </div>
                    <div>
                      <h4 className='text-black'>Efficient Power Management</h4>
                      <p>Our focus on efficient power management extends to every aspect of our operations. We employ state-of-the-art systems and processes to optimize energy usage and reduce our carbon footprint. Our efforts in this area have earned us accolades for excellence in energy efficiency, reinforcing our commitment to sustainable energy practices. ALOK won the “Best Innovation in Energy Efficiency Award” at the CII National Energy Efficiency Circle Competition 2021. ALOK has also been awarded by FICCI, the Chemicals & Petrochemicals Award 2019, for the ‘Sustainability for Excellence in Safety.</p>
                    </div>
                    <div>
                      <h4 className='text-black'>Renewable Energy Integration</h4>
                      <p>As part of our sustainability journey, we have invested in renewable energy solutions, including in-house solar power panels and sourcing a portion of our energy needs from solar power parks. This strategic approach not only reduces our reliance on conventional energy sources but also contributes to the growth of renewable energy infrastructure and supports the transition to a low-carbon economy.</p>
                    </div>
                    <div><p>At ALOK, sustainability is not just a goal; it's a fundamental principle that guides our decisions and actions. We are dedicated to ongoing innovation and collaboration to further enhance our environmental performance and create a brighter, more sustainable future for generations to come.</p></div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="tp-service-ara black-bg-2 pt-140 pb-140">
          <div className="container">
            <div className="row">
              <div className="col-6 offset-md-3 text-center">
                <div className="tp-section-wrapper text-center mb-70">
                  <span className="tp-section-subtitle mb-25">Our Initiatives</span>
                  <h2 className="text-white tp-section-title">Beyond Commitment to a Sustainable Tomorrow</h2>
                </div>
              </div>
              <div className='container'>
                <div className='row'>

                  <div className='col-4'>
                    <div>
                      <div className='sustain-card mb-30'>
                        <h4>Mangrove Plantation</h4>
                        <p>In order to sustain Gujarat's coastal defenses and promote biodiversity, We are undertaking a three-year mangrove plantation project in Devla Village, Bharuch District.</p>
                      </div>
                      <div >
                        <img className='sustain-img-card' src='/assets/img/sustain-2.png' />
                      </div>
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='row mb-20'>
                      <div className='col-6'>
                        <div className='sustain-card-sm'>
                          <h4>1,62,500</h4>
                          <p>Mangrove trees were planted in Devla Village.</p>
                        </div>
                      </div>
                      <div className='col-6'>
                        <div className='sustain-card-sm'>
                          <h4>3</h4>
                          <p>Water ATMs have been set up across the island</p>
                        </div>
                      </div>
                    </div>
                    <div className='center-sustain-card mb-20'>
                      <h4>Stories of Sustainability</h4>
                      <button>Reach To Us</button>
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <div >
                          <img style={{ height: '260px' }} className='sustain-img-card' src='/assets/img/sustain-4.png' />
                        </div>
                      </div>
                      <div className='col-6'>
                        <div className='sustain-card-sm'>
                          <h4>Himalayan Cleanup Drive</h4>
                          <p>23 Feb, 2023</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className=' mb-20' >
                      <img className='sustain-img-card' src='/assets/img/sustain-3.png' />
                    </div>
                    <div className='sustain-card mb-30'>
                      <h4>Neil Island - Integrated Development Plan</h4>
                      <p>This is an integrated development project at Neil Island and to facilitate the same, MakeMyTrip Foundation has worked very closely with the government and local auhtorities at Andaman and Nicobar Islands.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <!-- about about end--> */}
      </>
      <div className='mt-130'>
        <CommonContactCard />
      </div>
      <TopFooter />
      <Footer />
    </>
  )
}

export default Sustainability