const menu_data = [
  {
    id: 1,
    hasDropdown: true,
    title: 'Home',
    link: '/',
    submenus: [
      { title: 'Home Style 1', link: '/' },
      { title: 'Home Style 2', link: '/home-2' },
      { title: 'Home Style 3', link: '/home-3' },
    ]
  },
  {
    id: 2,
    title: 'About',
    link: '/about',
  },
]

export default menu_data;
