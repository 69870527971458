import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import IntroBanner from '../../common/IntroBanner'
import ProductContact from './ProductContact'
import ProductsSidebar from '../Products/ProductsSideBar'
import OtherProducts from '../Products/OurProducts'
import IndustriesFooter from '../Industries/IndustriesFooter'

function Additive() {
    return (
        <>
            <Menu />

            <IntroBanner imageUrl={'/assets/img/products/additive-masterbatch.jpg'} title={"Our Product"} subtext={"Additive Masterbatches Engineered for Excellence in Every Application"} textAvatar="AM" />
            <div className="container">
                <div className='row gx-80 masterclass-container '>
                    <div className='col-md-8'>
                        <div >
                            <div className='title-container'>
                                <h3 className='title'>Wide Range of Additive Masterbatches</h3>
                            </div>
                            <div className='subtitle subtitle-padding'>
                                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK's Additive Masterbatches are designed to enhance the performance, durability, and aesthetics of plastics across a broad spectrum of industries. These masterbatches integrate cutting-edge chemical formulations to improve processing conditions and end-product functionalities, meeting the sophisticated demands of modern manufacturing.</p>
                            </div>

                            <div className='title-container pt-80'>
                                <h3 className='title'>Additive Masterbatches</h3>
                            </div>
                            <div className='subtitle subtitle-padding'>
                                <p className='subtitle_text' style={{ fontSize: "16px" }}>ALOK's additive masterbatches enhance the processing and performance of end product applications</p>
                            </div>

                            <div className=" ">
                                <div className="row">
                                    <div className="col overflow-auto">
                                        <table className="table table-bordered masterclass-table">
                                            <thead>
                                                <tr className="table-header" style={{ backgroundColor: 'black', color: 'white' }}>
                                                    <th scope="col">Products</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Application</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Polymer Processing Aid</td>
                                                    <td>High-performance fluoropolymer formulations eliminate melt fracture and reduce die buildup.</td>
                                                    <td>Suitable for film extrusion processes up to 300°C.</td>
                                                </tr>
                                                <tr>
                                                    <td>Antioxidant</td>
                                                    <td>Custom formulations stabilize processing and extend product life, ideal for high-temperature environments.</td>
                                                    <td>Crucial for recycling industries and processors using regrind.</td>
                                                </tr>
                                                <tr>
                                                    <td>Optical Brightener</td>
                                                    <td>The UltraBright range enhances the natural yellowness of plastics.</td>
                                                    <td>Used in recycled polymers and applications requiring high clarity.</td>
                                                </tr>
                                                <tr>
                                                    <td>Anti-Static</td>
                                                    <td>Prevents dust attraction and electrostatic discharge in various packaging and electronic applications.</td>
                                                    <td>Essential for FIBC liners, electronic packaging, and more.</td>
                                                </tr>
                                                <tr>
                                                    <td>Permanent Anti-Static</td>
                                                    <td>Effective at low addition levels, independent of humidity, offering anti-fog properties.</td>
                                                    <td>Used in PE applications, enhancing both functionality and aesthetics.</td>
                                                </tr>
                                                <tr>
                                                    <td>Absorbent Masterbatch</td>
                                                    <td>Enhances the visual appeal and shelf life of packaged fresh foods by absorbing excess moisture.</td>
                                                    <td>Ideal for food packaging in foamed trays.</td>
                                                </tr>
                                                <tr>
                                                    <td>Anti Odor</td>
                                                    <td>Chemically neutralizes foul odors during the recycling process of polymers.</td>
                                                    <td>Used to maintain a healthy processing environment and extend application possibilities.</td>
                                                </tr>
                                                <tr>
                                                    <td>Anti-Microbial</td>
                                                    <td>Offers permanent protection against microbes, compliant with major safety standards.</td>
                                                    <td>Suitable for applications demanding high hygiene standards, like consumer products and medical supplies.</td>
                                                </tr>
                                                <tr>
                                                    <td>Flame Retardant</td>
                                                    <td>Available in halogenated and non-halogenated grades for enhanced fire safety.</td>
                                                    <td>Essential for tapes, films, fibers, and general molding industries.</td>
                                                </tr>
                                                <tr>
                                                    <td>Slip</td>
                                                    <td>High-performance masterbatch for improved processing and surface properties of films.</td>
                                                    <td>Used in monolayer and multilayer films, laminations, and cast films.</td>
                                                </tr>
                                                <tr>
                                                    <td>Slip & Anti-block</td>
                                                    <td>Combines slip additives and anti-block properties for balanced performance in film applications.</td>
                                                    <td>Crucial for applications requiring both easy processing and clear optical properties.</td>
                                                </tr>
                                                <tr>
                                                    <td>Anti-block</td>
                                                    <td>Prevents blockages in film applications, enhancing machine performance and product quality.</td>
                                                    <td>Used in agricultural films and food packaging films.</td>
                                                </tr>
                                                <tr>
                                                    <td>Anti Rodent</td>
                                                    <td>Eco-friendly repellent masterbatch to protect plastic products from rodent attacks.</td>
                                                    <td>Suitable for applications where rodent exposure is a risk.</td>
                                                </tr>
                                                <tr>
                                                    <td>Light Diffuser</td>
                                                    <td>Minimizes light transmission loss while preventing corneal damage from direct LED light.</td>
                                                    <td>Designed for the LED lighting industry.</td>
                                                </tr>
                                                <tr>
                                                    <td>Purge Compound</td>
                                                    <td>Facilitates quick color changeovers and cleans out contaminants from injection molding equipment.</td>
                                                    <td>Ideal for the injection molding industry, ensuring clean and efficient transitions.</td>
                                                </tr>
                                                <tr>
                                                    <td>Sink Free</td>
                                                    <td>Removes sink marks and improves the aesthetics and accuracy of injection molded parts.</td>
                                                    <td>Crucial for producing high-quality, aesthetically pleasing molded products.</td>
                                                </tr>
                                                <tr>
                                                    <td>Foaming</td>
                                                    <td>Provides foaming solutions for PE and PS polymers, suited for various manufacturing processes.</td>
                                                    <td>Used in structural foam molding, sheet extrusion, and gas foam processes.</td>
                                                </tr>
                                                <tr>
                                                    <td>Desiccant</td>
                                                    <td>Absorbs moisture from recycled feedstock, improving processing and product quality.</td>
                                                    <td>A key solution for the recycling industry to enhance the value of recycled materials.</td>
                                                </tr>
                                                <tr>
                                                    <td>Ethylene Scavenger</td>
                                                    <td>Prolongs the shelf life of fresh foods packaged in films by absorbing ethylene.</td>
                                                    <td>Essential for packaging applications that involve fresh produce.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ProductContact />
                    </div>
                    <div className='col-md-4'>
                        <ProductsSidebar />
                    </div>
                </div>

            </div>
                {/* <OtherProducts/> */}
            <TopFooter />
            <Footer />
      {/* <IndustriesFooter/> */}
        </>
    )
}

export default Additive