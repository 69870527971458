import { useState } from "react"



export default function WhyAlok() {

    const [answers, setAnswers] = useState([
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <path d="M39.0648 30.0664L45.9799 42.1039L37.9847 40.5477L35.4102 48.4247L28.834 36.9789" fill="#FFC59E"/>
            <path d="M39.0648 30.0664L45.9799 42.1039L37.9847 40.5477L35.4102 48.4247L28.834 36.9789" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.9151 30.0664L4 42.1039L11.9952 40.5477L14.5697 48.4247L21.1459 36.9789" fill="#FFC59E"/>
            <path d="M10.9151 30.0664L4 42.1039L11.9952 40.5477L14.5697 48.4247L21.1459 36.9789" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.63672 19.7083C7.63672 24.4049 9.4651 28.9091 12.7196 32.23C15.9742 35.551 20.3883 37.4167 24.9909 37.4167C29.5935 37.4167 34.0076 35.551 37.2621 32.23C40.5167 28.9091 42.345 24.4049 42.345 19.7083C42.345 15.0118 40.5167 10.5076 37.2621 7.18665C34.0076 3.86569 29.5935 2 24.9909 2C20.3883 2 15.9742 3.86569 12.7196 7.18665C9.4651 10.5076 7.63672 15.0118 7.63672 19.7083Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26.0636 10.2771L28.5125 15.4831H32.798C33.8184 15.4831 34.4307 16.7325 33.6145 17.5655L29.5328 21.5221L31.7778 26.7281C32.1859 27.7693 31.1655 28.8105 30.1451 28.1858L24.8392 25.0622L19.5331 28.1858C18.5128 28.8105 17.2883 27.7693 17.9005 26.7281L20.1453 21.5221L16.0638 17.5655C15.2475 16.7325 15.8597 15.4831 16.8801 15.4831H21.1658L23.6146 10.2771C24.431 9.4441 25.6554 9.4441 26.0636 10.2771Z" fill="#FFC59E" stroke="black" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>`,
            name: "Unmatched Quality Assurance",
            detail: "At ALOK, we commit to the highest standards of quality. Each batch of Masterbatches undergoes rigorous testing to ensure consistency and performance, so you receive only the best products every time.",
        },
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <path d="M13.7768 27.2834L3.77734 21.7546C7.76239 14.1125 15.6095 12.2632 21.6641 13.9436" stroke="black" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.7305 36.2376L28.2592 46.2373C35.8755 42.2464 37.7315 34.4194 36.0662 28.3691" stroke="black" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.7773 27.2504L13.8614 27.3022C17.484 29.5382 20.5299 32.5951 22.7528 36.2256C29.9331 31.9244 38.0956 27.7584 41.8212 23.8973C49.8823 15.8364 45.2083 4.79493 45.2083 4.79493C45.2083 4.79493 34.1667 0.12094 26.1058 8.18185C22.2447 11.9075 18.0449 20.1039 13.7773 27.2504Z" stroke="black" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.1704 43.7715C13.1445 44.762 10.4314 45.7022 7.85335 46.4191C5.19957 47.1569 2.84472 44.8018 3.58248 42.148C4.29919 39.5701 5.23956 36.8569 6.23007 35.8312C6.74443 35.2791 7.36466 34.8364 8.05381 34.5293C8.74295 34.2223 9.48692 34.0572 10.2413 34.0438C10.9956 34.0305 11.7449 34.1693 12.4445 34.4519C13.144 34.7344 13.7795 35.1551 14.313 35.6886C14.8465 36.2218 15.2671 36.8575 15.5496 37.5569C15.8322 38.2566 15.971 39.006 15.9576 39.7601C15.9443 40.5145 15.7792 41.2586 15.4722 41.9477C15.1651 42.6368 14.7224 43.2572 14.1704 43.7715Z" fill="#FFC59E" stroke="black" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M34.4863 15.5147L35.7314 14.2695" stroke="black" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="35" cy="15" r="4" fill="#FFC59E" stroke="black" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>`,
            name: "Pioneering Innovation",
            detail: "Our ongoing dedication to innovation in the Masterbatch industry sets us apart. We continuously develop new formulations and enhance existing ones to meet the evolving needs of the market and our customers.",
        },
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <path d="M11.8153 2.5332C6.92535 2.5332 1.06898 9.90051 2.8883 15.0338C3.34436 16.3033 4.5273 17.3712 5.6932 18.0497C6.85911 18.7281 8.22695 18.9736 9.55601 18.7429C10.8851 18.5121 12.0902 17.82 12.9592 16.7883C13.8282 15.7566 14.3055 14.4514 14.307 13.1025C14.307 14.622 14.9106 16.0792 15.985 17.1536C17.0595 18.2281 18.5167 18.8317 20.0362 18.8317C21.5556 18.8317 23.0129 18.2281 24.0873 17.1536C25.1617 16.0792 25.7653 14.622 25.7653 13.1025C25.7653 14.622 26.3689 16.0792 27.4434 17.1536C28.5178 18.2281 29.975 18.8317 31.4945 18.8317C33.014 18.8317 34.4712 18.2281 35.5456 17.1536C36.6201 16.0792 37.2237 14.622 37.2237 13.1025C37.2252 14.4514 37.7025 15.7566 38.5715 16.7883C39.4405 17.82 40.6456 18.5121 41.9747 18.7429C43.3037 18.9736 44.6716 18.7281 45.8375 18.0497C47.0034 17.3712 47.8926 16.3033 48.3487 15.0338C50.2826 9.64545 43.8322 2.5332 38.8592 2.5332C29.1089 2.5332 19.0597 2.5332 11.8153 2.5332Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M30.5664 44.791V28.1243C30.5664 27.5718 30.7784 27.0419 31.1556 26.6512C31.5329 26.2605 32.0446 26.041 32.5781 26.041H38.6133C39.1468 26.041 39.6585 26.2605 40.0358 26.6512C40.4131 27.0419 40.625 27.5718 40.625 28.1243V44.791" fill="#FFC59E"/>
            <path d="M30.5664 44.791V28.1243C30.5664 27.5718 30.7784 27.0419 31.1556 26.6512C31.5329 26.2605 32.0446 26.041 32.5781 26.041H38.6133C39.1468 26.041 39.6585 26.2605 40.0358 26.6512C40.4131 27.0419 40.625 27.5718 40.625 28.1243V44.791" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M27.4785 44.7918L44.7911 44.7918V18.7812" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.29102 23.5518L7.29102 18.832" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.8513 48.9428C20.3492 48.9428 25.6167 43.6753 25.6167 37.1775C25.6167 30.6797 20.3492 25.4121 13.8513 25.4121C7.35348 25.4121 2.08594 30.6797 2.08594 37.1775C2.08594 43.6753 7.35348 48.9428 13.8513 48.9428Z" stroke="black" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.54492 41.7246H24.1575" stroke="black" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.54492 32.6289H24.1575" stroke="black" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.72625 26.6094C8.96229 29.5738 8.50391 32.8502 8.50391 36.1267C8.50391 40.3392 9.26791 44.3958 10.6431 48.1402" stroke="black" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.9773 26.6094C18.7414 29.5738 19.1997 32.8502 19.1997 36.1267C19.1997 40.3392 18.4357 44.3958 17.0605 48.1402" stroke="black" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>`,
            name: "Global Scale and Local Service",
            detail: "With facilities strategically located around the world, we offer the benefits of a global leader with the personalized service of a local provider. This unique combination ensures responsive service and a deep understanding of regional market dynamics.",
        },
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <path d="M9.06353 46.5594L3.89015 40.0831C2.95101 38.9075 2.43945 37.4474 2.43945 35.9428V21.6822C2.43945 19.8503 3.92451 18.3652 5.75642 18.3652C7.58831 18.3652 9.07338 19.8503 9.07338 21.6822V31.6331" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M12.106 37.6974L9.76059 35.352C8.46522 34.0565 8.46522 31.9563 9.76059 30.661C11.0559 29.3656 13.1561 29.3656 14.4515 30.661L15.8254 32.0349C18.9357 35.1453 20.683 39.3635 20.683 43.7621V46.5593" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M40.9357 46.5594L46.1089 40.0831C47.0482 38.9075 47.5597 37.4474 47.5597 35.9428V21.6822C47.5597 19.8503 46.0747 18.3652 44.2427 18.3652C42.4108 18.3652 40.9258 19.8503 40.9258 21.6822V31.6331" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M37.8934 37.6974L40.2388 35.352C41.5341 34.0565 41.5341 31.9563 40.2388 30.661C38.9436 29.3656 36.8433 29.3656 35.5479 30.661L34.174 32.0349C31.0637 35.1453 29.3164 39.3635 29.3164 43.7621V46.5593" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M29.8282 19.0346C28.1496 19.9879 26.2583 20.5037 24.3282 20.5346C22.7506 20.5326 21.2138 20.0331 19.9365 19.1073C18.6591 18.1815 17.7062 16.8765 17.2133 15.3779C16.7205 13.8793 16.7127 12.2634 17.1911 10.7601C17.6695 9.25683 18.6098 7.94268 19.8782 7.00461C19.8782 7.00461 22.3282 4.53461 28.3282 4.53461C31.0991 4.67325 33.8479 3.97649 36.2182 2.53461C36.292 2.49522 36.3745 2.47461 36.4582 2.47461C36.5419 2.47461 36.6243 2.49522 36.6982 2.53461C36.7742 2.56624 36.8408 2.61688 36.8916 2.68168C36.9424 2.74647 36.9756 2.82324 36.9882 2.90461C37.4482 5.55461 38.2282 14.4146 29.8282 19.0346Z" fill="#FFC59E" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M27.3291 11.0352C24.224 11.893 21.314 13.343 18.7591 15.3052L14.8691 18.6252" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>`,
            name: "Environmental Responsibility",
            detail: "Sustainability is at the heart of what we do. Our products and processes are designed to minimize environmental impact, helping our clients meet their sustainability goals.",
        },
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <path d="M41.0098 39.5L31.0098 41.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M29.3434 27.9219L22.4044 30.2617C21.2929 30.6361 20.083 30.0992 19.5933 29.0142C19.0628 27.8527 19.5312 26.4711 20.6519 25.8925L27.3726 22.4421C28.5304 21.8463 29.8786 21.7665 31.0962 22.2215L41.0463 25.9373" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.87305 39.9132C7.87305 39.9132 10.8848 39.5752 12.7651 39.9132C14.6454 40.2513 19.0098 48.5009 22.5098 47.0009C26.0098 45.5009 35.0098 39.9132 32.7414 37.1161L26.4508 28.8984" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.6736 25.3633L20.9573 24.7629C19.7873 23.8837 18.255 23.6837 16.9057 24.2341L8.00195 27.8662" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 24.3962H6.00167C7.06027 24.3515 7.95493 25.1885 8.0025 26.2685V39.3679C7.95493 40.4479 7.06027 41.285 6.00167 41.24H2" fill="#FFC59E"/>
            <path d="M2 24.3962H6.00167C7.06027 24.3515 7.95493 25.1885 8.0025 26.2685V39.3679C7.95493 40.4479 7.06027 41.285 6.00167 41.24H2" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M47.0181 41.2381H43.0165C41.9579 41.2828 41.0632 40.4458 41.0156 39.3658V26.2664C41.0632 25.1864 41.9579 24.3493 43.0165 24.3943H47.0181" fill="#FFC59E"/>
            <path d="M47.0181 41.2381H43.0165C41.9579 41.2828 41.0632 40.4458 41.0156 39.3658V26.2664C41.0632 25.1864 41.9579 24.3493 43.0165 24.3943H47.0181" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.4035 21C7.86349 10.0204 18.0362 0.37911 28.7146 3.6457C35.2527 5.6455 39.5213 11.9879 38.9604 18.868" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0098 11H13.77C15.9954 11.1743 17.7644 13.0235 17.9311 15.3501V19.4113C17.9311 20.011 18.4429 20.5295 19.0098 21" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M27.0098 3.5C27.0098 3.5 25.1346 5.01327 24.2932 5.73837C22.8673 6.95822 22.5874 9.23391 23.6659 10.8383L25.6451 13.787C26.2653 14.703 27.2345 15.2399 28.2624 15.2372H30.1811C31.049 15.2372 31.8816 15.6222 32.4956 16.3081L34.0098 18" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>`,
            name: "Customer-Centric Approach",
            detail: "We believe in building partnerships, not just client lists. Our approach is deeply rooted in understanding and fulfilling the unique needs of each customer, ensuring satisfaction and long-term success.",
        },
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <path d="M6.20898 36.1973V25.1348C6.20898 23.4779 7.55213 22.1348 9.20898 22.1348H17.7923C19.4492 22.1348 20.7923 23.4779 20.7923 25.1348V36.1973" fill="#FFC59E"/>
            <path d="M6.20898 36.1973V25.1348C6.20898 23.4779 7.55213 22.1348 9.20898 22.1348H17.7923C19.4492 22.1348 20.7923 23.4779 20.7923 25.1348V36.1973" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M48.4375 41.4056C48.4375 42.0896 48.3028 42.7668 48.041 43.3987C47.7793 44.0306 47.3957 44.6048 46.912 45.0884C46.4284 45.5721 45.8542 45.9557 45.2223 46.2175C44.5904 46.4792 43.9131 46.6139 43.2292 46.6139H6.77083C5.3895 46.6139 4.06474 46.0652 3.08799 45.0884C2.11123 44.1117 1.5625 42.7869 1.5625 41.4056C1.5625 40.0243 2.11123 38.6995 3.08799 37.7228C4.06474 36.746 5.3895 36.1973 6.77083 36.1973H43.2292C44.6105 36.1973 45.9353 36.746 46.912 37.7228C47.8888 38.6995 48.4375 40.0243 48.4375 41.4056Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.85352 41.4062H9.89518" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.2715 41.4062H20.3132" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M29.6875 41.4062H30.7292" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M40.1035 41.4062H41.1452" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.5 22.1348V27.3431" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M30.25 10.6777V12.7611C30.25 14.4187 30.9085 16.0084 32.0806 17.1805C33.2527 18.3526 34.8424 19.0111 36.5 19.0111C38.1576 19.0111 39.7473 18.3526 40.9194 17.1805C42.0915 16.0084 42.75 14.4187 42.75 12.7611V10.6777" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M30.25 10.6764V9.63477C30.25 7.97716 30.9085 6.38745 32.0806 5.21535C33.2527 4.04325 34.8424 3.38477 36.5 3.38477C38.1576 3.38477 39.7473 4.04325 40.9194 5.21535C42.0915 6.38745 42.75 7.97716 42.75 9.63477V10.6764" fill="#FFC59E"/>
            <path d="M30.25 10.6764V9.63477C30.25 7.97716 30.9085 6.38745 32.0806 5.21535C33.2527 4.04325 34.8424 3.38477 36.5 3.38477C38.1576 3.38477 39.7473 4.04325 40.9194 5.21535C42.0915 6.38745 42.75 7.97716 42.75 9.63477V10.6764" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M28.166 10.6777H44.8327" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26.4102 29.6484C27.2783 27.6904 28.6971 26.0271 30.4938 24.8613C32.2905 23.6954 34.3875 23.0772 36.5293 23.0821C38.6711 23.0869 40.7652 23.7146 42.5566 24.8886C44.348 26.0626 45.7593 27.7323 46.6185 29.6942" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>`,
            name: "Industry Expertise",
            detail: "With decades of experience, ALOK brings a wealth of knowledge and technical expertise to the table, helping to solve even the most complex challenges in the Masterbatch industry.",
        },
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <g clipPath="url(#clip0_556_795)">
              <path d="M45.3125 45.3125C45.3125 46.1413 44.9833 46.9362 44.3972 47.5222C43.8112 48.1083 43.0163 48.4375 42.1875 48.4375C41.3587 48.4375 40.5638 48.1083 39.9778 47.5222C39.3917 46.9362 39.0625 46.1413 39.0625 45.3125C39.0625 44.4837 39.3917 43.6888 39.9778 43.1028C40.5638 42.5167 41.3587 42.1875 42.1875 42.1875C43.0163 42.1875 43.8112 42.5167 44.3972 43.1028C44.9833 43.6888 45.3125 44.4837 45.3125 45.3125Z" fill="#FFC59E" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10.9375 45.3125C10.9375 46.1413 10.6083 46.9362 10.0222 47.5222C9.43616 48.1083 8.6413 48.4375 7.8125 48.4375C6.9837 48.4375 6.18884 48.1083 5.60279 47.5222C5.01674 46.9362 4.6875 46.1413 4.6875 45.3125C4.6875 44.4837 5.01674 43.6888 5.60279 43.1028C6.18884 42.5167 6.9837 42.1875 7.8125 42.1875C8.6413 42.1875 9.43616 42.5167 10.0222 43.1028C10.6083 43.6888 10.9375 44.4837 10.9375 45.3125Z" fill="#FFC59E" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M48.4375 1.5625H45.3125C44.4837 1.5625 43.6888 1.89174 43.1028 2.47779C42.5167 3.06384 42.1875 3.8587 42.1875 4.6875V32.2917C42.1875 33.1205 41.8583 33.9153 41.2722 34.5014C40.6862 35.0874 39.8913 35.4167 39.0625 35.4167H1.5625" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M35.9375 35.4167H20.3125V20.9792C20.3125 20.388 20.5474 19.821 20.9654 19.4029C21.3835 18.9849 21.9505 18.75 22.5417 18.75H33.7083C34.0011 18.75 34.2909 18.8077 34.5614 18.9197C34.8319 19.0317 35.0776 19.1959 35.2846 19.4029C35.4916 19.6099 35.6558 19.8556 35.7678 20.1261C35.8798 20.3966 35.9375 20.6864 35.9375 20.9792V35.4167Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M28.5 19.1348V23.1348" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M20.3125 35.4174H4.6875V24.6674C4.76026 24.1407 5.03165 23.6617 5.44609 23.3286C5.86052 22.9955 6.38664 22.8335 6.91667 22.8757H18.0833C18.6134 22.8335 19.1395 22.9955 19.5539 23.3286C19.9683 23.6617 20.2397 24.1407 20.3125 24.6674V35.4174Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12.5 23.1348V27.1348" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M28.125 15.9798V9.89648C28.125 9.06768 27.7958 8.27283 27.2097 7.68678C26.6237 7.10072 25.8288 6.77148 25 6.77148H15.625C14.7962 6.77148 14.0013 7.10072 13.4153 7.68678C12.8292 8.27283 12.5 9.06768 12.5 9.89648V19.6257" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M20.5 7.13477V11.1348" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_556_795">
                <rect width="50" height="50" fill="white"/>
              </clipPath>
            </defs>
          </svg>`,
            name: "Extensive Product Range",
            detail: "Whether you need standard or specialized Masterbatches, our diverse and comprehensive product line meets a wide array of applications and requirements, ensuring you always have the right solutions at hand.",
        },
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <path d="M38.0898 43.3431C38.0898 42.7906 37.8703 42.2607 37.4796 41.87C37.0889 41.4793 36.559 41.2598 36.0065 41.2598H15.1732C14.6206 41.2598 14.0907 41.4793 13.7 41.87C13.3093 42.2607 13.0898 42.7906 13.0898 43.3431V47.5098H38.0898V43.3431Z" fill="#FFC59E" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M33.9225 41.2598V32.9264C33.8991 32.3378 33.6914 31.7715 33.3288 31.3072C32.9663 30.843 32.4671 30.5043 31.9017 30.3389L25.5892 28.7598L19.2767 30.3389C18.7112 30.504 18.2118 30.8426 17.8492 31.3069C17.4865 31.7712 17.279 32.3378 17.2559 32.9264V41.2598" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.5069 4.68217L24.116 7.30931L27.9914 3.43081C28.1796 3.22776 28.4258 3.08771 28.6965 3.02978C28.9672 2.97185 29.2492 2.99886 29.504 3.10712C29.7588 3.21538 29.9739 3.39962 30.1201 3.63469C30.2663 3.86976 30.3363 4.1442 30.3207 4.42056L29.9547 10.7798L36.11 13.2076C36.3885 13.3218 36.624 13.5207 36.7831 13.7762C36.9422 14.0316 37.017 14.3307 36.9968 14.6309C36.9765 14.9312 36.8623 15.2175 36.6703 15.4493C36.4784 15.6811 36.2183 15.8467 35.9271 15.9225L28.888 17.8837L26.9228 24.9272C26.847 25.2185 26.6814 25.4785 26.4496 25.6704C26.2177 25.8623 25.9314 25.9765 25.6311 25.9968C25.3308 26.017 25.0317 25.9423 24.7762 25.7831C24.5207 25.624 24.3217 25.3886 24.2075 25.1102L21.7831 18.954L15.4265 19.3199C15.1499 19.3367 14.8748 19.2676 14.6389 19.1222C14.403 18.9767 14.2178 18.7619 14.1087 18.5072C13.9995 18.2525 13.9718 17.9703 14.0291 17.6992C14.0865 17.4282 14.2263 17.1814 14.4293 16.9928L18.3047 13.1271L15.679 6.50983C15.6013 6.25583 15.5942 5.98549 15.6584 5.72777C15.7227 5.47004 15.8559 5.23466 16.0437 5.04685C16.2316 4.85904 16.467 4.72587 16.7247 4.66163C16.9825 4.59739 17.2529 4.60449 17.5069 4.68217Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>`,
            name: "Award-Winning Excellence",
            detail: "Our commitment to excellence has been recognized by numerous industry awards. This external validation underscores our role as a leader in the Masterbatch market.",
        },
        {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <path d="M29.5352 7.81185C29.5352 9.19319 30.0839 10.5179 31.0606 11.4947C32.0374 12.4714 33.3622 13.0202 34.7435 13.0202C36.1248 13.0202 37.4496 12.4714 38.4263 11.4947C39.4031 10.5179 39.9518 9.19319 39.9518 7.81185C39.9518 6.43051 39.4031 5.10575 38.4263 4.129C37.4496 3.15225 36.1248 2.60352 34.7435 2.60352C33.3622 2.60352 32.0374 3.15225 31.0606 4.129C30.0839 5.10575 29.5352 6.43051 29.5352 7.81185Z" fill="#FFC59E" stroke="black" strokeWidth="1.5" stroke-miterlimit="10"/>
            <path d="M47.1132 41.8309C43.832 39.2038 43.0882 32.9226 43.0778 30.7226C43.0724 30.5829 43.0578 30.4437 43.034 30.3059V30.2205L40.9507 17.7205C40.8491 17.1132 40.5703 16.5494 40.1493 16.1C39.7284 15.6506 39.1841 15.3356 38.5847 15.1945C37.9853 15.0534 37.3576 15.0925 36.7804 15.3069C36.2032 15.5213 35.7022 15.9015 35.3403 16.3996C34.2448 17.9204 32.6836 19.0427 30.8931 19.5968C29.1026 20.1509 27.1804 20.1066 25.4174 19.4705C24.6464 19.2 23.8001 19.2422 23.0598 19.5881C22.3196 19.934 21.7443 20.5561 21.4571 21.3211C21.17 22.0861 21.1939 22.9331 21.5237 23.6806C21.8535 24.4282 22.463 25.0168 23.2215 25.3205C25.244 26.1107 27.4214 26.4241 29.5846 26.2362C31.7478 26.0483 33.8386 25.3643 35.6945 24.2371L36.4903 29.0288C33.9215 30.636 31.7665 32.8245 30.1992 35.4178C28.6318 38.0111 27.6961 40.9365 27.4674 43.958C27.3854 44.7826 27.6343 45.6061 28.1594 46.2472C28.6845 46.8883 29.4427 47.2946 30.2674 47.3767C30.3718 47.3885 30.4769 47.3941 30.582 47.3934C31.3558 47.3927 32.1019 47.1049 32.6757 46.5857C33.2495 46.0665 33.6103 45.3529 33.6882 44.583C33.9264 41.4414 35.2525 38.4812 37.4382 36.2121C38.1069 40.2659 40.1391 43.97 43.1986 46.7121C43.518 46.9777 43.8871 47.1768 44.2843 47.2981C44.6815 47.4193 45.0989 47.4601 45.5121 47.4182C45.9253 47.3762 46.326 47.2523 46.6907 47.0537C47.0555 46.8552 47.377 46.5858 47.6364 46.2616C47.8959 45.9373 48.0882 45.5646 48.2019 45.1651C48.3157 44.7657 48.3487 44.3476 48.299 43.9353C48.2493 43.523 48.1179 43.1247 47.9125 42.7637C47.7071 42.4027 47.4319 42.0863 47.1028 41.833L47.1132 41.8309Z" stroke="black" strokeWidth="1.5" strokeLinejoin="round"/>
            <path d="M10.7852 44.2715C10.7852 44.6819 10.866 45.0882 11.023 45.4674C11.1801 45.8465 11.4103 46.191 11.7004 46.4812C11.9906 46.7714 12.3351 47.0016 12.7143 47.1586C13.0934 47.3157 13.4998 47.3965 13.9102 47.3965C14.3205 47.3965 14.7269 47.3157 15.106 47.1586C15.4852 47.0016 15.8297 46.7714 16.1199 46.4812C16.41 46.191 16.6402 45.8465 16.7973 45.4674C16.9543 45.0882 17.0352 44.6819 17.0352 44.2715C17.0352 43.8611 16.9543 43.4547 16.7973 43.0756C16.6402 42.6965 16.41 42.352 16.1199 42.0618C15.8297 41.7716 15.4852 41.5414 15.106 41.3844C14.7269 41.2273 14.3205 41.1465 13.9102 41.1465C13.4998 41.1465 13.0934 41.2273 12.7143 41.3844C12.3351 41.5414 11.9906 41.7716 11.7004 42.0618C11.4103 42.352 11.1801 42.6965 11.023 43.0756C10.866 43.4547 10.7852 43.8611 10.7852 44.2715Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.1596 21.3535L14.7305 41.2535" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.86719 40.4348L5.94427 29.166L17.0005 31.9993L15.0005 40.9993C15.0005 40.9993 13.3792 41.1088 12.5005 41.4993C11.6218 41.8899 11.0005 42.4993 11.0005 42.4993L2.86719 40.4348Z" fill="#FFC59E" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.2086 42.7128L1.71484 40.1211" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>`,
            name: "Dependable Supply Chain",
            detail: "Our robust supply chain infrastructure ensures reliable and timely delivery of products, regardless of your location. We manage our logistics with precision to support your business continuity.",
        },
    ])
    return (
        <>
            <div className="w-100 why-section">
                <div className="container mx-auto">
                    <p className=" mb-0 why-title">
                        Why ALOK?
                    </p>

                    <div className="row g-5">
                        {/* <div className=""> */}
                            <div className="why-details" style={{marginTop : 0}}>
                                <h2 className="ans-1">Choose Alok for a partnership that values quality, innovation, and sustainability.</h2>
                                <p className="ans-2">With our extensive industry experience and commitment to excellence, we ensure each client receives personalized solutions that propel their products to the forefront of the market. Let Alok be your gateway to mastering the art of Masterbatches, where tradition meets innovation for your success.</p>
                            </div>
                        {/* </div> */}
                        <div className="">
                            <div className="why-answers row">
                                {
                                    answers.map((item, index) => (
                                        <div className="answer col-lg-3 mx-auto" key={index}>
                                            <div className="row pb-10">
                                            <div className="col-2 col-lg-3">
                                            <div className="icon" dangerouslySetInnerHTML={{ __html: item.logo }}>

                                            </div>
                                            
                                            </div>
                                            <div className="details col-10 col-lg-9">
                                                <p className="my-auto title">{item.name}</p>
                                            </div>
                                            </div>
                                            <div className="row">
                                                <p className="mb-0 desc">{item.detail}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}