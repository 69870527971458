import React, { useState } from 'react'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css';


export default function Faq() {


    const [faqs, setFaqs] = useState([
        {
            title: "Lorem ipsum dolor sit amet consectetur?",
            value: "Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product, you should determine where your inventory will be held.Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product,"
        },
        {
            title: "Lorem ipsum dolor sit amet consectetur?",
            value: "Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product, you should determine where your inventory will be held.Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product,"
        },
        {
            title: "Lorem ipsum dolor sit amet consectetur?",
            value: "Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product, you should determine where your inventory will be held.Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product,"
        },
        {
            title: "Lorem ipsum dolor sit amet consectetur?",
            value: "Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product, you should determine where your inventory will be held.Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product,"
        },
        {
            title: "Lorem ipsum dolor sit amet consectetur?",
            value: "Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product, you should determine where your inventory will be held.Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product,"
        },
        {
            title: "Lorem ipsum dolor sit amet consectetur?",
            value: "Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product, you should determine where your inventory will be held.Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product,"
        },
        {
            title: "Lorem ipsum dolor sit amet consectetur?",
            value: "Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product, you should determine where your inventory will be held.Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product,"
        },
        {
            title: "Lorem ipsum dolor sit amet consectetur?",
            value: "Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product, you should determine where your inventory will be held.Research helps you plan the best way to get your product from the manufacturer to the retail shelf. In addition to deciding which retailers should carry your product,"
        },
    ])


    return (
        <>
            <div className='w-100 faq-section' id='faq'>
                <div className='container mx-auto faq-container'>
                    <div className='faq-title-container'>
                        <p className='mb-0 subtitle'>Frequently asked questions</p>
                        <p className='mb-0 title'>Anything Related to our company</p>
                    </div>

                    <div className='faqs'>
                        <Accordion>
                            {
                                faqs.map((item, index) => (
                                    <AccordionItem key={index}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                {item.title}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            {item.value}
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                ))
                            }
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}