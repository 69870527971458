import React from "react";
import Brands from "../../common/Brands/Brands";
import Menu from "../../common/Menu/Menu";
import Testimonial from "../../common/Testimonial/Testimonial";
import Services from "../../HomeTwo/Services";
import AboutBanner from "./AboutBanner";
import AboutCompany from "./AboutCompany";
import AboutFeature from "./AboutFeature";
import Footer from "../../common/Footer/Footer";
import TopFooter from "../../common/TopFooter/TopFooter";
import Team from "../../common/Team/Team";
import Achievements from "../../Home/Achievements";
import LastedBlog from "../../Home/LastedBlog";
import OurMission from "./OurMission";
import WhyAlok from "./WhyAlok";
import OurPhiloshophy from "./OurPhiloshophy";
import OurStory from "./OurStory";

const About = () => {
  return (
    <>
      <Menu />
      <AboutBanner />
      {/* <Brands /> */}
      <AboutCompany />
      {/* <WhyAlok/> */}
      <OurMission />
      <OurPhiloshophy/>
      <WhyAlok/>
      {/* <Team /> */}
      {/* <LastedBlog /> */}
      <Achievements />

      <OurStory/>
      <Services />
      {/* <AboutFeature /> */}
      {/* <Testimonial /> */}
      <TopFooter />
      <Footer />
    </>
  );
};

export default About;
