import React, { useRef } from "react";
import Slider from "react-slick";
import slider from "../../data/SliderHomePageOne.json";
import Timeline from "./Timeline";

const HomeSlider = () => {
  const sliderRef = useRef(null);
  const [isHovered, setIsHovered] = React.useState(false);
  const [progressVisible, setProgressVisible] = React.useState(true);

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    lazyLoad: true,
    speed: 0,
    dots: false,
    fade: true,
    animation: false,
    arrows: false,
    pauseOnHover: false, // Pause carousel on hover
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    setProgressVisible(false); // Hide progress bar on carousel hover
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setProgressVisible(true); // Show progress bar when mouse leaves carousel
  };

  return (
    <>
      {/* <!-- hero slider start --> */}
      <div className="slider-home position-relative" id="heroSection">
        <div className="tp-slider-area p-relative ">
          <div className="tp-hero_slider slider-active p-relative overflow-hidden">
            {/* <LineProgressBar loading={!isHovered} /> Show progress bar if not hovered over carousel */}
            {/* <video className="position-absolute top-0 w-100 h-100 slider-bg-video" src="/assets/img/bg-video.mp4" autoPlay loop ></video> */}

            <video
              className="position-absolute top-0 w-100 h-100 slider-bg-video"
              src="/assets/img/bg-video.mp4"
              autoPlay
              loop
              muted
            >
              Your browser does not support the video tag.
            </video>


            <div className="">
              <Slider ref={sliderRef} {...settings} className="h-100">
                {slider?.slider.map((item, index) => (
                  <div key={index}>
                    {/* <ProgressBar/> */}
                    <div
                      className="tp-single-slider tp-slider-height p-relative"
                      style={{ backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}
                    >
                      <div className="w-100 tp-slider-text hero-shadow d-flex flex-column justify-content-end align-items-start h-100">
                        <div className="container">

                          {/* <p className="mb-15 d-inline-block hero-tagline"> */}
                            {/* {item.title} */}

                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clipPath="url(#clip0_100_10052)">
                              <path d="M12.4799 13.2911L19.1844 8.1973C19.2756 8.10706 19.339 7.9925 19.367 7.8673C19.395 7.7421 19.3866 7.61146 19.3427 7.4909C19.2988 7.37034 19.2214 7.2649 19.1194 7.18698C19.0175 7.10914 18.8953 7.0621 18.7674 7.05146L13.3966 7.0481L10.606 0.990901C10.5488 0.878981 10.4617 0.785061 10.3544 0.719461C10.2471 0.653861 10.1239 0.619141 9.99812 0.619141C9.87244 0.619141 9.74908 0.653861 9.6418 0.719461C9.53452 0.785061 9.4474 0.878981 9.39012 0.990901L6.6038 7.0481H1.23292C1.10508 7.05874 0.982916 7.10578 0.880996 7.1837C0.778996 7.26154 0.701556 7.36698 0.657636 7.48762C0.613796 7.60818 0.605396 7.73874 0.633396 7.86394C0.661476 7.98914 0.724836 8.1037 0.815956 8.19402L5.64788 11.8775L3.6006 18.5279C3.56708 18.6541 3.57076 18.7874 3.61116 18.9117C3.65156 19.0359 3.72708 19.1459 3.82844 19.2282C3.92988 19.3106 4.05292 19.3619 4.18276 19.3759C4.31268 19.3901 4.4438 19.3663 4.56052 19.3076L9.99812 15.2135L15.4378 19.3046C15.5544 19.3633 15.6856 19.387 15.8156 19.3729C15.9454 19.3587 16.0684 19.3075 16.1698 19.2251C16.2712 19.1427 16.3468 19.0328 16.3871 18.9086C16.4276 18.7843 16.4312 18.6511 16.3977 18.5248L15.1584 13.8811" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_100_10052">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg> */}
                            {/* ALOK MASTERBATCHES */}

                            {/* INDIA’s Most Trusted <span>Masterbatch Manufacturer</span> */}
                          {/* </p> */}

                          <h3 className="tp-slider-title">
                            {/* {item.subTitle1}  */}
                            India’s Most Trusted
                            <br />
                            {/* {item.subTitle2} */}
                            Masterbatch Manufacturer
                          </h3>

                          <div className="row w-fit-content">
                            <h5 className="col-lg-5 tp-slider-title tp-slider-sub-head p-0">
                              {/* {item.subTitle3} */}
                              Adding Good To Make Better
                            </h5>
                            <h5 className="col-lg-3 tp-slider-title tp-slider-subtitle m-0">
                              {item.subTitle3}
                            </h5>
                          </div>

                        </div>

                        {/* <Link
                        to="/"
                        className="tp-slider-btn mt-40 d-inline-block"
                        >
                        <span>
                        <svg
                        width="53"
                        height="8"
                        viewBox="0 0 53 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                        d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                        fill="currentColor"
                        />
                        </svg>
                        <svg
                            width="53"
                            height="8"
                            viewBox="0 0 53 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                              d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        {item?.btnText}
                      </Link> */}
                      </div>
                    </div>
                    {/* {isHovered} */}
                  </div>
                ))}
              </Slider>
            </div>

          </div>
          {/* <div className="slider-arrow">
            <button
            onClick={() => sliderRef.current?.slickNext()}
            type="button"
            className="slick-prev slick-arrow"
            >
            <span>Next</span>
            </button>
            <button
            onClick={() => sliderRef.current?.slickPrev()}
            type="button"
              className="slick-next slick-arrow"
            >
              <span>Prev</span>
            </button>
          </div> */}

          {/* <div className="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style={{ opacity: 1 }}>
            <div className="scroll-prompt-arrow-container">
              <div className="scroll-prompt-arrow"><div></div></div>
              <div className="scroll-prompt-arrow"><div></div></div>
              <div className="scroll-prompt-arrow"><div></div></div>
            </div>
          </div> */}

          <Timeline />

        </div>
      </div>
      {/* <!-- hero slider end  --> */}
    </>
  );
};

export default HomeSlider;
