import React from "react";
import { Link } from "react-router-dom";




const CareerBanner = () => {
  return (
    <>
      {/* <!-- breadcrumb area start --> */}
      <section
        className="career_banner"
      >
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-12 h-100">
              <div className="banner-title-container">
                <p className="mb-0 head"> </p>
                <div className="sub-heads-container">
                  <h3 className="mb-0 sub-head">
                  Join a growing team with outsized impact
                  </h3>

                  <div className="view-open-btn" >
                    <p className="mb-0 btn-text">View Open Poitions</p>
                    <div className="offcanva-btn mt-60" >
                      <a
                        href={"#jobs"}
                        className="tp-slider-btn d-inline-block"
                        tabIndex="-1"
                      >
                        <span>
                          <svg
                            width="53"
                            height="8"
                            viewBox="0 0 53 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                              fill="white"
                            ></path>
                          </svg>
                          <svg
                            width="53"
                            height="8"
                            viewBox="0 0 53 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                              fill="white"
                            ></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- breadcrumb area end --> */}
    </>
  );
};

export default CareerBanner;
