import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import HeroSection from './HeroSection'
import Applications from './Applications'
import OtherIndustries from './OtherIndustries'
import ContactCard from './ContactCard'
import HeroIndustries from './Agriculture/HeroIndustries'
import IndustriesFooter from './IndustriesFooter'
import OtherProducts from '../Products/OurProducts'

function Industries() {
  return (
      <>
          <Menu />
      <HeroIndustries imageUrl={'/assets/img/industries/indus-bg1.png'} title={"Our Industries"} subtext={"Advancing Textiles with Innovative Fiber Solutions"} />
          <Applications/>
          <MasterBatchSidebar/>
          <OtherProducts/>
          <ContactCard/>
          <TopFooter />
          {/* <IndustriesFooter/> */}
          <Footer />
      </>
  )
}

export default Industries