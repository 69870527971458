import React from "react";
import { Link } from "react-router-dom";

const Menus = ({setOffCanvas, showProducts, setShowProducts, showIndustries, setShowIndustries}) => {
  return (
    <ul>
      <li className="">
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/about">About Us</Link>
      </li>
      <li>
        <Link to="#" onClick={()=>{setOffCanvas(true); setShowIndustries(true); setShowProducts(false)}}>Industries</Link>
      </li>
      <li>
        <Link to="#" onClick={()=>{setOffCanvas(true);  setShowIndustries(false); setShowProducts(true)}}>Products</Link>
      </li>
      {/* <li>
        <Link to="/">Innovation</Link>
      </li> */}
      <li>
        <Link to="/sustainability">Sustainability</Link>
      </li>
      <li>
        <Link to="/regulatory">Regulatory</Link>
      </li>
      <li>
        <Link to="/contact-us">Contact</Link>
      </li>
    </ul>
  );
};

export default Menus;
