import { useEffect, useRef } from "react";
import IndustrySidebar from "../IndustrySidebar";

export default function Applications() {
    const detailsRef = useRef(null);
    const sidebarRef = useRef(null);
    const containerRef = useRef(null);

    // useEffect(() => {
    //     if (window.innerWidth > 990) {
    //         const observer = new IntersectionObserver(
    //             ([entry], observer) => {
    //                 const details = detailsRef.current;
    //                 const sidebar = sidebarRef.current;
    //                 if (entry.isIntersecting) {
    //                     details.style.position = "sticky";
    //                     details.style.top = `${containerRef.current.getBoundingClientRect().top}px`;
    //                     sidebar.style.position = "sticky";
    //                     sidebar.style.top = `${containerRef.current.getBoundingClientRect().top}px`;
    //                 } else {
    //                     details.style.position = "relative";
    //                     sidebar.style.position = "relative";
    //                 }
    //             },
    //             {
    //                 root: null,
    //                 threshold: 1.0,
    //             }
    //         );

    //         observer.observe(detailsRef.current);
    //         observer.observe(sidebarRef.current);

    //         return () => observer.disconnect();
    //     }
    // }, []);

    return (
        <div className="w-100 applications" ref={containerRef}>
            <div className="container mx-auto hero-container">
                <div className="w-100 row gy-5 custom-gx" >
                    <div className="col-lg-7" style={{ overflowY: "scroll" }} ref={detailsRef}>
                        <div className="application-details">
                            <div className="details">
                                <div className="title-container">
                                    <p className="title mb-0">Automotive Industry</p>
                                </div>
                                <div className="description-container">
                                    <p className="description mb-0">
                                        ALOK Masterbatches propels the automotive industry forward by delivering high-quality polymer compounds...
                                    </p>
                                </div>
                            </div>
                            <div className="application-list">
                                <div className="row application">
                                    <div className="col-2 col-sm-1 p-0 logo">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                            <rect width="40" height="40" rx="20" fill="#622466" />
                                            <path d="M15.1524 8.99906C16.2554 12.5507 17.9466 15.8481 20.112 18.7835C18.0799 21.5372 15.6291 23.9723 12.8509 25.9961C9.92981 23.8681 6.64796 22.1945 3.1123 21.0815V21.0755C8.46004 18.6264 12.7567 14.3218 15.1524 8.99906Z" fill="white" />
                                            <path d="M3.1123 30.9121V21.0825C6.64831 22.1955 9.93017 23.8692 12.8512 25.9975C9.93017 28.1261 6.64831 29.7991 3.1123 30.9121Z" fill="white" />
                                            <path d="M20.1133 18.7851C22.279 15.8498 23.9703 12.5523 25.0736 9.00067C27.4693 14.3234 31.766 18.628 37.1133 21.0771V21.0831C33.578 22.1961 30.2955 23.8697 27.3748 25.998C24.5966 23.9742 22.1461 21.5388 20.1133 18.7851Z" fill="white" />
                                            <path d="M37.1139 21.0825V30.9121C33.5783 29.7991 30.2961 28.1261 27.375 25.9975C30.2961 23.8695 33.5783 22.1955 37.1139 21.0825Z" fill="white" />
                                            <path d="M20.1148 18.7852C17.9494 15.8498 16.2582 12.5524 15.1553 9.00075L15.1563 8.99935H25.0741L25.0751 9.00075C23.9718 12.5524 22.2806 15.8498 20.1148 18.7852Z" fill="white" />
                                        </svg>
                                    </div>
                                    <div className="col-10 col-sm-11 pl-3">
                                        <div className="details">
                                            <p className="title mb-0">Interior Automotive Components</p>
                                            <p className="description mb-0">Our Masterbatches are essential in manufacturing robust and aesthetically pleasing interior automotive components. These include dashboards, door panels, and console elements that not only meet design specifications but also comply with safety standards, enhancing both functionality and passenger experience.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row application">
                                    <div className="col-2 col-sm-1 p-0 logo">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                            <rect width="40" height="40" rx="20" fill="#622466" />
                                            <path d="M15.1524 8.99906C16.2554 12.5507 17.9466 15.8481 20.112 18.7835C18.0799 21.5372 15.6291 23.9723 12.8509 25.9961C9.92981 23.8681 6.64796 22.1945 3.1123 21.0815V21.0755C8.46004 18.6264 12.7567 14.3218 15.1524 8.99906Z" fill="white" />
                                            <path d="M3.1123 30.9121V21.0825C6.64831 22.1955 9.93017 23.8692 12.8512 25.9975C9.93017 28.1261 6.64831 29.7991 3.1123 30.9121Z" fill="white" />
                                            <path d="M20.1133 18.7851C22.279 15.8498 23.9703 12.5523 25.0736 9.00067C27.4693 14.3234 31.766 18.628 37.1133 21.0771V21.0831C33.578 22.1961 30.2955 23.8697 27.3748 25.998C24.5966 23.9742 22.1461 21.5388 20.1133 18.7851Z" fill="white" />
                                            <path d="M37.1139 21.0825V30.9121C33.5783 29.7991 30.2961 28.1261 27.375 25.9975C30.2961 23.8695 33.5783 22.1955 37.1139 21.0825Z" fill="white" />
                                            <path d="M20.1148 18.7852C17.9494 15.8498 16.2582 12.5524 15.1553 9.00075L15.1563 8.99935H25.0741L25.0751 9.00075C23.9718 12.5524 22.2806 15.8498 20.1148 18.7852Z" fill="white" />
                                        </svg>
                                    </div>
                                    <div className="col-10 col-sm-11 pl-3">
                                        <div className="details">
                                            <p className="title mb-0">Exterior Automotive Parts</p>
                                            <p className="description mb-0">We provide specialized Masterbatches that improve the weatherability and UV resistance of exterior automotive parts. These products help maintain color consistency and material integrity in parts such as bumpers, grilles, and mirror housings, even under harsh environmental conditions.</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="row application">
                                    <div className="col-2 col-sm-1 p-0 logo">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                            <rect width="40" height="40" rx="20" fill="#622466" />
                                            <path d="M15.1524 8.99906C16.2554 12.5507 17.9466 15.8481 20.112 18.7835C18.0799 21.5372 15.6291 23.9723 12.8509 25.9961C9.92981 23.8681 6.64796 22.1945 3.1123 21.0815V21.0755C8.46004 18.6264 12.7567 14.3218 15.1524 8.99906Z" fill="white" />
                                            <path d="M3.1123 30.9121V21.0825C6.64831 22.1955 9.93017 23.8692 12.8512 25.9975C9.93017 28.1261 6.64831 29.7991 3.1123 30.9121Z" fill="white" />
                                            <path d="M20.1133 18.7851C22.279 15.8498 23.9703 12.5523 25.0736 9.00067C27.4693 14.3234 31.766 18.628 37.1133 21.0771V21.0831C33.578 22.1961 30.2955 23.8697 27.3748 25.998C24.5966 23.9742 22.1461 21.5388 20.1133 18.7851Z" fill="white" />
                                            <path d="M37.1139 21.0825V30.9121C33.5783 29.7991 30.2961 28.1261 27.375 25.9975C30.2961 23.8695 33.5783 22.1955 37.1139 21.0825Z" fill="white" />
                                            <path d="M20.1148 18.7852C17.9494 15.8498 16.2582 12.5524 15.1553 9.00075L15.1563 8.99935H25.0741L25.0751 9.00075C23.9718 12.5524 22.2806 15.8498 20.1148 18.7852Z" fill="white" />
                                        </svg>
                                    </div>
                                    <div className="col-10 col-sm-11 pl-3">
                                        <div className="details">
                                            <p className="title mb-0">Under-the-Hood Components</p>
                                            <p className="description mb-0">Our high-performance Masterbatches are used in under-the-hood components, where materials need to withstand high temperatures and chemical exposures. These applications include components like engine covers, battery housings, and fluid containers, offering enhanced thermal stability and chemical resistance.</p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 position-relative" >
                        <IndustrySidebar allowed={[1, 2,3, 6, 7]} />
                    </div>
                </div>
            </div>
        </div>
    );
}
