import React from 'react'
import { Link } from 'react-router-dom'


export default function LifeAtAlok() {

    return (
        <>
            <div className='w-100' id="life">
                <div className='container mx-auto row life-at-container g-5'>
                    <div className='col-lg-5 life-title-container'>
                        {/* <p className='subtitle'>Life @Alok</p> */}
                        <p className='title'>What we’re all about</p>
                    </div>

                    <div className='col-lg-7 description-container'>
                        <p className='description'>ALOK Masterbatches emphasizes its commitment to employee well-being through comprehensive perks and benefits. They offer robust healthcare, professional development opportunities, a supportive work environment, and a culture rooted in diversity, inclusion, and integrity. The company prioritizes the physical and mental health of its employees, fosters career growth, ensures a safe and collaborative workspace, and upholds values of professionalism and innovation.</p>

                        <div className='join-team-btn'>

                            <p className='mb-0 btn-text'>Join our Team</p>
                            <div className="offcanva-btn mt-60" >
                                <a
                                    href={"#jobs"}
                                    className="tp-slider-btn d-inline-block"
                                    tabIndex="-1"
                                >
                                    <span>
                                        <svg
                                            width="53"
                                            height="8"
                                            viewBox="0 0 53 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                                                fill="#7939A8"
                                            ></path>
                                        </svg>
                                        <svg
                                            width="53"
                                            height="8"
                                            viewBox="0 0 53 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                                                fill="#7939A8"
                                            ></path>
                                        </svg>
                                    </span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}