import React, { useRef, useState } from 'react'
import Slider from 'react-slick';


export default function PerksAndBenefits() {

    const sliderRef = useRef(null);
    const [missions, setMissions] = useState([
        {
            image: "/assets/img/career/perk1.png"
        },
        {
            image: "/assets/img/career/perk2.png"
        },
        {
            image: "/assets/img/career/perk3.png"
        },
        {
            image: "/assets/img/career/perk7.png"
        },
        {
            image: "/assets/img/career/perk5.png"
        },
        {
            image: "/assets/img/career/perk6.png"
        },
    ]);


    const settings = {
        autoplay: true,
        lazyLoad: true,
        autoplaySpeed: 7000,
        speed: 500,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        // fade: true,
        arrows: false,
        pauseOnHover: false, // Pause carousel on hover
        afterChange: (current) => {
            setSlideIndex(current)
            // setActiveSlide(current)
        },
        beforeChange: (prev, next) => {
        },
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    const [activeSlide, setActiveSlide] = useState(0);
    const [slideIndex, setSlideIndex] = useState(0);


    return (
        <>
            <div className='w-100 perks-container' id='perks'>
                <div className='container mx-auto row position-relative'>

                    <div className='perks-slider'>
                        <Slider ref={sliderRef} {...settings}>
                            {missions.map((item,index) => (
                                <div key={index} className='image px-2'>
                                    <img src={item.image} className='max-w-100' />
                                </div>
                            ))}
                        </Slider>
                    </div>

                    <div className='col-lg-5'>
                        <div className='perks-title-container'>
                            <p className='mb-0 sub-title'>PERKS & BENEFITS</p>
                            <h2 className='mb-0 title'>We invest on you, because <br />we care for you</h2>
                            <p className='mb-0 description'>
                                At ALOK Masterbatches, we work hard to create a welcoming space for the team, and we’re happy to share the results.
                            </p>
                        </div>
                    </div>

                    <div className='col-lg-7'>
                        <div className='perks row g-5'>
                            <div className='perk col-lg-6'>
                                <div className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <path d="M17.4121 20.6643C19.7807 20.1882 21.6877 18.2579 22.1646 15.8281C22.6415 18.2579 24.548 20.1882 26.9166 20.6643M26.9166 20.6671C24.548 21.1432 22.641 23.0735 22.1642 25.5033C21.6872 23.0735 19.7807 21.1432 17.4121 20.6671" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M24.8645 13.9071C27.0524 11.9379 27.4899 8.87473 26.1772 6.24916C24.208 2.09202 18.957 1.21686 15.675 4.49882L13.9247 6.24916L12.1742 4.49882C9.11107 1.43565 3.64117 2.31082 1.67199 6.24916C0.578013 8.87473 1.01562 11.9379 2.9848 13.9071L14.1434 25.5033L16.0202 23.4732" stroke="white" strokeWidth="1.63" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>

                                <div className='perk-details'>
                                    <h2 className='mb-0 title'>Healthcare</h2>
                                    <p className='mb-0 description'>Our organization offers comprehensive health insurance along with a robust wellness program that includes health screenings, informative sessions, and mental health support. We prioritize the well-being of our employees, ensuring they have access to the resources necessary to maintain their physical and mental health.</p>
                                </div>
                            </div>

                            <div className='perk col-lg-6'>
                                <div className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <g clipPath="url(#clip0_424_3468)">
                                            <path d="M12.7725 23.5834C11.355 21.901 7.61353 20.2584 1.73003 20.0682C1.42386 20.0633 1.13189 19.9382 0.917114 19.72C0.702343 19.5017 0.581992 19.2078 0.582031 18.9015V2.34654C0.582006 2.19155 0.612864 2.03811 0.672804 1.89517C0.732743 1.75224 0.820562 1.62268 0.931131 1.51407C1.0417 1.40546 1.1728 1.31996 1.31678 1.26258C1.46076 1.2052 1.61473 1.17709 1.7697 1.17988C9.3997 1.43421 13.4154 4.13504 13.4154 6.18371C13.4154 4.14088 17.9234 1.45404 25.0564 1.18104C25.2117 1.17762 25.3662 1.20528 25.5107 1.26237C25.6552 1.31947 25.7869 1.40487 25.898 1.51354C26.009 1.62222 26.0973 1.75199 26.1575 1.89524C26.2177 2.03848 26.2487 2.19232 26.2487 2.34771V17.4922" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M13.418 6.18359V19.2409" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.5014 6.99176C8.42125 6.24894 6.26282 5.74711 4.06836 5.49609" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M16.918 12.2418C18.9984 11.4988 21.1573 10.997 23.3521 10.7461" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.5014 12.2418C8.42125 11.4989 6.26282 10.9971 4.06836 10.7461" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.5003 16.9089C8.40107 16.159 6.22261 15.6529 4.00781 15.4004" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M16.918 6.99288C19.0178 6.24237 21.1971 5.7362 23.4128 5.48438" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M27.4175 26.8249V25.5415C27.4222 24.6733 27.0915 23.8368 26.4944 23.2064C25.8973 22.5761 25.0799 22.2006 24.2127 22.1582H21.5842V16.6165C21.5842 16.2298 21.4306 15.8588 21.1571 15.5853C20.8836 15.3118 20.5126 15.1582 20.1259 15.1582C19.7391 15.1582 19.3682 15.3118 19.0947 15.5853C18.8212 15.8588 18.6675 16.2298 18.6675 16.6165V25.0749L16.893 22.5794C16.8198 22.4341 16.7184 22.3049 16.5947 22.1993C16.4709 22.0936 16.3274 22.0137 16.1725 21.9641C16.0175 21.9146 15.8543 21.8964 15.6922 21.9106C15.5302 21.9248 15.3726 21.9711 15.2286 22.0469C15.0847 22.1227 14.9573 22.2263 14.8538 22.3519C14.7504 22.4774 14.673 22.6223 14.6261 22.7781C14.5793 22.9339 14.5639 23.0974 14.581 23.2592C14.598 23.421 14.6471 23.5778 14.7254 23.7204L16.3587 26.8249" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_424_3468">
                                                <rect width="28" height="28" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>

                                <div className='perk-details'>
                                    <h2 className='mb-0 title'>Professional Development</h2>
                                    <p className='mb-0 description'>We are deeply committed to the professional growth of our employees. We provide paid training programs, regularly conduct Training Needs Analysis (TNA), and offer mentorship opportunities. These initiatives are designed to help our team members develop their skills and advance in their careers.</p>
                                </div>
                            </div>

                            <div className='perk col-lg-6'>
                                <div className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <path d="M7.71496 15.2792L2.11523 12.1831C4.34686 7.90349 8.74125 6.86784 12.1318 7.80891" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.7285 20.2931L15.8246 25.8929C20.0897 23.658 21.1291 19.2749 20.1965 15.8867" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.71484 15.2594L7.7619 15.2885C9.7906 16.5406 11.4963 18.2525 12.7411 20.2856C16.7621 17.8769 21.3331 15.5439 23.4194 13.3817C27.9336 8.86759 25.3162 2.68438 25.3162 2.68438C25.3162 2.68438 19.1329 0.0669446 14.6188 4.58106C12.4566 6.66742 10.1047 11.2574 7.71484 15.2594Z" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.93572 24.5105C7.36124 25.0652 5.84188 25.5916 4.39819 25.9931C2.91207 26.4063 1.59335 25.0875 2.0065 23.6013C2.40786 22.1577 2.93447 20.6383 3.48915 20.0639C3.77719 19.7547 4.12452 19.5068 4.51045 19.3349C4.89637 19.1629 5.31299 19.0705 5.73542 19.063C6.15785 19.0555 6.57747 19.1333 6.96923 19.2915C7.36098 19.4497 7.71683 19.6853 8.01559 19.9841C8.31434 20.2827 8.54987 20.6387 8.7081 21.0303C8.86634 21.4221 8.94405 21.8418 8.93659 22.2641C8.92914 22.6866 8.83668 23.1033 8.66472 23.4891C8.49276 23.875 8.24484 24.2225 7.93572 24.5105Z" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M19.3125 8.68752L20.0097 7.99023" stroke="white" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>

                                <div className='perk-details'>
                                    <h2 className='mb-0 title'>Work Environment</h2>
                                    <p className='mb-0 description'>Our work environment is equipped with modern office, laboratory, and plant facilities, providing a safe and clean workspace. We emphasize a collaborative working atmosphere, ensuring that our employees have a conducive environment for productivity and innovation.</p>
                                </div>
                            </div>

                            <div className='perk col-lg-6'>
                                <div className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <g clipPath="url(#clip0_424_3495)">
                                            <path d="M26.25 9.04167L14 0.875L1.75 9.04167" stroke="white" strokeWidth="1.63333" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M21.584 0.875H25.084V4.375" stroke="white" strokeWidth="1.63333" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.125 20.125C8.05799 20.125 9.625 18.558 9.625 16.625C9.625 14.6919 8.05799 13.125 6.125 13.125C4.19201 13.125 2.625 14.6919 2.625 16.625C2.625 18.558 4.19201 20.125 6.125 20.125Z" stroke="white" strokeWidth="1.63333" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.0917 23.625C9.15832 22.575 7.75833 21.875 6.125 21.875C3.20833 21.875 0.875 24.2083 0.875 27.125" stroke="white" strokeWidth="1.63333" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M13.4277 27.1254H23.9277C24.5111 27.1254 24.9777 26.7754 25.2111 26.1921L27.0777 19.6586C27.3111 18.8419 26.7277 18.0254 25.7944 18.0254H17.0444C16.4611 18.0254 15.9944 18.3754 15.7611 18.9587L13.4277 27.1254Z" stroke="white" strokeWidth="1.63333" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8.17773 27.125H13.4277" stroke="white" strokeWidth="1.63333" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M20.4277 23.0417C20.7499 23.0417 21.0111 22.7804 21.0111 22.4583C21.0111 22.1362 20.7499 21.875 20.4277 21.875" stroke="white" strokeWidth="1.63333" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M20.429 23.0417C20.1069 23.0417 19.8457 22.7804 19.8457 22.4583C19.8457 22.1362 20.1069 21.875 20.429 21.875" stroke="white" strokeWidth="1.63333" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_424_3495">
                                                <rect width="28" height="28" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>

                                <div className='perk-details'>
                                    <h2 className='mb-0 title'>Culture</h2>
                                    <p className='mb-0 description'>We champion diversity and inclusion in the workplace, fostering an environment where teamwork and open communication are valued. As a family-owned organization, we uphold traditional values like integrity, ethics, and transparency. Simultaneously, we have infused a culture of professionalism, performance, and cutting-edge innovation to keep us at the top of our game.
</p>
                                </div>
                            </div>

                          

                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}