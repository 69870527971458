// src/Calendar.js
import React, { useState } from 'react';
import './index.css';

const Calendar = ({ setSelectedDate, selectedDate, dates = [] }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const changeMonth = (direction) => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + direction, 1));
  };

  const renderHeader = () => {
    const dateFormat = { month: 'long', year: 'numeric' };
    return (
      <div className="calendar-header d-flex justify-content-start align-items-center p-3 pb-0">
        <button className="calender-controls btn btn-link text-white" onClick={() => changeMonth(-1)}>
          {"<"}

          {/* <svg xmlns="http://www.w3.org/2000/svg" width="25" height="41" viewBox="0 0 25 41" fill="none">
            <path d="M24.7871 36.3484L20.7183 40.3984L0.601063 20.3984L20.7183 0.398438L24.7871 4.44844L8.75126 20.3984L24.7871 36.3484Z" fill="white" />
          </svg> */}
        </button>
        <h4 className="text-white">{currentDate.toLocaleDateString('en-US', dateFormat)}</h4>
        <button className="calender-controls btn btn-link text-white" onClick={() => changeMonth(1)}>
          {">"}

          {/* <svg xmlns="http://www.w3.org/2000/svg" width="25" height="41" viewBox="0 0 25 41" fill="none">
            <path d="M0.599609 4.44844L4.66841 0.398438L24.7857 20.3984L4.66841 40.3984L0.599609 36.3484L16.6355 20.3984L0.599609 4.44844Z" fill="white" />
          </svg> */}
        </button>
        {/* <div className='calender-controls'>
          
        </div> */}
      </div>
    );
  };

  const renderDays = () => {
    const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    return (
      <div className="row single-row calender-days-container">
        {days.map((day, index) => (
          <div className="col p-0 d-flex justify-content-center align-items-center" key={index}>
            <span className="text-center text-white calender-days">
              {day}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const renderCells = () => {
    const monthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const monthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const startDate = new Date(monthStart);
    startDate.setDate(monthStart.getDate() - monthStart.getDay() + 1);
    const endDate = new Date(monthEnd);
    endDate.setDate(monthEnd.getDate() + (7 - monthEnd.getDay()) % 7);

    const rows = [];
    let days = [];
    let day = new Date(startDate);

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const cloneDay = new Date(day);
        days.push(
          <div
            className={'col p-0'}
            key={day.toString()}
            onClick={() => {
              if (!dates.filter((item) => item === cloneDay.toJSON()).length || cloneDay.getMonth() !== currentDate.getMonth()) {
                return;
              }
              setSelectedDate(cloneDay);
            }}
          >
            <span className={`calender-cells mx-auto text-center ${cloneDay.getMonth() !== currentDate.getMonth() ? 'opacity-0' : 'text-white'} ${selectedDate && selectedDate.getDate() === cloneDay.getDate() ? 'bg-current' :
              (dates.filter((item) => item === cloneDay.toJSON()).length && cloneDay.getTime() < currentDate.getTime()) ? 'bg-prev' : (dates.filter((item) => item === cloneDay.toJSON()).length && cloneDay.getTime() > currentDate.getTime()) ? 'bg-forward' : 'bg-dark'}`} >
              {day.getDate()}
            </span>
          </div>
        );
        day.setDate(day.getDate() + 1);
      }
      rows.push(
        <div className="row single-row" key={day.toString()}>
          {days}
        </div>
      );
      days = [];
    }
    return <>{rows}</>;
  };

  return (
    <div className="container calendar-container p-0 rounded bg-dark">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};

export default Calendar;