import React from 'react'
import { Link } from 'react-router-dom'



export default function CareersTab() {

    return (
        <>
            <div className='w-100'>
                <div className='container mx-auto'>
                    <div className='career-tabs'>

                        <a className='career-tab career-tab-active' href={"#life"}>
                            <p className='tab-title'>Life @ALOK</p>
                        </a>

                        <a className='career-tab' href={"#perks"}>
                            <p className='tab-title'>Perks & Benefits</p>
                        </a>

                        <a className='career-tab' href={"#jobs"}>
                            <p className='tab-title'>Jobs @ALOK</p>
                        </a>

                        {/* <a className='career-tab' href={"#our-team"}>
                            <p className='tab-title'>Our Team</p>
                        </a> */}

                        {/* <a className='career-tab' href={"#faq"}>
                            <p className='tab-title'>FAQ</p>
                        </a>

                        <a className='career-tab' href={"#joinform"}>
                            <p className='tab-title'>Career enquiry</p>
                        </a> */}

                    </div>
                </div>
            </div>
        </>
    )
}